import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { getTemplateVars } from '@/client/utils'
import type { SingleActionMetadataCache } from '@/server/service/types'
import { api } from '@/utils'
import type { WorkspaceAppActionIParams } from '../types/queryparams'
import { useUser } from './user'

export function useFetchAppActions(appId?: number) {
  const { user } = useUser()
  const { data, isLoading, error, refetch } = api.action.getAllForApp.useQuery(
    { appId: appId! },
    {
      enabled: user !== undefined && Boolean(appId),
    }
  )
  return {
    actions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useFetchAppActionsBySlug(slug?: string, workspaceSlug?: string) {
  const { user } = useUser()
  const { data, isLoading, error, refetch } = api.action.getAllForAppBySlug.useQuery(
    { slug: slug!, workspaceSlug: workspaceSlug! },
    {
      enabled: user !== undefined && Boolean(slug) && Boolean(workspaceSlug),
    }
  )
  return {
    actions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useFetchActionBySlug(actionSlug: string) {
  const { user } = useUser()
  const { data, isLoading, isFetching, error, refetch } = api.action.getBySlug.useQuery(
    { slug: actionSlug },
    {
      enabled: user !== undefined && actionSlug !== undefined,
    }
  )
  return {
    action: data,
    isLoading: isLoading && isFetching,
    error,
    refetch,
  }
}

export function useFetchActionsCache(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.action.getActionsCache.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined && workspaceId !== undefined,
    }
  )
  return {
    cache: data,
    isLoading: isLoading,
    error,
  }
}

export function useFetchActionByGuid(guid: string) {
  const { data, isLoading, isFetching, error } = api.publicAction.getByGuid.useQuery(
    { guid },
    { enabled: Boolean(guid) }
  )
  return {
    action: data,
    isLoading: isLoading && isFetching, // see https://github.com/TanStack/query/issues/3975#issuecomment-1245101647, it should be resolved in v5
    error,
  }
}

export function useFetchAgentSessions(
  actionId?: number,
  config?: {
    enabled?: boolean
    limit?: number
  }
) {
  const { user } = useUser()

  const limit = config?.limit || 15

  const { data, isLoading, error, refetch } = api.actionSession.getAll.useQuery(
    { actionId: actionId!, limit },
    {
      enabled:
        config?.enabled === undefined
          ? user !== undefined && Boolean(actionId)
          : config.enabled && user !== undefined && Boolean(actionId),
    }
  )
  return {
    actionSessions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useFetchAgentSessionsBySlug(
  actionSlug?: string,
  config?: {
    enabled?: boolean
    limit?: number
  }
) {
  const { user } = useUser()

  const limit = config?.limit || 15

  const { data, isLoading, error, refetch } = api.actionSession.getAllBySlug.useQuery(
    { actionSlug: actionSlug!, limit },
    {
      enabled:
        config?.enabled === undefined ? user !== undefined : config.enabled && user !== undefined,
    }
  )
  return {
    actionSessions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useFetchActionSessionsNoData(
  actionId?: number,
  config?: {
    enabled?: boolean
    limit?: number
  }
) {
  const { user } = useUser()

  const limit = config?.limit || 15

  const { data, isLoading, error, refetch } = api.actionSession.getAllNoData.useQuery(
    { actionId: actionId!, limit },
    {
      enabled:
        config?.enabled === undefined
          ? user !== undefined && Boolean(actionId)
          : config.enabled && user !== undefined && Boolean(actionId),
      refetchOnWindowFocus: false,
    }
  )
  return {
    actionSessions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useInfiniteFetchAgentSessions(actionId?: number, pageSize?: number) {
  const [pageState, setPageState] = useState({
    pageIndex: 0,
    pageSize: pageSize ?? 50,
  })

  const { user } = useUser()

  const { data, isLoading, error, fetchNextPage, isFetching } =
    api.actionSession.getInfiniteAll.useInfiniteQuery(
      { actionId: actionId!, limit: pageState.pageSize },
      {
        enabled: user !== undefined && Boolean(actionId),
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    )

  const handleFetchNextPage = async () => {
    await fetchNextPage()
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex + 1,
    })
  }

  const handleFetchPage = (pageIndex: number) => {
    setPageState({
      ...pageState,
      pageIndex,
    })
  }

  const handleFetchPreviousPage = () => {
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex - 1,
    })
  }

  const pageCount = data?.pages[pageState.pageIndex]?.pageCount ?? 1

  return {
    actionSessions: data?.pages[pageState.pageIndex]?.dataItems,
    isLoading,
    isFetching,
    error,
    handleFetchNextPage,
    handleFetchPreviousPage,
    handleFetchPage,
    hasNextPage: pageState.pageIndex < pageCount - 1,
    hasPreviousPage: pageState.pageIndex > 0,
    pageState,
    setPageState,
    pageCount,
  }
}

export function useFetchLatestAgentSession(actionId?: number) {
  const { user } = useUser()

  const { data, isLoading, error, refetch } = api.actionSession.getLatestByActionId.useQuery(
    { actionId: actionId! },
    {
      enabled: user !== undefined && Boolean(actionId),
      refetchOnWindowFocus: false,
    }
  )
  return {
    actionSession: data,
    isLoading: actionId !== undefined && isLoading,
    error,
    refetch,
  }
}

export function useFetchAgentSession(sessionId?: number, getOnlyLatestData = false) {
  const { user } = useUser()

  const { data, isLoading, error } = api.actionSession.get.useQuery(
    { sessionId: sessionId!, getOnlyLatestData },
    {
      enabled: user !== undefined && Boolean(sessionId),
      refetchOnWindowFocus: false,
    }
  )
  return {
    actionSession: data,
    isLoading,
    error,
  }
}

export function useFetchActionSessionMessage(sessionId?: number, getOnlyLatestData = true) {
  const { user } = useUser()

  const { data, isLoading, error } = api.actionSession.getMessage.useQuery(
    { sessionId: sessionId!, getOnlyLatestData },
    {
      enabled: user !== undefined && Boolean(sessionId),
      refetchOnWindowFocus: false,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useCurrentAction() {
  const router = useRouter()
  const { actionSlug } = router.query as WorkspaceAppActionIParams
  return useFetchActionBySlug(actionSlug)
}

export function useFetchActionVersions(actionId: number, enabled = true) {
  const { user } = useUser()

  const { data, isLoading, error, refetch } = api.actionVersion.getAllForAction.useQuery(
    { actionId: actionId },
    {
      enabled: typeof enabled !== undefined ? user !== undefined && enabled : user !== undefined,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    versions: data,
    isLoading,
    error,
    refetch,
  }
}

export function useInfiniteFetchActionVersions(actionId: number, pageSize?: number) {
  const [pageState, setPageState] = useState({
    pageIndex: 0,
    pageSize: pageSize ?? 20,
  })

  const { user } = useUser()

  const { data, isLoading, error, fetchNextPage, isFetching } =
    api.actionVersion.getInfiniteAllForAction.useInfiniteQuery(
      {
        actionId,
        limit: pageState.pageSize,
      },
      {
        enabled: user !== undefined,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    )
  const handleFetchNextPage = async () => {
    await fetchNextPage()
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex + 1,
    })
  }

  const handleFetchPage = (pageIndex: number) => {
    setPageState({
      ...pageState,
      pageIndex,
    })
  }

  const handleFetchPreviousPage = () => {
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex - 1,
    })
  }

  const pageCount = data?.pages[pageState.pageIndex]?.pageCount ?? 1

  return {
    versions: data?.pages[pageState.pageIndex]?.data,
    isLoading,
    isFetching,
    error,
    handleFetchNextPage,
    handleFetchPreviousPage,
    handleFetchPage,
    hasNextPage: pageState.pageIndex < pageCount - 1,
    hasPreviousPage: pageState.pageIndex > 0,
    pageState,
    setPageState,
    pageCount,
  }
}

export function useFetchExistingAppVariables(appId?: string) {
  const { actions } = useFetchAppActions(appId ? Number(appId) : undefined)

  return useMemo(() => {
    const existingVariables = new Set<string>()

    actions?.forEach((action) => {
      const metadata = action.metadata as SingleActionMetadataCache
      if (metadata?.variables) {
        // pull vars from metadata if available
        metadata.variables.forEach((variable) => {
          existingVariables.add(variable)
        })
      } else {
        const promptVariables = getTemplateVars(action.prompt)
        const systemMessageVariables = action.systemMessage
          ? getTemplateVars(action.systemMessage)
          : []

        const variables = [...promptVariables, ...systemMessageVariables]
        variables.forEach((variable) => {
          existingVariables.add(variable)
        })
      }
    })

    return [...existingVariables]
  }, [actions])
}

export function useFetchExistingAppVariablesBySlug(slug?: string, workspaceSlug?: string) {
  const { actions } = useFetchAppActionsBySlug(slug ?? undefined, workspaceSlug ?? undefined)

  return useMemo(() => {
    const existingVariables = new Set<string>()

    actions?.forEach((action) => {
      const metadata = action.metadata as SingleActionMetadataCache
      if (metadata?.variables) {
        // pull vars from metadata if available
        metadata.variables.forEach((variable) => {
          existingVariables.add(variable)
        })
      } else {
        const promptVariables = getTemplateVars(action.prompt)
        const systemMessageVariables = action.systemMessage
          ? getTemplateVars(action.systemMessage)
          : []

        const variables = [...promptVariables, ...systemMessageVariables]
        variables.forEach((variable) => {
          existingVariables.add(variable)
        })
      }
    })

    return [...existingVariables]
  }, [actions])
}

export function useFetchActionEvals(
  actionId: number,
  workspaceId: number,
  dataType?: 'LIVE' | 'TEST'
) {
  const { user } = useUser()

  const { data, isLoading, error } = api.eval.getAllForAction.useQuery(
    { actionId, workspaceId, dataType },
    {
      enabled: user !== undefined,
    }
  )

  return {
    evals: data,
    isLoading,
    error,
  }
}
