import type { Workspace } from '@prisma/client'
import { useRouter } from 'next/router'
import type { FeatureName } from 'tier'
import { api } from '@/utils'
import type { WorkspaceIParams } from '../types/queryparams'
import { useUser } from './user'

export function useWorkspaceFeaturesEnabled(workspaceId?: number) {
  const { user } = useUser()

  const { data, isLoading, error } = api.workspaceMeta.getFeatureFlags.useQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { workspaceId: workspaceId! },
    {
      enabled: user !== undefined && workspaceId !== undefined,
      refetchOnWindowFocus: false,
    }
  )

  return {
    featuresEnabled: data && (data.meta.metadata as string[]),
    isLoading,
    error,
  }
}

export function useCheckWorkspaceFeatureAccess({
  workspaceId,
  featureId,
}: {
  workspaceId: number
  featureId: FeatureName
}) {
  const { data, isLoading, error } = api.billing.checkFeatureAccess.useQuery(
    {
      workspaceId,
      featureId,
    },
    {
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )

  if (data?.err) {
    console.error('Error checking feature access: ', data.err)
  }

  return {
    hasFeatureAccess: data && data.ok ? true : false,
    isLoading,
    error,
  }
}

export function useFetchWorkspaces() {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getAll.useQuery(undefined, {
    enabled: user !== undefined,
  })
  return {
    workspaces: data,
    isLoading,
    error,
  }
}

export function useFetchWorkspacesForMember() {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getIsMemberOf.useQuery(undefined, {
    enabled: user !== undefined,
  })
  return {
    workspaces: data,
    isLoading,
    error,
  }
}

export function useCurrentWorkspace() {
  const { user } = useUser()
  const router = useRouter()
  const { workspaceSlug } = router.query as WorkspaceIParams

  const validSlug =
    workspaceSlug !== undefined &&
    typeof workspaceSlug === 'string' &&
    workspaceSlug.length > 0 &&
    workspaceSlug !== 'current' &&
    !workspaceSlug.includes('.') &&
    workspaceSlug.trim() !== '404'

  const { data, isLoading, error } = api.workspace.getBySlug.useQuery(
    { slug: workspaceSlug },
    {
      enabled: user !== undefined && validSlug,
      refetchOnWindowFocus: false,
    }
  )

  return {
    workspace: data,
    isLoading,
    error,
  }
}

export function useFetchBillingData({ workspace }: { workspace: Workspace }) {
  const { data, isLoading, error } = api.billing.getData.useQuery({
    workspaceId: workspace.id,
  })

  if (!workspace) {
    return {
      data: null,
      isLoading: false,
      error: null,
    }
  }

  return {
    data,
    isLoading,
    error,
  }
}

export function useCheckWorkspaceSubscription(workspaceId: number) {
  const { data, isLoading, error } = api.workspace.checkWorkspaceSubscription.useQuery({
    workspaceId,
  })

  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchSubscriptionPlans() {
  const { data, isLoading, error } = api.billing.getSubscriptionPlans.useQuery()

  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchCompanyWorkspacesForDomain(domain: string) {
  const { user } = useUser()

  if (!domain || domain == '') {
    return {
      workspaces: [],
      isLoading: false,
    }
  }

  const { data, isLoading, error } = api.workspace.getCompanyWorkspacesForDomain.useQuery(
    { domain: domain },
    {
      enabled: user !== undefined,
    }
  )

  return {
    workspaces: data,
    isLoading,
    error,
  }
}

export function useFetchEnvironmentsEnabled(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getIsEnvironmentsEnabled.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )

  return {
    currentlyEnabled: data,
    isLoading,
    error,
  }
}

export function useFetchEnvironments(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error, refetch } = api.workspace.getEnvironments.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )

  return {
    environments: data,
    refetch,
    isLoading,
    error,
  }
}

export function useFetchWorkspaceAPIUrl(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error, refetch } = api.workspace.getAPIUrl.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )

  return {
    apiUrl: data,
    refetch,
    isLoading,
    error,
  }
}

export function useFetchOpenAIFiles(workspaceSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getOpenAIFiles.useQuery(
    {
      slug: workspaceSlug,
    },
    {
      enabled: user !== undefined,
    }
  )

  return {
    files: data,
    isLoading,
    error,
  }
}
