import { CheckCircle, InfoCircle } from '@untitled-ui/icons-react'

export const PassFailCell = ({ value }: { value: boolean }) => {
  return (
    <div className="whitespace-pre-wrap text-s relative items-center py-3 leading-tight flex gap-2">
      {value ? (
        <>
          <CheckCircle className="text-green-500 h-5 w-5" />
          Passed
        </>
      ) : (
        <>
          <InfoCircle className="text-red-500 h-5 w-5" />
          Failed
        </>
      )}
    </div>
  )
}
