import { api } from '@/utils'
import { useUser } from './user'

export function useFetchDatasets(appId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.getAll.useQuery(
    { appId, workspaceId },
    {
      enabled: user !== undefined,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    datasets: data,
    isLoading,
    error,
  }
}

export function useFetchDataset(datasetId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.get.useQuery(
    { id: datasetId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    dataset: data,
    isLoading,
    error,
  }
}

export function useFetchDatasetByGuid(datasetGuid: string, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.getByGuid.useQuery(
    { guid: datasetGuid, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    dataset: data,
    isLoading,
    error,
  }
}

export function useFetchDatasetSuggestions(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.suggest.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    suggestions: data,
    isLoading,
    error,
  }
}

export function useFetchDatasetItemsCount(workspaceId: number, datasetId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.getDatasetItemsCount.useQuery(
    { id: datasetId!, workspaceId },
    {
      enabled: user !== undefined && datasetId !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchTotalDatasetTokens(datasetId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.datasetItem.getDatasetSumOfTokens.useQuery(
    { datasetId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    tokens: data,
    isLoading,
    error,
  }
}

export function useFetchDatasetTags(datasetId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.dataset.getAllTags.useQuery(
    { datasetId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    tags: data,
    isLoading,
    error,
  }
}
