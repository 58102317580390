import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { cn } from '@/client/utils'
import type { DropdownMenuItemProps } from './types'

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  DropdownMenuItemProps
>(({ className, inset, variant, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center rounded-md px-2 py-1.5 text-sm outline-none transition-colors  data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ',
      inset && 'pl-8',
      variant === 'destructive'
        ? 'text-red-500 hover:bg-red-50 dark:hover:bg-red-900 dark:text-red-400 focus:bg-red-50 dark:focus:bg-red-900/50 focus:text-red-500 dark:focus:text-red-400'
        : 'text-grey-900 hover:bg-grey-100 dark:hover:bg-zinc-900 dark:text-grey-50 focus:bg-grey-100 dark:focus:bg-zinc-900 focus:text-grey-900 dark:focus:text-grey-50',
      className
    )}
    {...props}
  />
))
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

export { DropdownMenuItem }
