import React from 'react'
import { Button, Loader } from '@/client/components'
import { useDatasetBulkAction } from '../../context'

export const ExportDatasetForm = () => {
  const {
    downloadCsv,
    downloadJson,
    loading,
    toggleSelectAllRows,
    totalRowCount,
    selectAllRows,
    downloadingJson,
    downloadingCsv,
  } = useDatasetBulkAction()
  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div>
          <div className="mt-1 flex items-center justify-between pb-2">
            <label htmlFor="user" className="block text-sm text-grey-600 dark:text-zinc-600">
              <span className="font-medium text-grey-800">
                {loading ? (
                  <div className="flex animate-pulse">
                    <div className="flex-1 space-y-2">
                      <div className="h-2 rounded bg-grey-700"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    {totalRowCount > 1000 ? `${Math.round(totalRowCount / 1000)}k` : totalRowCount}{' '}
                    datapoints
                  </>
                )}
              </span>{' '}
              currently selected.
            </label>
          </div>
          <div className="w-full font-inter">
            <p className="mt-3 border-t border-grey-200 dark:border-zinc-800 pt-4 text-sm font-light text-grey-600 dark:text-zinc-600 dark:text-zinc-500">
              JSONL data export prepared using{' '}
              <a
                className="underline hover:text-grey-900"
                href="https://platform.openai.com/docs/guides/fine-tuning/preparing-your-dataset"
                target="_blank"
                rel="noreferrer"
              >
                OpenAI best practices
              </a>
              . CSV export with all data attributes is also available.
            </p>
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 dark:border-zinc-800 pt-4">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                className={
                  'h-4 w-4 rounded border-grey-300 text-grey-600 dark:text-zinc-600 focus:ring-0 cursor-pointer'
                }
                checked={selectAllRows}
                onChange={() => void toggleSelectAllRows()}
              />
              <label htmlFor="user" className="block text-sm text-grey-600 dark:text-zinc-600">
                Export all data
              </label>
            </div>

            {loading && <Loader className="h-6 w-6" />}
          </div>
        </div>
      </div>

      <div className="mt-3 flex items-center space-x-4 justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          onClick={() => void downloadCsv()}
          disabled={downloadingCsv || totalRowCount < 1}
          variant="outline"
          className="w-full"
        >
          Export CSV
          {downloadingCsv && <Loader className="inline-block w-5 h-5 ml-2" />}
        </Button>
        <Button
          onClick={() => void downloadJson()}
          disabled={downloadingJson || totalRowCount < 1}
          className="w-full"
        >
          Export JSONL
          {downloadingJson && <Loader className="inline-block w-5 h-5 ml-2" />}
        </Button>
      </div>
    </div>
  )
}
