/* eslint-disable @next/next/no-img-element */
import { UploadCloud01 } from '@untitled-ui/icons-react'
import { useId, useState } from 'react'
import type { ButtonVariantProps } from '@/client/components/Button/variants'
import { buttonVariants } from '@/client/components/Button/variants'
import { cn } from '@/client/utils'
import { Loader } from '.'

interface FileUploadProps {
  onChangeFiles?: (files: File[]) => void
  noLabel?: boolean
  label?: string
  disabled?: boolean
  multiple?: boolean
  className?: string
  allowDrop?: boolean
  allowedFileTypes?: string[]
  variant?: ButtonVariantProps['variant']
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onChangeFiles,
  label,
  noLabel = false,
  disabled,
  multiple = true,
  className,
  allowDrop = false,
  allowedFileTypes,
  variant = 'primary',
}) => {
  const id = useId()
  const [dragActive, setDragActive] = useState(false)

  if (!label && !noLabel) {
    label = `Add file${multiple ? 's' : ''}`
  }

  const handleFilesChange = ({ target }: { target: HTMLInputElement }) => {
    if (!target.files) return

    const files = Array.from(target.files)
    if (onChangeFiles) {
      onChangeFiles(files)
    }
  }

  const handleDrag = function (e: React.DragEvent<HTMLLabelElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = function (e: React.DragEvent<HTMLLabelElement>) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = [...e.dataTransfer.files]
      if (onChangeFiles) {
        onChangeFiles(files)
      }
    }
  }

  return (
    <div className={cn('relative overflow-hidden break-words w-full h-full', className)}>
      {allowDrop && (
        <label
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          className={cn(
            `flex justify-center w-full h-full px-4 transition 
            bg-white border-2 border-grey-300 border-dashed rounded-md 
            appearance-none cursor-pointer hover:border-grey-800 
            focus:outline-none break-words overflow-hidden`,
            {
              'border-grey-800': dragActive,
            }
          )}
        >
          <span
            className={cn('flex items-center', {
              'space-x-2': !noLabel,
            })}
          >
            {disabled ? (
              <Loader
                className={cn('h-8 w-8', {
                  'mx-auto': noLabel,
                  'mr-2': !noLabel,
                })}
              />
            ) : (
              <UploadCloud01
                className={cn('h-6 w-6 text-grey-600 dark:text-zinc-600', {
                  'mx-auto': noLabel,
                  'mr-2': !noLabel,
                })}
              />
            )}

            <span className="text-sm text-grey-800">{label}</span>
          </span>
          <input
            accept={allowedFileTypes?.join(',') || undefined}
            type="file"
            multiple={multiple}
            onChange={(e) => {
              void handleFilesChange(e)
            }}
            className="hidden"
            disabled={disabled}
            id={id}
          />
        </label>
      )}

      {!allowDrop && (
        <label htmlFor={id}>
          <span className={cn(buttonVariants({ variant, size: 'md' }), 'cursor-pointer')}>
            {disabled ? (
              <Loader className="mr-2 h-5 w-5" />
            ) : (
              <UploadCloud01 className="mr-2 h-5 w-5" />
            )}
            {label}
          </span>
          <input
            accept={allowedFileTypes?.join(',') || undefined}
            type="file"
            multiple={multiple}
            onChange={(e) => {
              void handleFilesChange(e)
            }}
            className="hidden"
            disabled={disabled}
            id={id}
          />
        </label>
      )}
    </div>
  )
}
