import { PlayCircle, Settings02, StopCircle } from '@untitled-ui/icons-react'
import router from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DownloadIcon } from '@/client/assets/icons/icons'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import type { StudioFormikKeys } from '@/client/containers/views/Studio/components/Formik/types'
import type { StudioQueryParams } from '@/client/containers/views/Studio/context/types'
import { cn } from '@/client/utils'
import { Button } from '../Button'
import { Dialog } from '../Dialog'
import { Select } from '../Select'
import { Switch } from '../Switch'

type TextToSpeechPlayerProps = {
  text: string
  isLast?: boolean
}

const TextToSpeechPlayer = ({ text, isLast }: TextToSpeechPlayerProps) => {
  const [playing, setPlaying] = useState(false)
  const { values } = useStudioFormikContext()
  const audio = useRef<HTMLAudioElement | null>(null)

  const { textToSpeechSettings } = values

  const src = useMemo(() => {
    return `/api/tts?input=${text}&voice=${textToSpeechSettings?.voice || 'alloy'}&model=${textToSpeechSettings?.model || 'tts-1'}&speed=${textToSpeechSettings?.speed || 1}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToSpeechSettings])

  const autoplay = useMemo(() => {
    return isLast && textToSpeechSettings?.autoplay
  }, [isLast, textToSpeechSettings?.autoplay])

  const stopAudio = useCallback(() => {
    if (audio.current) {
      audio.current.pause()
      setPlaying(false)
    }
  }, [audio])

  const playAudio = useCallback(async () => {
    if (playing || !audio.current) return

    // stop all audio
    const allAudio = document.querySelectorAll('audio')
    allAudio.forEach((a) => {
      a.pause()
    })

    try {
      await audio.current.play()
    } catch (error) {
      // safari doesn't allow autoplay on page load
      console.error('Error playing audio', error)
    }
  }, [audio, playing])

  useEffect(() => {
    if (!audio.current) return
    audio.current.onplay = () => {
      setPlaying(true)
    }
    audio.current.onpause = () => {
      setPlaying(false)
    }
    audio.current.onended = () => {
      stopAudio()
    }
  }, [audio, stopAudio, playAudio])

  useEffect(() => {
    const currentAudio = audio.current

    if (autoplay) {
      void playAudio()
    }

    return () => {
      if (currentAudio) {
        currentAudio.pause()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadAudio = useCallback(() => {
    if (!audio.current) return
    const a = document.createElement('a')
    a.href = audio.current.src
    a.download = 'audio.mp3'
    a.click()
  }, [audio])

  const [settingsOpen, toggleSettings] = useState(false)

  return (
    <div>
      <audio
        ref={audio}
        src={src}
        autoPlay={autoplay || false}
        preload={autoplay ? 'auto' : 'none'}
      />
      <Button
        size="xs"
        className={cn(
          'w-9 group-hover:opacity-100 hover:bg-white',
          playing ? 'opacity-100' : 'opacity-30'
        )}
        variant="outline-hover"
        onClick={() => {
          if (playing) {
            stopAudio()
          } else {
            void playAudio()
          }
        }}
        tooltip={{
          content: (
            <TooltipContent
              playing={playing}
              toggleSettings={toggleSettings}
              downloadAudio={downloadAudio}
            />
          ),
          side: 'left',
        }}
      >
        {playing ? (
          <StopCircle className="h-4 w-4 animate pulse text-blue-400" />
        ) : (
          <PlayCircle className="h-4 w-4" />
        )}
      </Button>
      <Dialog.Root open={settingsOpen} onOpenChange={toggleSettings}>
        <Dialog.Content>
          <StudioTextToSpeechSettings />
        </Dialog.Content>
      </Dialog.Root>
    </div>
  )
}

const TooltipContent = ({
  playing,
  toggleSettings,
  downloadAudio,
}: {
  playing: boolean
  toggleSettings: (val: boolean) => void
  downloadAudio: () => void
}) => {
  return (
    <div className="flex justify-between gap-2.5 items-center">
      <div>{playing ? 'Stop' : 'Play'}</div>

      <div className="pointer text-grey-400 hover:text-grey-800">
        <DownloadIcon className="h-4 w-4" onClick={downloadAudio} />
      </div>

      <div className="pointer text-grey-400 hover:text-grey-800">
        <Settings02 className="h-4 w-4" onClick={() => toggleSettings(true)} />
      </div>
    </div>
  )
}

const StudioTextToSpeechSettings = () => {
  const { setFieldValue, values } = useStudioFormikContext()
  const { updateStateForActionId } = usePersistStudioState()
  const { autoplay, voice, model, speed } = values.textToSpeechSettings || {}

  const { actionSlug } = router.query as Partial<StudioQueryParams>

  const updateSetting = async (key: string, val: string | number | boolean) => {
    await setFieldValue(`textToSpeechSettings.${key}` as StudioFormikKeys, val)
  }

  useEffect(() => {
    if (!actionSlug) return

    updateStateForActionId(actionSlug, {
      textToSpeechSettings: values.textToSpeechSettings,
    })
  }, [values.textToSpeechSettings, actionSlug, updateStateForActionId])

  const voiceOptions = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer']

  return (
    <div className="flex flex-col space-y-2 p-4">
      <div className="text-grey-400">Settings</div>

      <div className="flex justify-between">
        <div>Autoplay</div>
        <div>
          <Switch
            checked={autoplay}
            onCheckedChange={() => {
              void updateSetting('autoplay', !autoplay)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div>Voice</div>
        <div>
          <Select.Root
            value={voice ? voice : undefined}
            onValueChange={(val) => {
              void updateSetting('voice', val)
            }}
          >
            <Select.Trigger>
              <Select.Value placeholder={voice} />
            </Select.Trigger>

            <Select.Content>
              {voiceOptions.map((option, index) => {
                return (
                  <Select.Item key={index} value={option}>
                    <div className="flex items-center space-x-2">
                      <div>{option}</div>
                    </div>
                  </Select.Item>
                )
              })}
            </Select.Content>
          </Select.Root>
        </div>
      </div>

      <div className="flex justify-between">
        <div>Model</div>
        <div>
          <Select.Root
            value={model ? model : undefined}
            onValueChange={(val) => {
              void updateSetting('model', val)
            }}
          >
            <Select.Trigger>
              <Select.Value placeholder={model} />
            </Select.Trigger>

            <Select.Content>
              <Select.Item value="tts-1">tts-1</Select.Item>
              <Select.Item value="tts-1-hd">tts-1-hd</Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
      </div>

      <div className="flex justify-between">
        <div>Speed</div>
        <div>
          <Select.Root
            value={speed ? speed.toString() : '1'}
            onValueChange={(val) => {
              void updateSetting('speed', parseFloat(val))
            }}
          >
            <Select.Trigger>
              <Select.Value placeholder={speed} />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value={'0.25'}>0.25</Select.Item>
              <Select.Item value={'0.5'}>0.5</Select.Item>
              <Select.Item value={'1'}>1</Select.Item>
              <Select.Item value={'2'}>2</Select.Item>
              <Select.Item value={'4'}>4</Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
      </div>
    </div>
  )
}

export default TextToSpeechPlayer
