import type { App, Workspace } from '@prisma/client'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import type { DetailedEval } from '@/common/types/eval'
import { api } from '@/utils'

function DeleteEvalForm({
  setOpen,
  evalRecord,
  app,
  workspace,
}: {
  workspace: Workspace
  evalRecord: DetailedEval
  app: App
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteEval = api.eval.delete.useMutation()
  const utils = api.useContext()

  async function onSuccess() {
    await utils.eval.getAll.invalidate({ appId: app.id })
    toast.success({
      title: `${evalRecord.name} deleted`,
      description: 'Evaluation deleted successfully',
    })
    setOpen(false)
    await router.push(`/${workspace.slug}/apps/${app.slug}/evaluate`)
  }

  async function onDelete() {
    try {
      setLoading(true)
      await deleteEval.mutateAsync({ evalId: evalRecord.id })
      await onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">
              Delete {evalRecord.name}?
            </h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">
                Are you sure you want to delete {evalRecord.name}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 sm:flex sm:px-6">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          variant="destructive"
          onClick={() => void onDelete()}
        >
          {loading ? 'Deleting...' : 'Delete Eval'}
        </Button>
      </div>
    </>
  )
}

export const DeleteEvalModal = ({
  open,
  setOpen,
  evalRecord,
  app,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  evalRecord: DetailedEval | null
  app: App
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {evalRecord && (
            <DeleteEvalForm
              setOpen={setOpen}
              evalRecord={evalRecord}
              app={app}
              workspace={workspace}
            />
          )}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
