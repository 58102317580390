import type { Workspace } from '@prisma/client'
import { Plus } from '@untitled-ui/icons-react'
import { useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Input, Popover, toast } from '@/client/components'
import { FieldError, Select } from '@/common/components/forms'
import { useFetchBehaviourFeedbackValues, useUser } from '@/common/hooks'
import { CustomFeedbackBehaviourPopover } from '@/pages/[workspaceSlug]/settings'
import type { CreateFeedbackSchemaType } from '@/server/api/routers/feedback'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import type { DetailedData } from '../../tables/types'

export function CustomFeedbackActionPopover({
  open,
  isOpenChange,
}: {
  open: boolean
  isOpenChange: (val: boolean) => void
}) {
  const createActionFeedbackType = api.feedback.createActionFeedbackValueType.useMutation()
  const utils = api.useUtils()
  const [loading, setLoading] = useState(false)
  const [feedbackType, setFeedbackType] = useState<string>('')

  async function onSubmit() {
    await createActionFeedbackType.mutateAsync(
      { actionType: feedbackType },
      {
        onSuccess: () => {
          toast.success({
            title: 'Custom behavior added',
            description: 'Your custom action added to the signal options',
          })
        },
        onError: () => {
          toast.error({
            title: 'An error occurred',
            description: 'An error occurred while adding your custom action',
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
    await utils.feedback.getBehaviourFeedbackValues.invalidate()
    isOpenChange(false)
  }

  return (
    <Popover.Root open={open} onOpenChange={isOpenChange}>
      <Popover.Trigger asChild>
        <Button variant="outline" startIcon={Plus} onClick={() => isOpenChange(!open)} />
      </Popover.Trigger>
      <Popover.Content className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none dark:text-zinc-200">Custom behavior</h4>
            <p className="text-sm text-muted-foreground dark:text-zinc-400">
              Add custom behavior to your signal options
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <Input
              label={'Custom behavior'}
              id="width"
              placeholder={'Enter custom behavior (eg. Converted)'}
              value={feedbackType}
              onChange={(e) => {
                setFeedbackType(e.target.value)
                e.stopPropagation()
              }}
            />
            <Button onClick={() => void onSubmit()} disabled={loading} loading={loading}>
              Add Behavior
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}

export function useUpdateDataForm({
  workspace,
  data,
  onSuccess: onSuccessCallback,
}: {
  workspace: Workspace
  data: DetailedData
  onSuccess?: () => void | Promise<void>
}) {
  const [isRefresh, setRefresh] = useState(false)
  const addFeedback = api.feedback.create.useMutation()
  const deleteFeedback = api.feedback.delete.useMutation()
  const { user } = useUser()

  const { behaviourFeedbackValues } = useFetchBehaviourFeedbackValues()

  const actionOptions = [
    { id: 'saved', name: 'Saved' },
    { id: 'deleted', name: 'Deleted' },
    { id: 'selected', name: 'Selected' },
    { id: 'copied', name: 'Copied' },
    { id: 'shared', name: 'Shared' },
  ]

  const issueOptions = [
    { id: 'hallucination', name: 'Hallucination' },
    { id: 'inappropriate', name: 'Inappropriate' },
    { id: 'repetition', name: 'Repetition' },
  ]

  function onSuccess() {
    toast.success({
      title: 'Feedback added',
      description: 'Successfully added feedback to the data',
    })
    onSuccessCallback && void onSuccessCallback()
  }

  const feedback = {
    action: data.Feedback.find((f) => f.type === 'action')?.value,
    issue: data.Feedback.find((f) => f.type === 'issue')?.value,
    correction: data.Feedback.find((f) => f.type === 'correction')?.value,
  }

  const actionEnums = ['saved', 'selected', 'copied', 'shared', 'deleted'] as const
  const dataActionEnum = feedback.action as (typeof actionEnums)[number]

  const issueEnums = ['hallucination', 'inappropriate', 'repetition'] as const
  const dataIssueEnum = feedback.issue as (typeof issueEnums)[number]

  const options = {
    action: actionOptions,
    issue: issueOptions,
  }

  const actionValues = data.Feedback.filter((f) => f.type === 'action').map((f) => ({
    id: f.value,
    name: actionOptions.find((o) => o.id === f.value)?.name || dataActionEnum,
  }))
  const issuesValues = data.Feedback.filter((f) => f.type === 'issue').map((f) => ({
    id: f.value,
    name: issueOptions.find((o) => o.id === f.value)?.name || dataIssueEnum,
  }))

  const schema = z
    .object({
      action: z.array(z.string()),
      issue: z.array(z.string()),
      correction: z.string(),
    })
    .partial()

  type FormSchema = z.infer<typeof schema>

  const formik = useFormik<FormSchema>({
    initialValues: {
      action: actionValues.length === 0 ? undefined : actionValues.map((a) => a.id),
      issue: issuesValues.length === 0 ? undefined : issuesValues.map((a) => a.id),
      correction: feedback.correction || data.output,
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)
        const feedbackInputs: CreateFeedbackSchemaType[] = []
        if (values.action) {
          const actionsToAdd = values.action.filter(
            (a) => !actionValues.map((av) => av.id).includes(a)
          )

          if (actionsToAdd.length > 0) {
            actionsToAdd.forEach((issue) => {
              feedbackInputs.push({
                type: 'action',
                value: issue,
                dataId: data.id,
                metadata: {},
                workspaceId: workspace.id,
                overrideIfExists: false,
              })
            })
          }

          const actionsToDelete = actionValues.filter(
            (av) => !(values.action ?? []).includes(av.id)
          )

          if (actionsToDelete.length >= 0) {
            actionsToDelete.forEach((action) => {
              const original = data.Feedback.find(
                (f) => f.type === 'action' && f.value === action.id
              )
              original?.id ? void deleteFeedback.mutateAsync({ id: original.id }) : null
            })
          }
        }
        if (values.issue) {
          const issuesToAdd = values.issue.filter(
            (a) => !issuesValues.map((av) => av.id).includes(a)
          )

          if (issuesToAdd.length > 0) {
            issuesToAdd.forEach((issue) => {
              feedbackInputs.push({
                type: 'issue',
                value: issue,
                dataId: data.id,
                metadata: {},
                workspaceId: workspace.id,
                overrideIfExists: false,
              })
            })
          }

          const issuesToDelete = issuesValues.filter((av) => !(values.issue ?? []).includes(av.id))

          if (issuesToDelete.length >= 0) {
            issuesToDelete.forEach((issue) => {
              const original = data.Feedback.find((f) => f.type === 'issue' && f.value === issue.id)
              original?.id ? void deleteFeedback.mutateAsync({ id: original.id }) : null
            })
          }
        }
        if (values.correction) {
          if (values.correction !== data.output) {
            feedbackInputs.push({
              type: 'correction',
              value: values.correction,
              dataId: data.id,
              metadata: {},
              workspaceId: workspace.id,
              overrideIfExists: true,
            })
          } else {
            const original = data.Feedback.find((f) => f.type === 'correction')
            original?.id ? void deleteFeedback.mutateAsync({ id: original.id }) : null
          }
        }

        await addFeedback.mutateAsync(feedbackInputs)

        if (user) {
          const id = user.id ? user.id : ''
          const email = user.email ? user.email : ''
          const uname = user.name ? user.name : ''
          identify_and_group(id, email, uname, workspace?.slug)
        }
        track(EVENT_NAMES.prompt_correction, {
          'App ID': data.action,
          'Workspace ID': workspace?.id,
          'Workspace Name': workspace?.name,
          'User ID': user?.id,
          'User Name': user?.name,
          'User Email': user?.email,
        })
        setSubmitting(false)
        onSuccess()
      } catch (error) {
        setSubmitting(false)
        console.error(error)
        // Field-specific errors from server side validation.
        // if (error.formError) {
        //   const errorObj = formatFormError<{ email: string }>(error.formError)
        //   setErrors(errorObj)
        // }
      }
    },
  })

  const behaviourTypeOptions = behaviourFeedbackValues
    ? behaviourFeedbackValues?.map((f) => ({
        id: f.toLowerCase(),
        name: f.toLowerCase(),
      }))
    : []

  const action = () => (
    <div className="flex items-center space-x-2">
      <Select.Multiple<FormSchema>
        key={data.id}
        defaultOptions={actionValues}
        fieldName="action"
        options={[...options.action, ...behaviourTypeOptions]}
        formik={formik}
      />

      <CustomFeedbackBehaviourPopover hideLabel={true} buttonVariant={'ghost'} />
    </div>
  )

  const issue = () => (
    <Select.Multiple<FormSchema>
      key={data.id}
      defaultOptions={issuesValues}
      fieldName="issue"
      options={options.issue}
      formik={formik}
    />
  )

  const correctionError = () => <FieldError fieldName="correction" formik={formik} />

  const refresh = async () => {
    setRefresh(true)
    await formik.setValues({
      action: actionValues.length === 0 ? undefined : actionValues.map((a) => a.id),
      issue: issuesValues.length === 0 ? undefined : issuesValues.map((a) => a.id),
      correction: feedback.correction || data.output,
    })
    setRefresh(false)
  }

  const isDisabled =
    formik.initialValues.action === undefined &&
    formik.initialValues.issue === undefined &&
    formik.initialValues.correction === (feedback.correction ?? data.output)

  return {
    formik,
    render: {
      select: {
        action,
        issue,
      },
      error: {
        correction: correctionError,
      },
    },
    refresh,
    state: {
      isDisabled,
      isRefresh,
    },
  }
}
