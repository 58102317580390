import type { Context, Workspace } from '@prisma/client'
import type { FieldArrayRenderProps, FormikProps } from 'formik'
import { ErrorMessage, Field, FieldArray, Form, Formik, type FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Combobox, Dialog, toast } from '@/client/components'
import { FieldError, FileUpload } from '@/common/components/forms'
import { useFetchDatabaseProviders } from '@/common/hooks/connections'
import { api, classNames } from '@/utils'
import useCreateDatabaseProviderModal from '../modals/databases/CreateDatabaseProvider/Modal'
import { databaseExtraMetadata, sourceTypes } from './data'

function getPlaceholder(name: string, type: string) {
  if (type === 'files') {
    return 'Select a file'
  }

  if (name === 'query') {
    // TODO differentiate between query from postgres and query from elastic
    return 'SELECT * FROM users'
  }

  if (name === 'homepage') {
    return 'https://www.example.com'
  }

  if (name === 'max_depth') {
    return '100'
  }

  if (name === 'limit') {
    return '100'
  }

  if (name === 'locale') {
    return 'en-us'
  }

  if (name === 'video_url') {
    return 'https://www.youtube.com/watch?v=1234'
  }

  if (name === 'site_url') {
    return 'https://www.example.com'
  }

  if (name === 'filter') {
    return 'key:value'
  }

  return ''
}

const schema = z.object({
  sourceType: z.string(),
  databaseProviderId: z.number().optional(),
  loaderConfig: z
    .array(
      z.object({
        name: z.string({ required_error: 'This field is required ' }),
        value: z.string({ required_error: 'This field is required ' }),
      })
    )
    .default([]),
})

type FormSchema = z.infer<typeof schema>

export type LoaderConfig = {
  name: string
  value?: string
  required?: boolean
}

export function CreateContextSourceForm({
  workspace,
  context,
  setOpen,
}: {
  workspace: Workspace
  context: Context
  setOpen: (val: boolean) => void
}) {
  const { providers } = useFetchDatabaseProviders(workspace.id)
  const { render: renderDatabaseProviderModal, setOpen: setShowDatabaseProviderCreationModal } =
    useCreateDatabaseProviderModal({ workspace })
  const createContextSource = api.contextSource.create.useMutation()
  const [selectedLoader, setSelectedLoader] = useState<null | string>(null)
  const [isLoading, setLoading] = useState(false)
  /*   const [showDatabaseProviderCreationModal, setShowDatabaseProviderCreationModal] = useState(false) */
  const utils = api.useContext()
  const [selectedProvider, setSelectedProvider] = useState<null | string>(null)

  const initialValues: FormSchema = {
    sourceType: '',
    loaderConfig: [],
  }

  const formikConfig = {
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values: FormSchema, { resetForm }: FormikHelpers<FormSchema>) => {
      setLoading(true)

      const sourceType = sourceTypes.find((providers) => providers.name === values.sourceType)
      if (!sourceType) {
        throw new Error('Loader not found')
      }

      await createContextSource.mutateAsync(
        { ...values, contextId: context.id, loaderId: sourceType.id },
        {
          onSettled: () => {
            setLoading(false)
            resetForm()
          },
          onSuccess: () => {
            toast.success({
              title: 'Context source added',
              description: "Successfully added the context's source",
            })
            setOpen(false)
          },
          onError: (err) => {
            toast.error({
              title: 'Something went wrong',
              description: err.message,
            })
          },
        }
      )

      await utils.context.get.invalidate({ id: context.id })
      await utils.contextSource.getAll.invalidate({ contextId: context.id })
    },
  }

  async function onChangeSourceType(loaderName: string, formik: FormikProps<FormSchema>) {
    if (!loaderName) return
    const selectedSourceType = sourceTypes.find((providers) => providers.name === loaderName)
    if (!selectedSourceType) {
      throw new Error('Loader not found')
    }
    setSelectedLoader(loaderName)
    await formik.setFieldValue('sourceType', loaderName)
    const requiredMetadata = selectedSourceType.required_metadata
    await formik.setFieldValue('loaderConfig', requiredMetadata)
    formik.setErrors({})
  }

  async function onChangeProvider(providerId: string, formik: FormikProps<FormSchema>) {
    if (!providerId) return
    const selectedProvider = providers?.find((providers) => providers.id === parseInt(providerId))
    if (!selectedProvider) {
      throw new Error('Provider not found')
    }
    setSelectedProvider(providerId)
    await formik.setFieldValue('databaseProviderId', selectedProvider.id)

    // now update the loader config metadata with the provider's extra metadata
    const providerMetadata = selectedProvider.metadata as {
      provider: string
      config: Record<string, string>
    }

    const extraMetadata = databaseExtraMetadata.find(
      (metadata) => metadata.name === providerMetadata.provider
    )

    if (!extraMetadata) {
      throw new Error('Provider not found')
    }

    const requiredMetadata = extraMetadata.extra_metadata
    await formik.setFieldValue('loaderConfig', requiredMetadata)
  }

  return (
    <React.Fragment>
      {renderDatabaseProviderModal()}
      <Formik
        initialValues={formikConfig.initialValues}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form>
            <div className="rounded-md bg-white px-6 pt-0">
              <div className="mt-2">
                <Combobox.Single
                  classNameContent="bg-white text-sm"
                  label="Context Source"
                  options={sourceTypes
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((type) => {
                      return {
                        value: type.name,
                        label: type.name,
                      }
                    })}
                  value={selectedLoader ? selectedLoader : undefined}
                  placeholder="Select a loader"
                  onChange={(value) => {
                    void onChangeSourceType(value, formik)
                  }}
                />
                <div className="my-6">
                  {formik.values.sourceType === 'Database' && providers && (
                    <React.Fragment>
                      <div className="mt-6">
                        <Combobox.Single
                          classNameContent="bg-white text-sm"
                          label="Provider"
                          options={
                            providers
                              ? providers.map((provider) => {
                                  const metadata = provider.metadata as {
                                    provider: string
                                    config: Record<string, string>
                                  }
                                  return {
                                    value: provider.id.toString(),
                                    label: `${provider.name} (${metadata.provider})`,
                                    // icon: getDatabaseIcon(provider.name),
                                  }
                                })
                              : []
                          }
                          title={formik.values.sourceType}
                          onSelectCreate={() => setShowDatabaseProviderCreationModal(true)}
                          value={selectedProvider ? selectedProvider : undefined}
                          placeholder="Select a provider"
                          onChange={(value) => {
                            void onChangeProvider(value, formik)
                          }}
                        />
                        <FieldError fieldName="databaseProviderId" formik={formik} />
                      </div>
                    </React.Fragment>
                  )}
                  {formik.values.loaderConfig.length > 0 && (
                    <div className="mt-6">
                      <p className="body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500">
                        Context Configuration
                      </p>
                      <FieldArray name="loaderConfig">
                        {({}: FieldArrayRenderProps) => (
                          <div>
                            {formik.values.loaderConfig.length > 0 &&
                              formik.values.loaderConfig.map((item, index) => (
                                <MetadataItem item={item} index={index} key={index} />
                              ))}

                            {/* <Button
                              variant="outline"
                              onClick={() => push({ name: '', value: '', required: false })}
                              type="button"
                              className="mt-4 w-full"
                              startIcon={PlusIcon}
                            >
                              Add Key Item
                            </Button> */}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
              <Button variant="outline" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button disabled={isLoading} loading={isLoading} type="submit">
                {isLoading ? 'Saving...' : 'Add Source'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

const getMetadataItemDetails = (name: string) => {
  let showKeyInput = false
  let label = name

  switch (name) {
    case 'homepage':
      label = 'Homepage'
      break
    case 'max_depth':
      label = 'Max Crawl Depth'
      break
    case 'use_playwright':
      label = 'Use Playwright for crawling?'
      break
    case 'query':
      label = 'Query'
      break
    case 'site_url':
      label = 'Site URL'
      break
    case 'max_urls':
      label = 'Max URLs'
      break
    case 'video_url':
      label = 'Video URL'
      break
    case 'subdomain':
      label = 'Subdomain'
      break
    case 'limit':
      label = 'Limit'
      break
    case 'locale':
      label = 'Locale'
      break
    default:
      showKeyInput = true
      label = 'Value'
      break
  }

  return { label, showKeyInput }
}

export function MetadataItem({
  item,
  index,
  // remove,
  onChangeFiles,
}: {
  item: {
    name: string
    value?: string
    files?: File[]
    type?: string
    required?: boolean
  }
  index: number
  // remove: (index: number) => void
  onChangeFiles?: (files: File[]) => void
}) {
  const { label, showKeyInput } = getMetadataItemDetails(item.name)

  return (
    <div className="mt-3 flex flex-row space-x-3">
      {showKeyInput && (
        <div className="w-1/2 align-top">
          <label htmlFor={`loaderConfig.${index}.name`} className="text-sm text-grey-500">
            Key
          </label>

          {item.required ? (
            <input
              name={`loaderConfig.${index}.name`}
              type="text"
              placeholder="API_KEY"
              className="block w-full min-w-0 flex-1 cursor-not-allowed rounded-md border-grey-300 bg-grey-100 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
              value={item.name}
              disabled
            />
          ) : (
            <Field
              name={`loaderConfig.${index}.name`}
              type="text"
              placeholder="API_KEY"
              className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            />
          )}

          <ErrorMessage
            name={`loaderConfig.${index}.name`}
            component="div"
            className="mt-2 text-xs text-red-500"
          />
        </div>
      )}

      <div className="flex w-full flex-row space-x-2 align-top">
        <div className="w-full">
          <label
            htmlFor={`loaderConfig.${index}.value`}
            className={classNames('text-sm text-grey-500', item.type == 'files' ? 'hidden' : '')}
          >
            {label}
          </label>
          {item.type === 'files' ? (
            <div className="w-full">
              <FileUpload allowDrop={true} onChangeFiles={onChangeFiles} />
            </div>
          ) : (
            <Field
              name={`loaderConfig.${index}.value`}
              placeholder={getPlaceholder(item.name, item.type || '')}
              type="text"
              className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            />
          )}

          <ErrorMessage
            name={`loaderConfig.${index}.value`}
            component="div"
            className="mt-2 text-xs text-red-500"
          />
        </div>

        {/* {item.required ? null : (
          <Button
            type="button"
            variant="outline"
            className="font-inter text-xs mt-6"
            startIcon={TrashIcon}
            onClick={() => remove(index)}
          />
        )} */}
      </div>
    </div>
  )
}

export function CreateContextSourceModal({
  open,
  setOpen,
  workspace,
  context,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
  context: Context
}) {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content>
        <Dialog.Header>
          <h1 className="text-xl font-medium leading-6 p-6 pb-0">Select a Source</h1>
        </Dialog.Header>

        {workspace && (
          <CreateContextSourceForm workspace={workspace} setOpen={setOpen} context={context} />
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
