import { Users01 } from '@untitled-ui/icons-react'
import { useOthersMapped } from 'liveblocks.config'
import { useState } from 'react'
import { Dialog, Tooltip } from '@/client/components'
import { UserAvatar } from '@/common/components'
import { useUser } from '@/common/hooks'

export const OnlineUsersNavItem = ({ isOnlyIconsSidebar }: { isOnlyIconsSidebar: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const otherUsers: Record<string, any> = {}
  const { user: currentUser } = useUser()

  useOthersMapped((other) => other.info).forEach(([_connectionId, info]) => {
    if (info.isMember) {
      otherUsers[info.userId] = {
        id: info.userId,
        image: info.avatar,
        isMember: true,
        ...info,
      }
    }
  })

  if (!currentUser) {
    return null
  }

  const onlineUsers = Object.values(otherUsers) as {
    id: string
    name: string
    image: string
    isMember: boolean
  }[]

  onlineUsers.push({
    id: currentUser.id,
    name: currentUser.name ? currentUser.name : '',
    image: currentUser.image ? currentUser.image : '',
    isMember: true,
  })

  const onlineUsersFiltered = onlineUsers.reduce<
    { id: string; name: string; image: string; isMember: boolean }[]
  >((acc, user) => {
    if (!acc.some((u) => u.id === user.id)) {
      acc.push(user)
    }
    return acc
  }, [])

  if (onlineUsersFiltered.length < 2) {
    return null
  }

  const text = `${onlineUsersFiltered.length} Users Online Now`

  const handleClick = () => {
    setModalOpen(true)
  }

  return (
    <>
      <OnlineUsersModal open={modalOpen} setOpen={setModalOpen} users={onlineUsers} />

      <div className={'flex'}>
        {isOnlyIconsSidebar ? (
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger>
              <div
                onClick={handleClick}
                className="cursor-pointer group mt-0.5 flex items-center rounded-md px-1.5 py-1.5 text-sm truncate font-normal text-grey-700 hover:bg-grey-200 hover:text-grey-900 dark:text-zinc-400 dark:hover:bg-zinc-900 dark:hover:text-grey-200"
              >
                <Users01 className={'h-5 w-5 flex-shrink-0'} aria-hidden="true" />
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content side={'right'}>{text}</Tooltip.Content>
          </Tooltip.Root>
        ) : (
          <div
            onClick={handleClick}
            className="cursor-pointer group mt-0.5 w-full flex items-center rounded-md px-1.5 py-1.5 text-sm truncate font-normal text-grey-700 hover:bg-grey-200 hover:text-grey-900 dark:text-zinc-400 dark:hover:bg-zinc-900 dark:hover:text-grey-200"
          >
            <Users01 className={'mr-4 h-5 w-5 flex-shrink-0'} aria-hidden="true" />
            {text}
          </div>
        )}
      </div>
    </>
  )
}

export function OnlineUsersModal({
  open,
  setOpen,
  users,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  users: {
    id: string
    name: string
    image: string
    isMember: boolean
  }[]
}) {
  const nonStaffUsers = users.reduce<
    { id: string; name: string; image: string; isMember: boolean }[]
  >(
    (acc, user) => {
      if (!acc.some((u) => u.id === user.id)) {
        acc.push(user)
      }
      return acc
    },
    [] as { id: string; name: string; image: string; isMember: boolean }[]
  )

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content className={'p-6'}>
        <Dialog.Header>
          <Dialog.Title>
            {nonStaffUsers.length} User{nonStaffUsers.length > 1 ? 's' : ''} Online Now
          </Dialog.Title>

          <Dialog.Description>
            <div className="flex flex-col gap-4 mt-5">
              {nonStaffUsers.map((user) => {
                return (
                  <div key={user.id} className="flex gap-3 items-center">
                    <UserAvatar user={user} className="w-8 h-8" />
                    <span className="capitalize">{user.name}</span>
                  </div>
                )
              })}
            </div>
          </Dialog.Description>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
