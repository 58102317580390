import { CheckCircle, Clipboard } from '@untitled-ui/icons-react'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'

const CopyButton = ({ text, icon }: { text: string; icon?: React.FunctionComponent }) => {
  const [effect, setEffect] = React.useState(false)
  const [copied, setCopied] = React.useState(false)

  React.useEffect(() => {
    if (effect) {
      setTimeout(() => {
        setEffect(false)
      }, 1500)
    }
  }, [effect])

  const handleCopy = () => {
    setEffect(true)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <div className="flex items-center">
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <button
          className={cn(
            effect ? 'text-grey-900' : 'text-grey-600 dark:text-zinc-600 hover:text-grey-900',
            'inline-flex items-center rounded-md p-1 hover:bg-grey-100 dark:hover:bg-zinc-800 focus:outline-none'
          )}
          onClick={handleCopy}
          onAnimationEnd={() => {
            setEffect(false)
          }}
        >
          {copied ? (
            <div className="caption1 absolute -ml-24 flex rounded-full bg-grey-50 dark:bg-black p-2 text-success transition duration-300 ease-in-out">
              Copied <Icon className="ml-1" size="sm" component={CheckCircle} />
            </div>
          ) : (
            <span className="transition duration-300 ease-in-out" />
          )}

          <Icon size="sm" component={icon || Clipboard} />
        </button>
      </CopyToClipboard>
    </div>
  )
}

export { CopyButton }
