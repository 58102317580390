/* eslint-disable @next/next/no-img-element */
export function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-gradient-to-br from-grey-200 to-grey-25 dark:from-zinc-900 dark:to-zinc-950">
      <div className="flex min-w-screen min-h-screen flex-col items-center justify-center sm:py-12 p-8 w-full h-full">
        <div className="sm:mx-4 flex h-fit w-full max-w-md flex-col rounded-lg bg-white dark:bg-black p-6 pt-8 text-center shadow ring-1 ring-zinc-950/5 dark:ring-zinc-800 sm:max-w-md">
          {children}
        </div>
        <div className="text-xs pt-6 mx-4 px-6 sm:max-w-md text-grey-500 dark:text-zinc-400 leading-6 text-justify">
          By creating an account and using Klu you agree to the platform{' '}
          <a
            href="https://klu.ai/terms"
            className="text-primary-500 dark:text-primary-400 hover:text-primary-800 dark:hover:text-primary-600 transition-all ease-in-out duration-200 border-b border-white/0 hover:border-1 hover:border-primary-800"
          >
            Terms of Service
          </a>{' '}
          &{' '}
          <a
            href="https://klu.ai/privacy"
            className="text-primary-500 dark:text-primary-400 hover:text-primary-800 dark:hover:text-primary-600 transition-all ease-in-out duration-200 border-b border-white/0 hover:border-1 hover:border-primary-800"
          >
            Privacy Policy
          </a>
          . Klu complies with GDPR and CCPA. We can execute a Data Processing Agreement if your
          organization or app requires it. The Klu Platform has been evaluated by a third-party
          security auditor and is SOC 2 Type 2 compliant. Klu uses Amazon RSA 2048 Encryption and is
          built on enterprise data centers that are SOC 1-3, and ISO 27001, 27017, 27018, and 27701
          certified. Klu platform monitored by{' '}
          <a
            href="https://status.klu.ai/"
            className="text-primary-500 dark:text-primary-400 hover:text-primary-800 dark:hover:text-primary-600 transition-all ease-in-out duration-200 border-b border-white/0 hover:border-1 hover:border-primary-800"
          >
            Betterstack
          </a>{' '}
          & Cloudflare.
        </div>
        <div className="flex justify-center space-x-6 mt-6">
          <div>
            <img src="/images/soc2.png" alt="SOC 2 Compliant" className="h-12 w-auto" />
          </div>
          <div>
            <img src="/images/gdpr.svg" alt="GDPR Compliant" className="h-12 w-auto" />
          </div>
          <div>
            <img src="/images/ccpa.svg" alt="CCPA Compliant" className="h-12 w-auto" />
          </div>
        </div>
      </div>
    </div>
  )
}
