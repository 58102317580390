import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { deleteEnvironmentFormSchema } from './schema'
import type { DeleteEnvironmentFormInputs, FormProps } from './types'

export const useDeleteEnvironmentForm = ({ workspace, environment, onIsOpenChange }: FormProps) => {
  const deleteEnvironment = api.workspace.deleteEnvironment.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: DeleteEnvironmentFormInputs = {}

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Environment deleted',
      description: 'Your environment was deleted successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: DeleteEnvironmentFormInputs,
      { resetForm }: FormikHelpers<DeleteEnvironmentFormInputs>
    ) => {
      await deleteEnvironment.mutateAsync({
        workspaceId: workspace.id,
        environmentId: environment.id,
      })
      await utils.workspace.getEnvironments.invalidate({ workspaceId: workspace.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.environment_edited, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      deleteEnvironment,
      environment.id,
      onIsOpenChange,
      onSuccess,
      user,
      utils.workspace.getEnvironments,
      workspace.id,
      workspace.name,
      workspace?.slug,
    ]
  )

  const formikConfig: FormikConfig<DeleteEnvironmentFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deleteEnvironmentFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
