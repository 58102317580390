import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { cn } from '@/client/utils'

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      `
        transition
        duration-200
        ease-in-out
        body1
        inline-flex
        items-center
        justify-center
        whitespace-nowrap
        border-b-[3px]
        border-transparent
        py-4
        font-medium
        text-grey-500
        hover:text-grey-800 
        focus-visible:outline-none
        disabled:pointer-events-none
        data-[state=active]:border-grey-800
        data-[state=active]:text-grey-800
        dark:hover:text-grey-50
        dark:data-[state=active]:border-white
        dark:data-[state=active]:text-grey-50
        dark:data-[state=inactive]:text-zinc-500
        dark:hover:data-[state=inactive]:text-zinc-300
      `,
      className
    )}
    {...props}
  />
))

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

export { TabsTrigger }
