import type { Workspace } from '@prisma/client'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { getProviderIcon } from '@/client/assets/icons/providers/getProviderIcon'
import { Button, Combobox, Dialog, Input, toast } from '@/client/components'
import { FieldError, type SelectOption } from '@/common/components/forms'
import { useApplication } from '@/common/components/ui/context'
import { useFetchWorkspaceModelProviders } from '@/common/hooks'
import type { WorkspaceModelProviderWithProvider } from '@/common/types/workspaceModelProvider'
import { api, defaultLlmProviders, llmProviders } from '@/utils'

function ModelProviderForm({
  workspace,
  setOpen,
  forced,
  defaultProviderId,
  workspaceModelProvider,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
  forced?: boolean // prompting the user to create a model
  defaultProviderId?: number
  workspaceModelProvider?: WorkspaceModelProviderWithProvider
}) {
  // if providing a workspaceModelProvider, we are editing an existing provider
  const { billing } = useApplication()

  const availableLlmProviders = billing.hasScaleLLMAccess ? llmProviders : defaultLlmProviders

  const [isLoading, setLoading] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState<SelectOption | null>(() => {
    if (defaultProviderId) {
      const llmFound = availableLlmProviders.find((provider) => provider.id === defaultProviderId)
      if (llmFound) {
        return { id: llmFound.id, name: llmFound.name }
      }
      return null
    }
    return null
  })
  const [verified, setVerified] = useState(false)

  const { providers } = useFetchWorkspaceModelProviders(workspace.id)

  const createProvider = api.workspaceModelProvider.create.useMutation()
  const updateProvider = api.workspaceModelProvider.update.useMutation()
  const validateAPIKey = api.model.validateAPIKey.useMutation()

  const utils = api.useContext()

  const kluExists = providers ? providers.some((p) => p.providerId == 14) : false
  const filteredProviders = kluExists
    ? availableLlmProviders.filter((provider) => provider.name !== 'Klu')
    : availableLlmProviders

  function onSuccess(providerName: string) {
    toast.success({
      title: `${providerName.charAt(0).toUpperCase() + providerName.slice(1)} connection added`,
      description: `Successfully added ${providerName} connection`,
    })
    setOpen(false)
  }

  const schema = z.object({
    name: z.string().optional(),
    key: z.string().optional(),
    providerId: z.number().min(1, { message: 'Select a provider' }),
    url: z.string().url().optional(),
    model: z.string().optional(),
    metadata: z
      .array(
        z.object({
          name: z.string({ required_error: 'This field is required ' }),
          value: z.string().optional(),
        })
      )
      .optional()
      .default([]),
  })

  type FormSchema = z.infer<typeof schema>
  const formik = useFormik<FormSchema>({
    initialValues: {
      name: '',
      key: '',
      providerId: defaultProviderId ?? 1,
      url: '',
      model: '',
      metadata: [],
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      if (!values.key && ![14, 16, 24].includes(values.providerId)) {
        toast.error({
          title: 'API key is missing',
          description: 'Please enter an API key',
        })
        return
      }
      if (workspaceModelProvider) {
        const provider = await updateProvider.mutateAsync(
          {
            key: values.key ?? '',
            providerId: values.providerId,
            workspaceModelProviderId: workspaceModelProvider.id,
            name: values.name,
          },
          {
            onSettled: () => {
              setLoading(false)
            },
            onError: (err) => {
              toast.error({
                title: 'An error occurred',
                description: err.message ?? 'Failed to update provider',
              })
            },
          }
        )
        onSuccess(provider?.name ?? 'Provider')
      } else {
        const provider = await createProvider.mutateAsync(
          { ...values, workspaceId: workspace.id },
          {
            onSettled: () => {
              setLoading(false)
            },
            onError: (err) => {
              toast.error({
                title: 'An error occurred',
                description: err.message ?? 'Failed to update provider',
              })
            },
          }
        )
        onSuccess(provider?.provider.name ?? 'Provider')
      }
      resetForm()
      await utils.workspaceModelProvider.getAll.invalidate({ workspaceId: workspace.id })
    },
  })

  useEffect(() => {
    setVerified(false)
  }, [formik.values.key])

  const shouldNotShowAPIKeyField = ['Klu', 'Amazon Bedrock']
  const shouldShowMetadataFieds = ['Amazon Bedrock', 'Cloudflare AI']
  const modelFieldRequiredProviders = [
    'Replicate',
    'Hugging Face Hub',
    'Google PaLM',
    'Azure AI Endpoint',
    'API Endpoint',
  ]
  const urlRequiredProviders = ['Azure OpenAI', 'GCP Vertex', 'Azure AI Endpoint', 'API Endpoint']
  const shouldShowURLField = (): boolean => {
    if (!selectedProvider) return false
    return urlRequiredProviders.includes(selectedProvider.name)
  }
  const shouldShowApiKeyField = (): boolean => {
    if (!selectedProvider) return false
    return !shouldNotShowAPIKeyField.includes(selectedProvider.name)
  }
  const shouldShowModelField = (): boolean => {
    if (!selectedProvider) return false
    return modelFieldRequiredProviders.includes(selectedProvider.name)
  }

  const shouldShowMetadata = (): boolean => {
    if (!selectedProvider) return false
    return shouldShowMetadataFieds.includes(selectedProvider.name)
  }

  const requiredUrlIsMissing = (): boolean => {
    if (shouldShowURLField() && (!formik.values.url || formik.values.url === '')) {
      return true
    } else {
      return false
    }
  }

  async function verifyRequest() {
    if (!selectedProvider) return
    if (requiredUrlIsMissing()) {
      await formik.setTouched({ url: true })
      formik.setErrors({ url: 'cannot be blank' })
      return
    }
    if (!shouldShowApiKeyField()) {
      await formik.setFieldValue('key', 'klu')
    }
    if (!shouldShowMetadata()) {
      await formik.setFieldValue('metadata', [])
    }

    setLoading(true)

    const response = await validateAPIKey.mutateAsync(
      {
        provider:
          typeof selectedProvider.id === 'string'
            ? parseInt(selectedProvider.id)
            : selectedProvider.id,
        api_key: formik.values.key,
        workspaceSlug: workspace.slug,
        url: formik.values.url,
        metadata: formik.values.metadata.map((item) => ({
          name: item.name,
          value: item.value ?? '',
        })),
        model: formik.values.model,
      },
      {
        onSettled: () => {
          setLoading(false)
        },
        onSuccess: () => {
          setVerified(true)
          toast.success({
            title: 'API key verified',
            description: 'Your API key is valid',
          })
        },
        onError: (err) => {
          setVerified(false)
          toast.error({
            title: 'An error occurred',
            description: err.message ?? 'Your API key is invalid',
          })
        },
      }
    )

    if (response.validated) {
      setVerified(true)
      // submit the form
      formik.handleSubmit()
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="max-w-lg">
        <div className="mt-3 p-6 text-center sm:mt-0 sm:text-left">
          <Dialog.Title className="text-xl font-medium leading-6 text-grey-900">
            Add LLM Provider
          </Dialog.Title>
          {forced && (
            <p className="mt-2 text-sm text-grey-600 dark:text-zinc-600">
              To use this functionality, connect your OpenAI keys. You can find your keys in your{' '}
              <a
                className="text-grey-500 underline outline-none hover:text-grey-600 dark:text-zinc-600 focus:outline-none"
                href="https://platform.openai.com/account/api-keys"
                target="_blank"
                rel="noreferrer"
              >
                OpenAI dashboard
              </a>
              .
            </p>
          )}
          <div className="mt-6">
            <div className="mt-2">
              <Combobox.Single
                classNameContent="w-[287px] bg-white text-sm"
                label="Provider"
                options={filteredProviders.map((provider) => ({
                  value: String(provider.id),
                  label: provider.name,
                  icon: getProviderIcon(provider.id),
                }))}
                value={selectedProvider ? String(selectedProvider.id) : undefined}
                placeholder="Select a provider"
                onChange={(value) => {
                  if (!value) return
                  const selectedProvider = filteredProviders.find(
                    (providers) => providers.id === parseInt(value)
                  )
                  if (!selectedProvider) {
                    throw new Error('Provider not found')
                  }

                  setSelectedProvider(selectedProvider)
                  if (selectedProvider.name === 'Amazon Bedrock') {
                    void formik.setFieldValue('metadata', [
                      { name: 'AWS Access Key', value: '' },
                      { name: 'AWS Secret Key', value: '' },
                      { name: 'AWS Region', value: '' },
                      { name: 'AWS Session Token', value: '' },
                    ])
                  }
                  if (selectedProvider.name === 'Cloudflare AI') {
                    void formik.setFieldValue('metadata', [{ name: 'Account ID', value: '' }])
                  }
                  void formik.setFieldValue('providerId', selectedProvider.id)
                  void formik.setTouched({ providerId: true })
                  if (selectedProvider.name === 'Klu') {
                    setVerified(true)
                  } else {
                    setVerified(false)
                  }
                }}
              />
              <FieldError fieldName="providerId" formik={formik} />
            </div>
          </div>
          {shouldShowApiKeyField() && (
            <div className="mt-6">
              <Input
                type="text"
                id="key"
                name="key"
                autoComplete="off"
                label={'Your API Key'}
                onChange={formik.handleChange}
                value={formik.values.key}
              />
              <FieldError fieldName="key" formik={formik} />
            </div>
          )}
          {shouldShowURLField() && (
            <div className="mt-6">
              <Input
                type="text"
                id="url"
                name="url"
                autoComplete="off"
                label={'URL'}
                placeholder="https://deployment.ai.net"
                onChange={formik.handleChange}
                value={formik.values.url}
              />
            </div>
          )}
          {shouldShowModelField() && (
            <div className="mt-6">
              <Input
                type="text"
                id="model"
                name="model"
                label={'Model name (or version)'}
                placeholder="mistral-large"
                onChange={formik.handleChange}
                value={formik.values.model}
              />
            </div>
          )}
          {shouldShowMetadata() && (
            <div className="mt-6">
              {formik.values.metadata.map((item, index) => (
                <div key={index} className="mt-6">
                  <Input
                    type="text"
                    id={`metadata[${index}].value`}
                    name={`metadata[${index}].value`}
                    label={item.name}
                    onChange={formik.handleChange}
                    value={item.value}
                  />
                  <FieldError fieldName={`metadata[${index}].value`} formik={formik} />
                </div>
              ))}
            </div>
          )}
          <details className="mt-6">
            <summary className="cursor-pointer text-xs text-grey-500">Additional settings</summary>
            <div className="pt-4">
              <Input
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                label={'Name (Optional)'}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
          </details>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <div className="flex items-center space-x-2">
          {!verified && (
            <Button
              disabled={
                isLoading || (!formik.values.key && ![16, 24].includes(formik.values.providerId))
              }
              type="submit"
              onClick={() => void verifyRequest()}
            >
              Validate
            </Button>
          )}

          {verified && (
            <Button disabled={isLoading || !formik.isValid} type="submit" loading={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

export function CreateModelProviderModal({
  open,
  setOpen,
  workspace,
  forced,
  defaultProviderId,
  workspaceModelProvider,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
  forced?: boolean
  defaultProviderId?: number
  workspaceModelProvider?: WorkspaceModelProviderWithProvider
}) {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content>
        <ModelProviderForm
          workspace={workspace}
          setOpen={setOpen}
          forced={forced}
          defaultProviderId={defaultProviderId}
          workspaceModelProvider={workspaceModelProvider}
        />
      </Dialog.Content>
    </Dialog.Root>
  )
}
