import type { Context } from '@prisma/client'
import { useEffect } from 'react'
import { api } from '@/utils'
import { useUser } from './user'

export function useFetchContextAll() {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getAll.useQuery(undefined, {
    enabled: user !== undefined,
    refetchOnWindowFocus: false,
  })
  return {
    contexts: data,
    isLoading,
    error,
  }
}

export function useFetchContext(contextId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.get.useQuery(
    {
      id: contextId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    context: data,
    isLoading,
    error,
  }
}

export function useFetchContextBySlug(contextSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getBySlug.useQuery(
    {
      slug: contextSlug,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    context: data,
    isLoading,
    error,
  }
}

export function useFetchIndexStatus(context: Context) {
  const { user } = useUser()
  const utils = api.useContext()
  const { data, isLoading, error } = api.context.getStatus.useQuery(
    { id: context.id },
    {
      enabled: user !== undefined,
      refetchInterval: function (data) {
        return data?.processed ? false : 1000
      },
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (data?.processed) {
      utils.context.get.invalidate({ id: context.id }).catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    processed: data?.processed,
    isLoading,
    error,
  }
}

export function useFetchContextSchedule(context: Context) {
  if (!context.guid) {
    throw new Error('Context guid is undefined')
  }

  const { user } = useUser()
  const { data, isLoading, error } = api.context.getSchedule.useQuery(
    { contextGuid: context.guid },
    {
      enabled: user !== undefined,
    }
  )
  return {
    scheduledTask: data,
    isLoading,
    error,
  }
}

export function useFetchContextActions(contextSlug: string, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getActions.useQuery(
    { contextSlug: contextSlug, workspaceId: workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    actions: data,
    isLoading,
    error,
  }
}

export function useFetchContextInIds(contextIds: number[], workspaceSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getForIds.useQuery(
    { contextIds, workspaceSlug },
    {
      enabled: user !== undefined && contextIds.length > 0,
    }
  )
  return {
    contexts: data,
    isLoading,
    error,
  }
}

export function useFetchContextsMetaCache(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getContextsCache.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    contextMetaCache: data,
    isLoading,
    error,
  }
}

export async function useRefreshIndex(context: Context) {
  const refreshindex = api.context.refresh.useMutation()
  const utils = api.useContext()
  await refreshindex.mutateAsync({ id: context.id })

  void utils.context.get.invalidate({
    id: context.id,
  })
}

export function useFetchContextVersions(contextId: number) {
  const { user } = useUser()

  const { data, isLoading, error } = api.contextVersion.getAllForContext.useQuery(
    { contextId: contextId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    versions: data,
    isLoading,
    error,
  }
}

export function useFetchContextLogs(filters: Record<string, any>) {
  const { user } = useUser()
  const { data, isLoading, error } = api.context.getLogs.useQuery(
    { filters },
    {
      enabled: user !== undefined,
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}
