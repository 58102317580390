import type { Invite, User, Workspace } from '@prisma/client'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { api } from '@/utils'

function DeleteInviteForm({
  setOpen,
  invite,
  workspace,
}: {
  workspace: Workspace
  invite: Invite & {
    createdBy: User
  }
  setOpen: (val: boolean) => void
}) {
  const [loading, setLoading] = useState(false)
  const deleteInvite = api.invite.delete.useMutation()
  const utils = api.useContext()

  async function onSuccess() {
    await utils.invite.getAll.invalidate({ workspaceId: workspace.id })
    toast.success({
      title: 'Workspace invite cancelled',
      description: 'Invite cancelled successfully',
    })
    setOpen(false)
  }

  async function onDelete() {
    try {
      setLoading(true)
      await deleteInvite.mutateAsync({ inviteId: invite.id })
      await onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Cancel invite</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">
                Are you sure you want to cancel the invite for {invite.email}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 p-4 py-5 pr-6 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Cancelling...' : 'Cancel invite'}
        </Button>
      </div>
    </>
  )
}

export const DeleteInviteModal = ({
  open,
  setOpen,
  invite,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  invite:
    | (Invite & {
        createdBy: User
      })
    | null
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {invite && <DeleteInviteForm setOpen={setOpen} invite={invite} workspace={workspace} />}{' '}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
