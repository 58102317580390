import { Check, ChevronDown } from '@untitled-ui/icons-react'
import React, { useMemo } from 'react'
import { Command, FieldMessage, Icon, Popover, ScrollArea, Tag } from '@/client/components'
import { cn } from '@/client/utils'
import type { ComboboxMultipleProps } from './types'

const ComboboxMultiple = ({
  className,
  classNameContent,
  label,
  options,
  value = [],
  hideLabel,
  hideTags,
  placeholder,
  placeholderSearch,
  disabled,
  isLoading,
  emptyText,
  message,
  hasError,
  onChange,
  onRemove,
  noSearch = false,
  fitHeightToContent = false,
}: ComboboxMultipleProps) => {
  const [open, setOpen] = React.useState(false)

  // Sort options alphabetically
  const optionsSortedAlphabetically = useMemo(
    () => options?.sort((a, b) => a.label.localeCompare(b.label)),
    [options]
  )

  const selectedOptions =
    value && optionsSortedAlphabetically.filter((option) => value.includes(option.value))

  return (
    <div className={cn('relative', className)}>
      <label
        className={cn(
          'body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500',
          { 'text-grey-400 dark:text-zinc-600': disabled },
          {
            'sr-only': hideLabel,
          }
        )}
      >
        {label}
      </label>

      <Popover.Root open={open} onOpenChange={setOpen} modal>
        <Popover.Trigger asChild disabled={disabled}>
          <button
            className={cn(
              'flex font-inter w-full items-center justify-between rounded-md border bg-white dark:bg-black px-3 py-2 text-sm outline-none truncate',
              open
                ? 'border-grey-400 dark:border-white'
                : 'border-grey-300 focus-within:border-grey-400 dark:border-zinc-800 dark:focus-within:border-white',
              { 'border-error focus-within:border-error': hasError },
              { 'border-grey-100 bg-grey-100 dark:border-zinc-800 dark:bg-grey-700': disabled }
            )}
            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
            role="combobox"
            aria-expanded={open}
          >
            <span className="text-grey-300 dark:text-zinc-600">{placeholder || 'Select...'}</span>

            <Icon
              className={cn(
                'shrink-0  text-grey-500 transition-transform duration-200',
                { 'rotate-180': open },
                disabled ? 'text-grey-300 dark:text-zinc-600' : ' text-grey-500'
              )}
              size="md"
              component={ChevronDown}
            />
          </button>
        </Popover.Trigger>

        <Popover.Content
          className={cn('p-0', classNameContent)}
          matchTargetWidth
          collisionPadding={20}
        >
          <Command.Root
            filter={(value, search) => {
              if (value.includes(search.toLowerCase())) return 1 // optimization: if value and label are equal, then we don't have to search for label

              const label = optionsSortedAlphabetically.find(
                (opt) => opt.value.toLowerCase() === value
              )?.label
              if (!label) return 0

              return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
            }}
          >
            <div className="flex flex-col gap-3">
              {noSearch ? null : <Command.Input placeholder={placeholderSearch || 'Search...'} />}

              {isLoading ? (
                <Command.Loading>Loading</Command.Loading>
              ) : (
                <ScrollArea
                  className={cn({
                    'h-72': fitHeightToContent === false,
                    'h-auto': fitHeightToContent === true,
                  })}
                >
                  <Command.Empty>{emptyText || 'Not found.'}</Command.Empty>

                  <Command.Group>
                    {optionsSortedAlphabetically.map((option) => {
                      const selectedOption = selectedOptions.find(
                        (opt) => opt.value === option.value
                      )

                      return (
                        <Command.Item
                          key={option.value}
                          className="flex items-center gap-2 pl-8"
                          value={`${option.value}`}
                          onSelect={(currentValue) => {
                            if (onChange) {
                              onChange(
                                value.includes(currentValue)
                                  ? value.filter((v) => v !== currentValue)
                                  : [...value, currentValue]
                              )
                            }
                          }}
                        >
                          <div
                            className={cn(
                              'absolute left-2 flex h-4 w-4 items-center justify-center rounded border border-grey-700 dark:border-white',
                              {
                                'bg-primary-lightest-1 border-primary text-primary dark:border-primary dark:bg-primary dark:text-grey-50':
                                  selectedOption,
                              }
                            )}
                          >
                            <Icon
                              className={cn({
                                'sr-only': !selectedOption,
                              })}
                              size="sm"
                              component={Check}
                            />
                          </div>

                          {option.icon ? (
                            <Icon
                              className="text-grey-900 dark:text-white"
                              size="lg"
                              component={option.icon}
                            />
                          ) : null}

                          <span>{option.htmlLabel || option.label}</span>
                        </Command.Item>
                      )
                    })}
                  </Command.Group>
                </ScrollArea>
              )}
            </div>
          </Command.Root>
        </Popover.Content>
      </Popover.Root>

      {message ? <FieldMessage message={message} disabled={disabled} hasError={hasError} /> : null}

      {!hideTags && value.length > 0 && (
        <div className="mt-2 flex flex-wrap items-center justify-start gap-2">
          {value.map((v, index) => {
            const option = optionsSortedAlphabetically.find((opt) => opt.value === v)

            if (!option) {
              return null
            }

            return (
              <Tag
                key={index}
                variant="primary"
                onRemove={() => {
                  onRemove(option)
                }}
              >
                {option.label}
              </Tag>
            )
          })}
        </div>
      )}
    </div>
  )
}

ComboboxMultiple.displayName = 'ComboboxMultiple'

export { ComboboxMultiple }
