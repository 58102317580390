/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type { Action, Environment, Workspace } from '@prisma/client'
import React, { useState } from 'react'
import { Switch } from '@/client/components'
import DiffViewer from '@/client/components/DiffViewer'
import { ActionVersionEnvironmentInlineEditForm } from '@/client/containers/views/Action/ActionVersionEnvironment/Form/inline'
import type { DetailedActionVersion, ModelConfig } from '@/common/components/actions/types'
import { useFetchContextInIds, useFetchModel, useFetchSkillInIds } from '@/common/hooks'
import type { ActionVersionMetadata } from '@/server/service/types'
import { classNames } from '@/utils'
import { tryParseJSON } from '@/utils/json'

export function ActionVersionEnvironmentBadge({ version }: { version: DetailedActionVersion }) {
  const getColor = (name: string): string => {
    switch (name) {
      case 'Preview':
        return 'bg-purple-100 text-purple-700 ring-purple-400'
      case 'Staging':
        return 'bg-primary-100 text-primary-700 ring-primary-400'
      case 'Production':
        return 'bg-success-100 ring-success-400 text-green-700 ring-green-600/20'
      default:
        return 'bg-grey-100 text-grey-700 ring-grey-600/20'
    }
  }

  const getSvgColor = (name: string): string => {
    switch (name) {
      case 'Preview':
        return 'fill-purple-500'
      case 'Staging':
        return 'fill-primary-500'
      case 'Production':
        return 'fill-green-500'
      default:
        return 'fill-grey-500'
    }
  }

  if (version.deployments.length === 0) return null

  return (
    <div className="flex flex-wrap gap-2">
      {version.deployments.map((deployment) => (
        <span
          key={new Date(deployment.createdAt).toISOString()}
          className={classNames(
            'inline-flex items-center gap-x-1.5 rounded-lg px-2 py-1 text-xs font-medium  ring-1 ring-inset ',
            getColor(deployment.environment.name)
          )}
        >
          <svg
            className={classNames('h-1.5 w-1.5', getSvgColor(deployment.environment.name))}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          <span className="capitalize">{deployment.environment.name}</span>
        </span>
      ))}
    </div>
  )
}

function ActionVersionModel({ modelId }: { modelId: number }) {
  const { model } = useFetchModel(modelId)
  return (
    <>
      {model && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-grey-900">Model</dt>
          <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
            {model.llm}
          </dd>
        </div>
      )}
    </>
  )
}

type LogitBias = Record<string, number>[]

export function ActionVersionDetails({
  action,
  version,
  workspace,
  onIsOpenChange,
  environments,
  previousVersion,
}: {
  version: DetailedActionVersion
  action: Action
  workspace: Workspace
  onIsOpenChange: (isOpen: boolean) => void
  environments: Environment[]
  previousVersion?: DetailedActionVersion
}) {
  const [viewType, setViewType] = useState<'yaml' | 'separate'>('yaml')
  const metadata = version.metadata as ActionVersionMetadata
  const modelConfig = version.modelConfig as never as ModelConfig | undefined | null
  const { contexts } = useFetchContextInIds(
    metadata.contextIds ? metadata.contextIds : [],
    workspace.slug
  )
  const { skills } = useFetchSkillInIds(metadata.skillIds ? metadata.skillIds : [])

  const oldMetadata = previousVersion?.metadata as ActionVersionMetadata | undefined
  const oldModelConfig = previousVersion?.modelConfig as never as ModelConfig | undefined | null

  function formatLogitBias(logitBias: LogitBias | undefined | null) {
    if (!logitBias) return ''
    return logitBias.map((bias) => ({
      tokenId: bias.tokenId,
      biasValue: bias.biasValue,
    }))
  }

  /** Parse the content key value into an array of string */
  function messagesJSONStringReplacer(key: string, value: string) {
    if (key === 'content') {
      return value
        .split('\n')
        .map((line: string) => line)
        .map((line: string) => line.replace(`\"`, `'`).trim())
        .filter((line: string) => line.length > 0)
    }
    return value
  }

  const oldVersion = React.useMemo(
    () => ({
      name: previousVersion?.name,
      description: previousVersion?.description,
      model: oldMetadata?.modelName,
      type: previousVersion?.action_type,
      prompt:
        previousVersion?.action_type === 'chat'
          ? {
              chat: {
                templateMessages: tryParseJSON<Array<object>>(previousVersion?.prompt),
              },
            }
          : { template: tryParseJSON<Array<object>>(previousVersion?.prompt ?? '{}') },
      model_config: {
        topP: oldModelConfig?.topP,
        temperature: oldModelConfig?.temperature,
        maxResponseLength: oldModelConfig?.maxResponseLength,
        stopSequence: oldModelConfig?.stopSequence,
        presencePenalty: oldModelConfig?.presencePenalty,
        frequencyPenalty: oldModelConfig?.frequencyPenalty,
        numRetries: oldModelConfig?.numRetries,
        timeout: oldModelConfig?.timeout,
        responseFormat: oldModelConfig?.responseFormat,
        seed: oldModelConfig?.seed,
        logitBias: formatLogitBias(oldModelConfig?.logitBias),
      },
    }),
    [previousVersion, oldMetadata, oldModelConfig]
  )

  const newVersion = React.useMemo(
    () => ({
      name: version?.name,
      description: version.description,
      model: metadata.modelName,
      type: version.action_type,
      prompt:
        version.action_type === 'chat'
          ? { chat: { templateMessages: tryParseJSON<Array<object>>(version.prompt) } }
          : { template: tryParseJSON<Array<object>>(version.prompt) },
      model_config: {
        topP: modelConfig?.topP,
        temperature: modelConfig?.temperature,
        maxResponseLength: modelConfig?.maxResponseLength,
        stopSequence: modelConfig?.stopSequence,
        presencePenalty: modelConfig?.presencePenalty,
        frequencyPenalty: modelConfig?.frequencyPenalty,
        numRetries: modelConfig?.numRetries,
        timeout: modelConfig?.timeout,
        responseFormat: modelConfig?.responseFormat,
        seed: modelConfig?.seed,
        logitBias: formatLogitBias(modelConfig?.logitBias),
      },
    }),
    [version, metadata, modelConfig]
  )

  let actionVersionJson: { role: string; content: string }[]
  let promptIsJson = false
  try {
    actionVersionJson = JSON.parse(version.prompt) as { role: string; content: string }[]
    promptIsJson = true
  } catch (e) {
    actionVersionJson = []
    promptIsJson = false
  }

  return (
    <div className="p-4">
      <div className="">
        <div className="flex flex-row items-start justify-between">
          {/*
            <div>
              <ActionVersionEnvironmentBadge version={version} /> 
              <GuidCopyButton guid={version.guid} label="Version GUID" alignment="left" />
              <h3 className="mt-2 text-base font-semibold leading-7 text-grey-900">{version.name}</h3>
              {version.description && (
                <p className="text-sm leading-6 text-grey-700">{version.description}</p>
              )}
            </div> 
          */}
          {previousVersion && (
            <div className="flex flex-row items-center space-x-3">
              <Switch
                checked={viewType === 'yaml'}
                onCheckedChange={(checked) => {
                  setViewType(checked ? 'yaml' : 'separate')
                }}
              />
              <p className="text-sm leading-6 text-grey-700">
                Compare changes from previous version
              </p>
            </div>
          )}
          <div className="flex flex-col space-y-2">
            <ActionVersionEnvironmentInlineEditForm
              action={action}
              version={version}
              workspace={workspace}
              onIsOpenChange={onIsOpenChange}
              environments={environments}
            />
          </div>
        </div>
      </div>
      <div className="mt-6 border-t border-grey-100">
        {viewType === 'yaml' ? (
          <div className="text-sm">
            <DiffViewer
              title={{ old: previousVersion?.guid ?? 'old', new: version.guid }}
              data={{ old: oldVersion, new: newVersion }}
              config={{
                stringReplacer: messagesJSONStringReplacer,
              }}
              className="max-h-[800px] h-fit overflow-y-auto border-t-[1px] border-black/10"
            />
          </div>
        ) : (
          <dl className="divide-y divide-grey-100">
            {/* don't show system message as separate model for chat mode */}
            {version.action_type != 'chat' && version.systemMessage && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-grey-900">System Message</dt>
                <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                  <>{version.systemMessage}</>
                </dd>
              </div>
            )}
            {version.prompt && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                {/* <dt className="text-sm font-medium leading-6 text-grey-900">Template</dt> */}
                <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-3 sm:mt-0">
                  {promptIsJson ? (
                    (actionVersionJson as { role: string; content: string }[]).map(
                      (item, index) => (
                        <div key={index} className="mb-4">
                          <h3 className="text-lg font-semibold">
                            {item.role.charAt(0).toUpperCase() + item.role.slice(1)}
                          </h3>
                          {item.content.includes('{{') && item.content.includes('}}') ? (
                            <p className="mt-2">
                              {item.content.split(/(\{\{.*?\}\})/).map((part, index) =>
                                part.match(/\{\{.*?\}\}/) ? (
                                  <span key={index} className="text-primary-600">
                                    {part}
                                  </span>
                                ) : (
                                  <span key={index}>{part}</span>
                                )
                              )}
                            </p>
                          ) : (
                            <pre className="mt-2 whitespace-break-spaces font-inter">
                              {item.content}
                            </pre>
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-bold leading-6 text-grey-900">Prompt</dt>
                      <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                        <>{version.prompt}</>
                      </dd>
                    </div>
                  )}
                </dd>
              </div>
            )}
            {/* {version.action_type && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-grey-900">Type</dt>
                <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                  <>{version.action_type}</>
                </dd>
              </div>
            )} */}

            <>{metadata.modelId && <ActionVersionModel modelId={metadata.modelId} />}</>
            {skills && skills.length > 0 && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-grey-900">Functions</dt>
                <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                  {skills.map((skill) => (
                    <div className="flex items-center gap-2" key={skill.id}>
                      <span>{skill.name}</span>
                    </div>
                  ))}
                </dd>
              </div>
            )}
            {contexts && contexts.length > 0 && (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-grey-900">Contexts</dt>
                <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                  {contexts.map((context) => (
                    <div className="flex items-center gap-2" key={context.id}>
                      <span>{context.name}</span>
                    </div>
                  ))}
                </dd>
              </div>
            )}
            {metadata.outputFormat ||
              (oldMetadata?.outputFormat && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-grey-900">Output Parser</dt>
                  <dd className="mt-1 font-inter text-sm leading-6 text-grey-700 sm:col-span-2 sm:mt-0">
                    <>{metadata.outputFormat}</>
                  </dd>
                </div>
              ))}
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-grey-900">Model Config</dt>
              <dd className="mt-2 text-sm text-grey-900 sm:col-span-2 sm:mt-0">
                <ul role="list" className="divide-y divide-grey-100 flex flex-col space-y-4">
                  <li className="flex items-center justify-between text-sm leading-6">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <div className="truncate font-medium">Temperature</div>
                      <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                        <>{modelConfig?.temperature}</>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center justify-between pt-4 text-sm leading-6">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <div className="truncate font-medium">Max response length</div>
                      <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                        <>{modelConfig?.maxResponseLength}</>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center justify-between pt-4 text-sm leading-6">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <div className="truncate font-medium">Top P</div>
                      <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                        <>{modelConfig?.topP}</>
                      </div>
                    </div>
                  </li>
                  {modelConfig?.frequencyPenalty !== 0 && (
                    <li className="flex items-center justify-between pt-4 text-sm leading-6">
                      <div className="w-full grid grid-cols-3 gap-2">
                        <div className="truncate font-medium">Frequency Penalty</div>
                        <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                          <>{modelConfig?.frequencyPenalty}</>
                        </div>
                      </div>
                    </li>
                  )}
                  {modelConfig?.presencePenalty !== 0 && (
                    <li className="flex items-center justify-between pt-4 text-sm leading-6">
                      <div className="w-full grid grid-cols-3 gap-2">
                        <div className="truncate font-medium">Presence Penalty</div>
                        <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                          <>{modelConfig?.presencePenalty}</>
                        </div>
                      </div>
                    </li>
                  )}
                  {modelConfig?.stopSequence && (
                    <li className="flex items-center justify-between pt-4 text-sm leading-6">
                      <div className="w-full grid grid-cols-3 gap-2">
                        <div className="truncate font-medium">Stop Sequence</div>
                        <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                          <>{modelConfig?.stopSequence}</>
                        </div>
                      </div>
                    </li>
                  )}
                  <li className="flex items-center justify-between pt-4 text-sm leading-6">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <div className="truncate font-medium">Retry</div>
                      <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                        <>{modelConfig?.numRetries}</>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center justify-between pt-4 text-sm leading-6">
                    <div className="w-full grid grid-cols-3 gap-2">
                      <div className="truncate font-medium">Timeout (s)</div>
                      <div className="col-span-2 font-inter text-grey-600 dark:text-zinc-600">
                        <>{modelConfig?.timeout}</>
                      </div>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        )}
      </div>
    </div>
  )
}
