import { useRouter } from 'next/router'
import { api } from '@/utils'
import type { WorkspaceAppIParams } from '../types/queryparams'
import { useUser } from './user'

export function useFetchApps(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.app.getAll.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    apps: data,
    isLoading,
    error,
  }
}

export function useFetchAppsWithActions(workspaceId: number, actionType?: 'chat' | 'prompt') {
  const { user } = useUser()
  const { data, isLoading, error } = api.app.getAllWithActions.useQuery(
    { workspaceId, actionType },
    {
      enabled: user !== undefined,
    }
  )
  return {
    apps: data,
    isLoading,
    error,
  }
}

export function useFetchAppBySlug(slug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.app.getBySlug.useQuery(
    { slug },
    {
      enabled: user !== undefined,
    }
  )
  return {
    app: data,
    isLoading,
    error,
  }
}

export function useFetchAppsMetaCache(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.app.getAppsCache.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    appMetaCache: data,
    isLoading,
    error,
  }
}

export function useCurrentApp() {
  const { user } = useUser()
  const router = useRouter()
  const { slug } = router.query as WorkspaceAppIParams
  const { data, isLoading, error } = api.app.getBySlug.useQuery(
    { slug },
    {
      enabled: user !== undefined && slug !== undefined,
    }
  )
  return {
    slug,
    app: data,
    isLoading,
    error,
  }
}

export function useFetchRecentAppErrors(appSlug: string, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.app.getRecentAppErrors.useQuery(
    { workspaceId, appSlug },
    {
      enabled: user !== undefined,
    }
  )
  return {
    errors: data,
    isLoading,
    error,
  }
}
