import type { ActionSession, Data } from '@prisma/client'
import { format } from 'date-fns'
import React from 'react'
import { cn } from '@/client/utils'
import { Loader } from '@/common/components'
import { CompletionRenderer } from '@/common/components/tables/datatable-completion-render'
import { InputRenderer } from '@/common/components/tables/datatable-input-renderer'
import { PendingCompletionRenderer } from '@/common/components/tables/datatable-pending-completion'
import { useFetchDataForSession } from '@/common/hooks'
import { StreamRenderer } from '@/common/stream/stream/StreamRenderer'
import { useStreamStore } from '@/common/stream/stream/streamStore'
import type { FullPromptSent } from '@/server/engine/studio'
import { sessionService } from '@/server/service/session'

function HandleInputRenderer({ data }: { data: Data }) {
  const loadLastContent = (messages: FullPromptSent[], fallback: string) => {
    const content = messages[messages.length - 1]?.content
    if (!content) return <InputRenderer input={fallback} />
    if (typeof content === 'string') {
      return <InputRenderer input={content} />
    }
    if (Array.isArray(content)) {
      return content.map((item, id) => {
        if (item.image_url?.url) {
          return (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              key={`image-${data.guid}-${id}-${item.image_url?.url}`}
              src={`/api/files/download?fileUrl=${item.image_url?.url}`}
              className={cn('w-20 h-20 rounded-md object-cover')}
              alt={'Preview'}
            />
          )
        }
        return <InputRenderer key={`text-${data.id}-${id}`} input={item.text ?? ''} />
      })
    }
    return <InputRenderer input={fallback} />
  }

  if (data.input === '{}' || data.input === '') {
    try {
      const messages = sessionService.parser.parse(data.full_prompt_sent)
      return loadLastContent(messages, data.input)
    } catch (e) {
      return <InputRenderer input={data.input} />
    }
  }

  try {
    const messages = sessionService.parser.parse(data.input)
    return loadLastContent(messages, data.input)
  } catch (e) {
    return <InputRenderer input={data.input} />
  }
}

export function ActionConversationDetail({
  session,
}: {
  session: ActionSession & { Data: Omit<Data, 'raw_llm_request' | 'raw_llm_response'>[] }
}) {
  const { sessionData, isLoading } = useFetchDataForSession(session.id)

  const { isStreamAvailable } = useStreamStore()

  if (isLoading) {
    return (
      <div className="px-6 py-6">
        <div className="flex items-center justify-center">
          <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-full overflow-x-auto">
      <div className="inline-block align-middle">
        <div className="overflow-hidden ring-1 ring-black ring-opacity-5">
          <table className="w-full min-w-full table-fixed divide-y divide-grey-300 dark:divide-zinc-800">
            <tbody className="divide-y divide-grey-200 dark:divide-zinc-800 ">
              {sessionData?.map((data) => (
                <>
                  <tr key={`${data.id}-input`}>
                    <td className="w-full px-6 py-4 text-sm font-medium">
                      <div className="mb-3 text-xs uppercase text-grey-300">
                        Message on {format(new Date(data.createdAt), 'MMMM dd, HH:mm')}
                      </div>
                      <div className="line-clamp mb-3 whitespace-pre-wrap">
                        <span className="border-b border-grey-100 font-mono text-xs uppercase text-grey-500">
                          User
                        </span>
                        <span className="mt-2 block">
                          <HandleInputRenderer data={data} />
                        </span>
                      </div>
                      <div key={`${data.id}-output`}>
                        <div className="whitespace-wrap">
                          <span className="border-b border-grey-100 font-mono text-xs uppercase text-grey-500">
                            ai
                          </span>
                          <span className="mt-2 block">
                            {isStreamAvailable(data.guid) ? (
                              <StreamRenderer dataGuid={data.guid} />
                            ) : (
                              <>
                                {data.output === '' ? (
                                  <PendingCompletionRenderer dataId={data.id} />
                                ) : (
                                  <CompletionRenderer
                                    completion={data.output}
                                    numOutputTokens={
                                      data.num_output_tokens ? data.num_output_tokens : undefined
                                    }
                                    metadata={data.metadata as Record<string, unknown>}
                                  />
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
