import { SearchMd } from '@untitled-ui/icons-react'
import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { Icon } from '@/client/components'
import { cn } from '@/client/utils'
import type { CommandInputProps } from './types'

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  CommandInputProps
>(({ className, ...props }, ref) => (
  <div
    className={`
      flex
      items-center
      rounded-md 
      border-none
      border-grey-100
      px-3
      py-2
      focus-within:border
      focus-within:border-grey-300
      dark:border-zinc-800
      dark:focus-within:border-white
    `}
    cmdk-input-wrapper=""
  >
    <Icon className="mr-2 shrink-0 opacity-50" size="md" component={SearchMd} />

    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        `
          body1
          flex
          w-full
          border-0
          bg-transparent
          p-0
          outline-none
          placeholder:text-sm
          placeholder:text-grey-500
          focus:!border-0
          focus:!outline-none
          focus:!ring-0
          disabled:opacity-50
          dark:placeholder:text-zinc-600 
          dark:text-zinc-200
        `,
        className
      )}
      {...props}
    />
  </div>
))

export const CommandInputV2 = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    classNames?: {
      parent?: string
      input?: string
    }
  }
>(({ classNames, ...props }, ref) => (
  <div
    className={cn(
      `flex items-center rounded-md border-grey-200 dark:border-zinc-800 px-3 py-2 transition ease-in-out duration-200 focus-within:border-[1px] focus-within:border-grey-300  dark:focus-within:border-white
    `,
      classNames?.parent
    )}
    cmdk-input-wrapper=""
  >
    <Icon className="mr-2 shrink-0 opacity-50" size="md" component={SearchMd} />

    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        `
          body1
          flex
          w-full
          border-0
          bg-transparent
          p-0
          outline-none
          placeholder:text-sm
          placeholder:text-grey-500
          focus:!border-0
          focus:!outline-none
          focus:!ring-0
          disabled:opacity-50
          dark:placeholder:text-zinc-600 
          dark:text-zinc-400
        `,
        classNames?.input
      )}
      {...props}
    />
  </div>
))

CommandInputV2.displayName = CommandPrimitive.Input.displayName

CommandInput.displayName = CommandPrimitive.Input.displayName

export { CommandInput }
