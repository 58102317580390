import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { Environment, Workspace } from '@prisma/client';
import { PlusCircle, XClose } from '@untitled-ui/icons-react';
import { type NextPage } from 'next';
import { useState } from 'react';
import { ConnectionIcon } from '@/client/assets/icons/icons';
import { Button, Input, Popover, Separator, Skeleton, Switch, toast } from '@/client/components';
import { ButtonProps } from '@/client/components/Button/types';
import { CreateEnvironment } from '@/client/containers/views/Workspace/CreateEnvironment';
import { DeleteEnvironment } from '@/client/containers/views/Workspace/DeleteEnvironment';
import { DeleteFeedbackBehaviour } from '@/client/containers/views/Workspace/DeleteFeedbackBehaviour';
import { WorkspaceEditForm } from '@/client/containers/views/Workspace/Edit';
import { EditEnvironment } from '@/client/containers/views/Workspace/EditEnvironment';
import { WorkspaceLogoForm } from '@/client/containers/views/Workspace/EditLogo';
import { ProfileEditForm } from '@/client/containers/views/Workspace/Profile';
import { WorkspaceSettingsWrapper } from '@/common/components';
import { DeleteWorkspaceModal } from '@/common/components/modals/workspaces/DeleteWorkspaceModal';
import { LeaveWorkspaceModal } from '@/common/components/modals/workspaces/LeaveWorkspaceModal';
import { TransferWorkspaceModal } from '@/common/components/modals/workspaces/TransferWorkspaceModal';
import { WorkspaceLogo } from '@/common/components/workspace/workspace-logo';
import { useCurrentWorkspace, useFetchEnvironments, useFetchWorkspaceAPIUrl, useFetchWorkspaceConfig, useUser } from '@/common/hooks';
import { withWorkspace } from '@/common/lib';
import { api } from '@/utils';
function WorkspaceOutputParsing({
  workspace
}: {
  workspace: Workspace;
}) {
  const {
    workspaceConfig
  } = useFetchWorkspaceConfig(workspace?.id);
  const utils = api.useContext();
  const {
    mutateAsync
  } = api.workspaceMeta.updateWorkspaceConfig.useMutation({
    onMutate: async newValue => {
      await utils.workspaceMeta.getWorkspaceConfig.cancel({
        workspaceId: workspace.id
      });
      const previousConfig = utils.workspaceMeta.getWorkspaceConfig.getData({
        workspaceId: workspace.id
      });
      utils.workspaceMeta.getWorkspaceConfig.setData({
        workspaceId: workspace.id
      }, () => ({
        outputParsing: newValue.config.outputParsing
      }));
      return {
        previousConfig
      };
    },
    onSuccess: ({
      outputParsing
    }) => {
      toast.success({
        title: "Workspace's output parsing updated",
        description: `Output parsing is turned ${outputParsing ? 'on' : 'off'}`
      });
    },
    onError: (err, newTodo, context) => {
      utils.workspaceMeta.getWorkspaceConfig.setData({
        workspaceId: workspace.id
      }, context?.previousConfig);
      toast.error({
        title: 'Something went wrong',
        description: err.message
      });
    },
    onSettled: () => {
      return utils.workspaceMeta.getWorkspaceConfig.invalidate({
        workspaceId: workspace.id
      });
    }
  });
  return <div className={'mt-8 rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black flex flex-row items-center justify-between'}>
      <div className={'flex flex-col'}>
        <h1 className="text-lg font-medium leading-6 text-grey-800 dark:text-white">
          Output parsing
        </h1>
        <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
          Activate output parsing and show options in Studio
        </p>
      </div>
      <div>
        <Switch checked={Boolean(workspaceConfig?.outputParsing)} onCheckedChange={value => {
        void mutateAsync({
          workspaceId: workspace.id,
          config: {
            outputParsing: value
          }
        });
      }} />
      </div>
    </div>;
}
function WorkspaceDangerZone({
  workspace
}: {
  workspace: Workspace;
}) {
  const [open, setOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const {
    user
  } = useUser();
  function showLeaveWorkspace() {
    return user?.id !== workspace?.createdById;
  }
  function showOwnerActions() {
    if (user?.isStaff) return true;
    return user?.id === workspace?.createdById;
  }
  return <>
      <LeaveWorkspaceModal open={leaveOpen} setOpen={setLeaveOpen} workspace={workspace} />
      <DeleteWorkspaceModal open={open} setOpen={setOpen} workspace={workspace} />
      <TransferWorkspaceModal open={transferOpen} setOpen={setTransferOpen} workspace={workspace} />
      <div className="mt-3 pt-3">
        <div className="space-y-6 rounded-md border border-grey-200 dark:border-zinc-800 bg-white dark:bg-black px-4 pb-4 pt-5 shadow-sm sm:space-y-5 sm:p-6 sm:pb-6">
          <div>
            <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
            <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
              Destructive workspace options
            </p>
          </div>

          <div className="flex flex-col space-y-3 divide-y divide-grey-200 dark:divide-zinc-800">
            {showLeaveWorkspace() && <div className="flex items-center justify-start">
                <Button onClick={() => setLeaveOpen(!leaveOpen)}>Leave {workspace.name}</Button>
                <p className="ml-3 rounded-lg ring-1 ring-grey-100 bg-grey-50 dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-400 p-2 px-3 font-mono text-xs">
                  This will remove you from this workspace
                </p>
              </div>}

            {showOwnerActions() && <>
                <div className="flex items-center justify-start pt-3">
                  <Button startIcon={ConnectionIcon} variant="outline" onClick={() => setTransferOpen(!transferOpen)}>
                    Transfer {workspace.name}
                  </Button>
                  <p className="ml-3 rounded-lg bg-grey-50 ring-1 ring-grey-100 dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-400 p-2 px-3 font-mono text-xs">
                    Transfer this workspace to another user
                  </p>
                </div>
                <div className="flex items-center justify-start pt-3">
                  <Button startIcon={XClose} variant="outline" onClick={() => setOpen(!open)}>
                    Delete {workspace.name}
                  </Button>
                  <p className="ml-3 rounded-lg bg-grey-50 ring-1 ring-grey-100 dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-400 p-2 px-3 font-mono text-xs">
                    Warning: This cannot be undone, proceed with caution
                  </p>
                </div>
              </>}
          </div>
        </div>
      </div>
    </>;
}
function EnvironmentsTable({
  workspace,
  environments
}: {
  workspace: Workspace;
  environments: Environment[];
}) {
  return <div className="flow-root">
      <div className="">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-clip rounded-lg shadow-sm ring-1 ring-black ring-opacity-5 dark:ring-zinc-800">
            <table className="min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900">
                <tr>
                  <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-mono text-xs font-light uppercase text-grey-900 dark:text-white">
                    Name
                  </th>

                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                {environments.map(environment => <tr key={environment.id} className="group transition-all duration-0 ease-in-out bg-white hover:bg-grey-50 dark:bg-black dark:hover:bg-zinc-950">
                    <td className="whitespace-nowrap py-4 pl-6 text-sm">{environment.name}</td>

                    <td className="relative whitespace-nowrap py-4 pr-10 text-right text-sm font-medium sm:pr-0">
                      <div className="flex flex-row-reverse space-x-reverse space-x-3 px-6">
                        {environment.name !== 'Production' && environment.name !== 'Staging' && <>
                            <EditEnvironment environment={environment} workspace={workspace} />
                            <DeleteEnvironment environment={environment} workspace={workspace} />
                          </>}
                      </div>
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}
function WorkspaceEnvironments({
  workspace
}: {
  workspace: Workspace;
}) {
  const {
    environments,
    isLoading: isLoadingEnvironments
  } = useFetchEnvironments(workspace.id);
  return <div className="mt-8">
      <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-grey-800 dark:text-white">
              Klu Environments
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
              Manage workspace deployment environments
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <CreateEnvironment workspace={workspace} />
          </div>
        </div>
        <div className="mt-5 flex flex-col space-y-6">
          <Separator />
          {isLoadingEnvironments && <Skeleton className="h-40" />}
          {environments && <EnvironmentsTable workspace={workspace} environments={environments} />}
        </div>
      </div>
    </div>;
}
function WorkspaceAPIUrl({
  workspace
}: {
  workspace: Workspace;
}) {
  const {
    apiUrl,
    isLoading: isLoadingAPIUrl
  } = useFetchWorkspaceAPIUrl(workspace.id);
  return <div className="mt-8">
      <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
        <div className="flex flex-col space-y-6 max-w-lg">
          <h1 className="text-lg font-medium leading-6 text-grey-800 dark:text-white">
            Klu API URL
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
            Only change this if you have deployed the Klu API in your own cloud. To depoy Klu into
            your own cloud, please contact us.
          </p>
        </div>
        {!isLoadingAPIUrl && <Input label={'API URL'} id="api-url" value={apiUrl || ''} disabled />}
      </div>
    </div>;
}
export function CustomFeedbackBehaviourPopover({
  hideLabel,
  buttonVariant
}: {
  hideLabel?: boolean;
  buttonVariant?: ButtonProps['variant'];
}) {
  const [open, isOpenChange] = useState(false);
  const createBehaviourFeedbackValueType = api.feedback.createBehaviourFeedbackValueType.useMutation();
  const utils = api.useUtils();
  const [loading, setLoading] = useState(false);
  const [feedbackType, setFeedbackType] = useState<string>('');
  async function onSubmit() {
    await createBehaviourFeedbackValueType.mutateAsync({
      behaviourType: feedbackType
    }, {
      onSuccess: () => {
        toast.success({
          title: 'User behavior signal created',
          description: 'Successfully created custom behavior signal'
        });
      },
      onError: e => {
        toast.error({
          title: 'An error occured',
          description: e.message ?? `An error occurred while adding your custom behavior`
        });
      },
      onSettled: () => {
        setLoading(false);
      }
    });
    await utils.feedback.getBehaviourFeedbackValues.invalidate();
    isOpenChange(false);
  }
  return <Popover.Root open={open} onOpenChange={isOpenChange}>
      <Popover.Trigger asChild>
        <Button variant={buttonVariant || 'outline'} startIcon={PlusCircle}>
          {hideLabel ? '' : 'New Signal'}
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-80">
        <div className="grid gap-4">
          <div className="flex flex-col space-y-4">
            <Input label={'Signal name'} id="width" placeholder={'Enter name (eg. Converted)'} value={feedbackType} onChange={e => {
            setFeedbackType(e.target.value);
            e.stopPropagation();
          }} />
            <Button onClick={() => void onSubmit()} disabled={loading} loading={loading}>
              Add
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>;
}
function FeedbackBehaviourTable({
  workspace,
  behaviourValues
}: {
  workspace: Workspace;
  behaviourValues: string[];
}) {
  return <div className="flow-root">
      <div className="">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-clip rounded-lg shadow-sm ring-1 ring-black ring-opacity-5 dark:ring-zinc-800">
            <table className="min-w-full divide-y divide-grey-300 dark:divide-zinc-800">
              <thead className="bg-grey-50 dark:bg-zinc-900">
                <tr>
                  <th scope="col" className="py-3.5 pl-6 pr-3 text-left font-mono text-xs font-light uppercase text-grey-900 dark:text-white">
                    Name
                  </th>

                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grey-200 dark:divide-zinc-800">
                {behaviourValues.map(behaviourValue => <tr key={behaviourValue} className="group transition-all duration-0 ease-in-out bg-white hover:bg-grey-50 dark:bg-black dark:hover:bg-zinc-950">
                    <td className="whitespace-nowrap py-4 pl-6 text-sm">{behaviourValue}</td>

                    <td className="relative whitespace-nowrap py-4 pr-10 text-right text-sm font-medium sm:pr-0">
                      <div className="flex flex-row-reverse space-x-reverse space-x-3 px-6">
                        <DeleteFeedbackBehaviour feedbackBehaviour={behaviourValue} workspace={workspace} />
                      </div>
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}
function WorkspaceBehaviourLabels({
  workspace
}: {
  workspace: Workspace;
}) {
  const {
    data: behaviourValues,
    isLoading
  } = api.feedback.getBehaviourFeedbackValues.useQuery();
  return <div className="mt-8">
      <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-grey-800 dark:text-white">
              Custom User Behaviour Types
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">
              Add custom behavior signals used to label logs
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <CustomFeedbackBehaviourPopover />
          </div>
        </div>
        {behaviourValues && behaviourValues.length > 0 && <div className="mt-5 flex flex-col space-y-6">
            <Separator />
            {isLoading && <Skeleton className="h-40" />}
            <FeedbackBehaviourTable workspace={workspace} behaviourValues={behaviourValues} />
          </div>}
      </div>
    </div>;
}
const SettingsPage: NextPage = () => {
  const {
    user
  } = useUser();
  const {
    workspace
  } = useCurrentWorkspace();
  if (!user || !workspace) {
    return null;
  }
  return <WorkspaceSettingsWrapper>
      {workspace && <div className="flex flex-col">
          <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
            <div>
              <h3 className="text-lg font-medium leading-6">Workspace</h3>
            </div>
            <div className="mt-5">
              <WorkspaceEditForm workspace={workspace} />
            </div>
          </div>
          <div className="mt-8">
            <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
              <div>
                <h3 className="text-lg font-medium leading-6">Workspace Logo</h3>
              </div>
              <div className="mt-5 flex items-center">
                {workspace.logoUrl && <WorkspaceLogo workspace={workspace} className="w-12 h-12 mr-4" />}
                <WorkspaceLogoForm workspace={workspace} />
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-6 dark:bg-black">
              <div>
                <h3 className="text-lg font-medium leading-6">Profile</h3>
              </div>
              <div className="mt-5">
                {user && <ProfileEditForm user={user} workspace={workspace} />}
              </div>
            </div>
          </div>
          <WorkspaceAPIUrl workspace={workspace} />
          <WorkspaceEnvironments workspace={workspace} />
          <WorkspaceBehaviourLabels workspace={workspace} />
          <WorkspaceOutputParsing workspace={workspace} />
          <WorkspaceDangerZone workspace={workspace} />
        </div>}
    </WorkspaceSettingsWrapper>;
};
export default _withSuperJSONPage(SettingsPage);
export const getServerSideProps = _withSuperJSONProps(withWorkspace(), []);