import * as Portal from '@radix-ui/react-portal'
import * as React from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import getCaretCoordinates from 'textarea-caret'
import { FieldMessage } from '@/client/components'
import { HighlightVariables } from '@/client/components/Textarea/components/HighlightVariables'
import {
  addEndingVariableBrackets,
  getDiffChars,
  isCursorWithinDoubleBrackets,
  wroteDoubleBrackets,
} from '@/client/components/Textarea/logic'
import { textAreaVariants } from '@/client/components/Textarea/variants'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import { cn } from '@/client/utils'
import { useForwardRef } from '@/client/utils/forwardRef/useForwardedRef'
import useTokenizer from '@/common/hooks/tokenizer'
import styles from './styles.module.css'
import type {
  TextareaProps,
  TextareaWithAdaptiveHeightProps,
  VariableSuggestionAction,
  VariableSuggestionState,
} from './types'

const handlebarsTags = [
  "#assignLocal 'variable'",
  "#each (split variable ',') as |item|",
  '#fetch',
  '#generate',
  '#if variable',
  '#repeat 2',
]

const TextareaWithAdaptiveHeightBase = React.forwardRef<
  HTMLTextAreaElement,
  TextareaWithAdaptiveHeightProps
>((props, ref) => {
  const { onChange, onValueChange, ...rest } = props
  return (
    <textarea
      {...rest}
      ref={ref}
      onChange={(e) => {
        onChange?.(e)
        onValueChange?.(e.target.value)
        e.target.style.height = 'auto'
        e.target.style.height = `${e.target.scrollHeight}px`
      }}
    />
  )
})

TextareaWithAdaptiveHeightBase.displayName = 'TextareaWithAdaptiveHeight'

const TextareaBase = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      textAreaClassName,
      label,
      hideLabel,
      message,
      autoExpand,
      displayTokens,
      hasError,
      variant = 'primary',
      suggestedVariables,
      useVariables,
      onChangeValue,
      isSubmitting,
      ...props
    },
    ref
  ) => {
    const { id, disabled } = props

    const forwardedMutableRef = useForwardRef<HTMLTextAreaElement>(ref)
    const newRef = useRef<HTMLTextAreaElement | null>(null)
    const targetRef = ref ? forwardedMutableRef : newRef

    const scrollToIndex = useCallback((index: number) => {
      document.getElementById('suggested-variable-' + String(index))?.scrollIntoView({
        block: 'nearest',
      })
    }, [])

    const [textInBrackets, setComputedTextInBrackets] = useState('')
    const invalidateTextInBrackets = () => {
      if (targetRef.current) {
        const value = props.value?.toString() ?? ''
        const cursorIndex = targetRef.current.selectionStart
        const startingBracketsIndex = value.lastIndexOf('{{', cursorIndex)
        if (startingBracketsIndex !== -1) {
          const result = value.slice(startingBracketsIndex + 2, cursorIndex)
          setComputedTextInBrackets(result)
        }
      }
    }

    const fillWithVariable = useCallback(
      (variableToFill: string) => {
        if (!targetRef.current) return
        const cursorPosition = targetRef.current.selectionStart
        const startingBracketsIndex = targetRef.current.value.lastIndexOf('{{', cursorPosition)
        const endingBracketsIndex = targetRef.current.value.indexOf('}}', cursorPosition)
        if (
          startingBracketsIndex === undefined ||
          startingBracketsIndex === -1 ||
          endingBracketsIndex === undefined ||
          endingBracketsIndex === -1
        )
          return

        const newValue =
          targetRef.current.value.slice(0, startingBracketsIndex + 2) +
          variableToFill +
          targetRef.current.value.slice(endingBracketsIndex)

        onChangeValue?.(newValue)

        //   set cursor behind brackets (4 is 2+2 for starting and ending brackets)
        setTimeout(() => {
          if (!targetRef.current) return
          targetRef.current.selectionStart = startingBracketsIndex + 4 + variableToFill.length
          targetRef.current.selectionEnd = startingBracketsIndex + 4 + variableToFill.length
        }, 10)
      },
      [onChangeValue, targetRef]
    )

    const fillWithBlock = useCallback(
      (blockToFill: string) => {
        const blockTag = blockToFill.split(' ')[0]!

        if (!targetRef.current) return
        const cursorPosition = targetRef.current.selectionStart
        const startingBracketsIndex = targetRef.current.value.lastIndexOf('{{#', cursorPosition)
        const endingBracketsIndex = targetRef.current.value.indexOf('}}', cursorPosition)
        if (
          startingBracketsIndex === undefined ||
          startingBracketsIndex === -1 ||
          endingBracketsIndex === undefined ||
          endingBracketsIndex === -1
        )
          return

        const closingTag = `{{${blockTag.replace('#', '/')}}}`
        const fullBlock = '{{' + blockToFill + '}}\n' + closingTag

        // console.log('startingBracketsIndex', startingBracketsIndex)
        // console.log('endingBracketsIndex', endingBracketsIndex)
        // console.log('fullBlock.length', fullBlock.length)
        // console.log('targetRef.current.value', targetRef.current.value)

        const newValue =
          targetRef.current.value.slice(0, startingBracketsIndex) +
          fullBlock +
          targetRef.current.value.slice(endingBracketsIndex + 2)

        onChangeValue?.(newValue)

        //   set cursor behind brackets (4 is 2+2 for starting and ending brackets)
        setTimeout(() => {
          if (!targetRef.current) return
          targetRef.current.selectionStart = startingBracketsIndex + 4 + blockToFill.length
          targetRef.current.selectionEnd = startingBracketsIndex + 4 + blockToFill.length
        }, 10)
      },
      [onChangeValue, targetRef]
    )

    const suggestedVariablesFiltered = useMemo(() => {
      const variablesAndTags = [...(suggestedVariables || []), ...handlebarsTags]

      if (variablesAndTags.length < 0) return []

      return variablesAndTags.filter((variable) => {
        if (typeof variable == 'string' && variable.startsWith('#')) {
          return textInBrackets.startsWith('#') && variable.startsWith(textInBrackets)
        } else {
          return (
            (typeof variable == 'object' && variable.value.startsWith(textInBrackets)) ||
            (typeof variable == 'string' && variable.startsWith(textInBrackets))
          )
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textInBrackets, suggestedVariables])

    const [suggestionState, dispatchSuggestionAction] = React.useReducer(
      (state: VariableSuggestionState, action: VariableSuggestionAction) => {
        switch (action.type) {
          case 'open':
            if (!targetRef.current) return state
            const caretPosition = getCaretCoordinates(
              targetRef.current,
              targetRef.current.selectionStart
            )
            return {
              isOpen: true,
              position: caretPosition,
              highlightedIndex: 0,
            }
          case 'close':
            return {
              isOpen: false,
              position: {
                top: 0,
                left: 0,
              },
              highlightedIndex: 0,
            }
          case 'item-confirmed':
            const variable = suggestedVariablesFiltered?.[action.index]
            if (!variable) return state

            const variableValue = typeof variable == 'object' ? variable.value : variable

            if (variableValue.includes('#')) {
              fillWithBlock(variableValue)
            } else {
              fillWithVariable(variableValue)
            }

            return {
              isOpen: false,
              position: {
                top: 0,
                left: 0,
              },
              highlightedIndex: 0,
            }
          case 'item-hovered':
            return {
              ...state,
              highlightedIndex: action.index,
            }
          case 'key-pressed':
            if (action.key === 'ArrowDown') {
              let nextIndex = state.highlightedIndex + 1
              if (suggestedVariablesFiltered && nextIndex >= suggestedVariablesFiltered?.length) {
                nextIndex = 0
              }
              scrollToIndex(nextIndex)
              return {
                ...state,
                highlightedIndex: nextIndex,
              }
            }
            if (action.key === 'ArrowUp') {
              let nextIndex = state.highlightedIndex - 1
              if (suggestedVariablesFiltered && nextIndex < 0) {
                nextIndex = suggestedVariablesFiltered.length - 1
              }
              scrollToIndex(nextIndex)
              return {
                ...state,
                highlightedIndex: nextIndex,
              }
            }
            return state

          default:
            return state
        }
      },
      {
        isOpen: false,
        position: {
          top: 0,
          left: 0,
        },
        highlightedIndex: 0,
      }
    )

    // if props.value is ending with newline, add whitespace, this ensures textarea height is correct
    const valueWithSpaceIfNeeded = useMemo(
      () => (props.value?.toString().endsWith('\n') ? props.value?.toString() + ' ' : props.value),
      [props.value]
    )

    const { decodedTokens, encodedTokens, getTokenColor } = useTokenizer(
      props.value?.toLocaleString() ?? ''
    )

    const displayShadowDiv = Boolean(autoExpand || useVariables || displayTokens)

    return (
      <div
        className={cn('relative', className, { submitting: isSubmitting })}
        onBlur={(_e) => {
          //   setTimeout is needed because otherwise the click on the suggestion item is not registered
          setTimeout(() => {
            if (suggestionState.isOpen) {
              dispatchSuggestionAction({ type: 'close' })
            }
          }, 100)
        }}
      >
        <div className={'flex flex-row justify-between items-baseline'}>
          <label
            className={cn(
              'body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500',
              { 'text-grey-400 dark:text-zinc-600': disabled },
              { 'sr-only': hideLabel }
            )}
            htmlFor={id}
          >
            {label}
          </label>
          {displayTokens && (
            <div
              className={
                'border border-primary-200 rounded-md text-xs px-1 bg-primary-50 justify-end mb-3'
              }
            >
              {encodedTokens.length} token{encodedTokens.length > 1 ? 's' : ''}
            </div>
          )}
        </div>

        <div className={cn('grid relative w-full', { 'z-10': suggestionState.isOpen })}>
          {/* actual textarea for writing, but is invisible */}
          <textarea
            spellCheck={displayTokens ? 'false' : 'true'}
            className={cn(
              textAreaVariants({ variant }),
              hasError
                ? 'border-error focus-within:border-error'
                : 'border-grey-300 focus-within:border-primary-700 dark:border-zinc-800 dark:focus-within:border-white',
              { 'cursor-not-allowed': disabled },
              { 'overflow-hidden': autoExpand },
              textAreaClassName,
              { 'text-transparent caret-black dark:caret-zinc-400': displayShadowDiv },
              { 'z-10': displayTokens }
            )}
            style={{
              gridArea: '1 / 1 / 2 / 2',
            }}
            ref={targetRef}
            {...props}
            onKeyDown={(e) => {
              if (useVariables) {
                if (suggestionState.isOpen && suggestedVariablesFiltered.length > 0) {
                  if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    dispatchSuggestionAction({
                      type: 'key-pressed',
                      key: e.key,
                    })
                    e.preventDefault()
                    e.stopPropagation()
                  } else if (e.key === 'Enter' || e.key === 'Tab') {
                    if (isCursorWithinDoubleBrackets(targetRef.current)) {
                      dispatchSuggestionAction({
                        type: 'item-confirmed',
                        index: suggestionState.highlightedIndex,
                      })
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  } else if (e.key === 'Escape') {
                    dispatchSuggestionAction({
                      type: 'close',
                    })
                  }
                } else if (e.ctrlKey && e.key === ' ' && targetRef.current) {
                  if (isCursorWithinDoubleBrackets(targetRef.current)) {
                    dispatchSuggestionAction({
                      type: 'open',
                    })
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }
              }
              props.onKeyDown?.(e)
            }}
            // when user moves cursor
            onSelect={(_e) => {
              invalidateTextInBrackets()
              if (!isCursorWithinDoubleBrackets(targetRef.current)) {
                dispatchSuggestionAction({ type: 'close' })
              }
            }}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (useVariables && e.nativeEvent.inputType === 'insertText') {
                const differences = getDiffChars(props.value?.toString() ?? '', e.target.value)
                const cursorPosition = Number(differences[0]!.count!) + 1

                if (wroteDoubleBrackets(differences) && targetRef.current) {
                  addEndingVariableBrackets({
                    e,
                    cursorPosition,
                    ref: targetRef.current,
                  })
                  dispatchSuggestionAction({
                    type: 'open',
                  })
                }
              }
              props.onChange?.(e)
            }}
          />

          {/* div for displaying text */}
          {displayShadowDiv && (
            <div
              className={cn(
                textAreaVariants({ variant }),
                hasError
                  ? 'border-error focus-within:border-error'
                  : 'border-grey-300 focus-within:border-primary-700 dark:border-zinc-800 dark:focus-within:border-white',
                { 'cursor-not-allowed': disabled },
                { 'overflow-hidden': autoExpand },
                'pointer-events-none whitespace-pre-wrap border-transparent',
                textAreaClassName
              )}
              style={{
                gridArea: '1 / 1 / 2 / 2',
              }}
            >
              {displayTokens ? (
                decodedTokens.map(([str, num], index) => {
                  return (
                    <span
                      key={index}
                      style={{
                        backgroundColor: getTokenColor(num as number),
                        height: 1.2,
                        borderRadius: '0.2em',
                      }}
                    >
                      {str}
                    </span>
                  )
                })
              ) : (
                <HighlightVariables value={valueWithSpaceIfNeeded?.toString() ?? ''} />
              )}
            </div>
          )}

          {/* popover for autocomplete variables */}
          {useVariables && suggestionState.isOpen && suggestedVariablesFiltered.length > 0 && (
            // margin has to match padding with div for displaying the actual text
            <Portal.Root
              style={{
                top:
                  targetRef.current!.getBoundingClientRect().y + suggestionState.position.top + 17,
                left: targetRef.current!.getBoundingClientRect().x + suggestionState.position.left,
              }}
              className={cn(
                'absolute max-h-[282px] max-w-[350px] min-w-[170px] whitespace-nowrap bg-grey-50 overflow-y-scroll font-mono border border-grey-300 rounded-md shadow-md',
                styles.AutocompletePopoverContent
              )}
            >
              {suggestedVariablesFiltered.map((variable, index) => {
                return (
                  <div
                    id={'suggested-variable-' + String(index)}
                    key={typeof variable == 'object' ? variable.value : variable}
                    className={cn('cursor-pointer text-sm px-2 py-1', {
                      'bg-grey-200': index === suggestionState.highlightedIndex,
                    })}
                    onClick={() => {
                      dispatchSuggestionAction({ type: 'item-confirmed', index })
                    }}
                    onMouseOver={() => {
                      dispatchSuggestionAction({ type: 'item-hovered', index })
                    }}
                  >
                    {typeof variable == 'object' ? variable.label : variable}
                  </div>
                )
              })}
            </Portal.Root>
          )}
        </div>

        {message ? (
          <FieldMessage message={message} disabled={disabled} hasError={hasError} />
        ) : null}
      </div>
    )
  }
)

TextareaBase.displayName = 'Textarea'

const TextareaWithStudioContextBase = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      values: { modelName },
      isSubmitting,
    } = useStudioFormikContext()

    return <Textarea {...props} isSubmitting={isSubmitting} ref={ref} modelName={modelName} />
  }
)

TextareaWithStudioContextBase.displayName = 'TextareaWithStudioContext'

const Textarea = React.memo(TextareaBase)
const TextareaWithStudioContext = React.memo(TextareaWithStudioContextBase)
const TextareaWithAdaptiveHeight = React.memo(TextareaWithAdaptiveHeightBase)

export { Textarea, TextareaWithStudioContext, TextareaWithAdaptiveHeight }
