import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
/* eslint-disable @next/next/no-img-element */
import { Card } from '@tremor/react';
import { ChevronRight, MessageSquare02, XClose } from '@untitled-ui/icons-react';
import { useThreads } from 'liveblocks.config';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { AppEval, DocsIcon, EvalRunIcon } from '@/client/assets/icons/icons';
import { Button, toast, Tooltip } from '@/client/components';
import { Loader, OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { DataImport } from '@/common/components/datasets/DataImport';
import { DeleteEvalRunModal, EvalRunDrawer } from '@/common/components/evalRuns/EvalRunDrawer';
import { EvalRunGrid, EvalRunStatus } from '@/common/components/evalRuns/evalRunsGrid/EvalRunsGrid';
import { EvalOverview } from '@/common/components/evals/evalOverview';
import { EvalPassFailChart } from '@/common/components/evals/EvalPassFailChart';
import { EvalPassRateOverview } from '@/common/components/evals/EvalPassRateOverview';
import { EvalScoreChart } from '@/common/components/evals/EvalScoreChart';
import { App404 } from '@/common/components/notFound/404';
import { useWorkspace } from '@/common/components/ui/context';
import { useFetchActionVersions } from '@/common/hooks';
import { useFetchEvalRunsBySlug } from '@/common/hooks/evalRuns';
import { useFetchEvalBySlug } from '@/common/hooks/evals';
import { withApp } from '@/common/lib';
import { Room } from '@/common/Room';
import type { DetailedEval, DetailedEvalRun, EvalRunMetadata } from '@/common/types/eval';
import type { WorkspaceAppEvalIParams, WorkspaceAppEvalRunIParams } from '@/common/types/queryparams';
import type { ActionVersionMetadata, DatasetMetadata } from '@/server/service/types';
import { api, classNames, dateAgo } from '@/utils';
import { DatasetImportStatus } from '../../../datasets/[datasetGuid]';
function EvalPage() {
  const router = useRouter();
  const workspace = useWorkspace();
  const {
    evalSlug,
    evalRunId,
    slug,
    workspaceSlug
  } = (router.query as WorkspaceAppEvalRunIParams);
  const {
    eval: evalRecord
  } = useFetchEvalBySlug(evalSlug, workspaceSlug);
  const {
    versions
  } = useFetchActionVersions(evalRecord?.action.id || 0);
  const {
    evalRuns
  } = useFetchEvalRunsBySlug({
    evalSlug,
    page: 1,
    perPage: 10,
    workspaceId: workspace?.id || 0
  });
  const runDisabled = useMemo(() => {
    if (!evalRecord) return true;
    if (evalRecord.evalTypes.length == 0) return true;
  }, [evalRecord]);
  const runningEvalRuns = useMemo(() => {
    return evalRuns ? evalRuns.filter(run => run.metadata && (run.metadata as EvalRunMetadata).status != 'completed') : [];
  }, [evalRuns]);
  const currentVersionName: string | undefined = useMemo(() => {
    if (versions && versions[0]) {
      const version = versions[0];
      return `v${version.version_number}`;
    }
  }, [versions]);
  const [selectedEvalRunId, setSelectedEvalRunId] = useState<number | undefined>(evalRunId ? parseInt(evalRunId) : undefined);
  const utils = api.useContext();
  const runEval = api.eval.run.useMutation();
  const [runLoading, setRunLoading] = useState(false);
  if (!evalRecord || !workspace) {
    return <div className="flex items-center justify-center py-12">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>;
  }
  const handleRunEval = async () => {
    setRunLoading(true);
    try {
      await runEval.mutateAsync({
        evalGuid: evalRecord.guid,
        workspaceId: workspace.id
      });
      setRunLoading(false);
      toast.success({
        title: 'Eval run started',
        description: 'Your evaluation run has started'
      });
      await utils.eval.get.invalidate({
        id: evalRecord.id
      });
      await utils.evalRun.getAll.invalidate({
        evalId: evalRecord.id
      });
      await utils.evalRun.getAllBySlug.invalidate({
        evalSlug: evalRecord.slug
      });
      await utils.eval.getResultsByPeriod.invalidate({
        evalId: evalRecord.id
      });
    } catch (error) {
      const err = error instanceof Error ? error.message : JSON.stringify(error);
      toast.error({
        title: `Something went wrong`,
        description: err
      });
      setRunLoading(false);
    }
  };
  const dataset = evalRecord?.dataset;
  const {
    dataCount
  } = (dataset?.metadata as DatasetMetadata);
  return <>
      <Head>
        <title>{evalRecord.name} / Klu.ai</title>
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            <div className="flex flex-1 items-center">
              <h1 className="min-w-fit text-xl font-semibold text-grey-800 dark:text-white items-center flex gap-1">
                <Link href={`/${workspace.slug}/apps/${slug}/evaluate`} className="">
                  Evaluate
                </Link>
                <ChevronRight className="h-5 w-5 mx-2 text-grey-400" aria-hidden="true" />
                {evalRecord?.name}
              </h1>
            </div>
            <div className="flex items-center space-x-3">
              <Tooltip.Root delayDuration={333}>
                <Tooltip.Trigger asChild>
                  <Link href={`https://docs.klu.ai/guides/evals`} target="new">
                    <Button variant="outline" startIcon={DocsIcon}>
                      Learn more
                    </Button>
                  </Link>
                </Tooltip.Trigger>
                <Tooltip.Content side={'bottom'}>Learn more about evals</Tooltip.Content>
              </Tooltip.Root>

              <Button disabled={runDisabled} startIcon={EvalRunIcon} onClick={() => void handleRunEval()} tooltip={{
              content: currentVersionName ? `Evaluate version: ${currentVersionName}` : null,
              side: 'bottom'
            }}>
                <span>{runLoading ? 'Starting ...' : 'Run Eval'}</span>
              </Button>
            </div>
          </div>
        </div>
        <main>
          {selectedEvalRunId && <EvalRunDrawer evalRunId={selectedEvalRunId} open={true} setOpen={val => {
          setSelectedEvalRunId(val ? selectedEvalRunId : undefined);
        }} workspaceId={workspace.id} />}

          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <EvalNavTabs selected={'overview'} />

            {evalRecord && <div className="mt-6 flex flex-col gap-6">
                {runningEvalRuns && runningEvalRuns.length > 0 && <RunningEvals evalRuns={(runningEvalRuns as DetailedEvalRun[])} />}

                {/* eval overview row */}
                <EvalOverview evalRecord={evalRecord} />
                {/* eval charts row, don't show if it's still the first run and it hasn't finished yet */}
                {evalRuns && evalRuns.length > 0 && !(evalRuns.length === 1 && runningEvalRuns.length === 1) ? <>
                    <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-1">
                        <EvalPassRateOverview evalRecord={evalRecord} />
                      </div>
                      <div className="col-span-3">
                        <EvalCharts evalRecord={evalRecord} />
                      </div>
                    </div>

                    <EvalRunGrid evalRecord={evalRecord} setSelectedEvalRunId={setSelectedEvalRunId} />
                  </> : <div className="rounded-lg border border-grey-200 dark:border-zinc-800 bg-white py-8 items-center shadow-sm flex flex-col gap-3 ">
                    {dataset && (!dataCount || dataCount && dataCount < 1) ? <p className="mt-1 text-sm text-grey-500 max-w-md">
                        <div className="flex gap-3 items-center">
                          <span className="font-semibold">Dataset is empty</span>
                          <DataImport workspace={workspace} dataset={dataset} />
                          <DatasetImportStatus datasetId={dataset.id} />
                        </div>
                      </p> : <>
                        <h3 className="mt-2 text-sm font-semibold">No eval runs yet.</h3>
                        <Button disabled={runDisabled} startIcon={EvalRunIcon} onClick={() => void handleRunEval()}>
                          <span>{runLoading ? 'Starting ...' : 'Run Eval'}</span>
                        </Button>
                      </>}
                  </div>}
              </div>}
          </div>
        </main>
      </div>
    </>;
}
const RunningEvals = ({
  evalRuns
}: {
  evalRuns: DetailedEvalRun[];
}) => {
  return <>
      {evalRuns.map(run => <Card key={run.id} className="w-full">
          <RunningEval key={run.id} evalRun={run} />
        </Card>)}
    </>;
};
const RunningEval = ({
  evalRun
}: {
  evalRun: DetailedEvalRun;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteEvalRun = api.evalRun.delete.useMutation();
  const utils = api.useContext();
  const actionVersionMetadata = (evalRun.actionVersion?.metadata as ActionVersionMetadata);
  const {
    status
  } = ((evalRun.metadata || {}) as EvalRunMetadata);
  const handleDelete = async () => {
    await deleteEvalRun.mutateAsync({
      id: evalRun.id
    });
    await utils.evalRun.getAllBySlug.invalidate({
      evalSlug: evalRun.Eval.slug
    });
  };
  return <div className="flex flex-col gap-2 text-grey-600 dark:text-zinc-600 text-sm">
      {showDeleteModal && <DeleteEvalRunModal open={showDeleteModal} onOpenChange={setShowDeleteModal} name={`${evalRun.Eval.name} run #${evalRun.run_number}`} callback={{
      onDelete: async () => await handleDelete()
    }} />}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <AppEval className="h-5 w-5 text-grey" />
          <span className="font-medium text-grey-800">
            {evalRun.Eval.name} run #{evalRun.run_number}
          </span>
        </div>
        <Button variant="outline-hover" className="w-9" size="sm" onClick={() => setShowDeleteModal(true)}>
          <XClose className="h-5 w-5" />
          <span className="sr-only">Delete</span>
        </Button>
      </div>
      <div className="text-sm text-grey-600 dark:text-zinc-600">
        {status === 'running' && <>started {dateAgo(new Date(evalRun.createdAt))}</>}
      </div>
      <div className="text-sm text-grey-600 dark:text-zinc-600">
        Version #{evalRun.actionVersion?.version_number}
        <span className="text-grey-400">{' | '}</span>
        {actionVersionMetadata?.modelName}
      </div>
      <div className="flex items-center justify-between">
        <Room id={`evalRun-${evalRun.id}`} hideLoader={true}>
          <EvalRunStatus evalRun={evalRun} />
        </Room>
        {/* {status == 'cancelled' && <span className="text-red-500">cancelled</span>} */}
      </div>
    </div>;
};
export const EvalCharts = ({
  evalRecord
}: {
  evalRecord: DetailedEval;
}) => {
  return <Card>
      {evalRecord.dataType == 'TEST' ? <EvalScoreChart evalRecord={evalRecord} /> : <EvalPassFailChart evalRecord={evalRecord} />}
    </Card>;
};
export const EvalNavTabs = ({
  selected
}: {
  selected?: string;
}) => {
  const router = useRouter();
  const workspace = useWorkspace();
  const {
    evalSlug,
    slug
  } = (router.query as WorkspaceAppEvalIParams);
  const tabs = [{
    name: 'Runs',
    id: 'overview',
    href: ''
  }, {
    name: 'Dataset',
    id: 'dataset',
    href: 'dataset'
  }, {
    name: 'Settings',
    id: 'edit',
    href: 'edit'
  }, {
    name: 'Preview Run',
    id: 'preview',
    href: 'preview'
  }, {
    name: 'Comments',
    id: 'comments',
    href: 'comments'
  }];
  return <div className="border-b border-grey-300">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {workspace && tabs.map(tab => <Link href={`/${workspace.slug}/apps/${slug}/evaluate/evals/${evalSlug}/${tab.href}`} key={tab.name} className={classNames(selected === tab.id ? 'border-grey-800 text-grey-800' : 'border-transparent text-grey-500 hover:border-grey-300', 'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium flex items-center gap-1')}>
              {tab.name}
              {tab.id === 'comments' && <Room id={`eval-${evalSlug}`} hideLoader={true}>
                  <EvalCommentsCount />
                </Room>}
            </Link>)}
      </nav>
    </div>;
};
const EvalCommentsCount = () => {
  const {
    threads
  } = useThreads();
  if (!threads) return null;
  const commentsCount = threads ? threads.reduce((acc, thread) => acc + thread.comments.length, 0) : 0;
  return <div className="ml-2">
      {threads.length > 0 && <span className="flex items-center gap-1 text-grey-400">
          <MessageSquare02 className="h-4 w-4 text-grey-400" aria-hidden="true" />
          {commentsCount}
        </span>}
    </div>;
};
const EvalPageDetail404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <App404 />;
  }
  return <Sidebar>
      <EvalPage {...props} />
    </Sidebar>;
};
export default _withSuperJSONPage(EvalPageDetail404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withApp(), []);