import { z } from 'zod'

export const createFinetuneFormSchema = z.object({
  name: z
    .string()
    .max(18, { message: 'OpenAI requires the finetune name to be less than 18 characters' }),
  baseModel: z.string(),
  datasetId: z.number(),
  overideSystemMessage: z.optional(z.string()),
  workspaceModelProviderId: z.number().min(0),
})
