import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components'
import { cn } from '@/client/utils'
import type { TriggerProps } from './types'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  TriggerProps
>(({ className, icon, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex focus-visible:outline-none">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        `
          body1
          flex
          flex-1
          min-w-0
          items-center
          justify-between
          gap-3
          p-4
          transition-all
          focus-visible:outline-1
          focus-visible:outline-grey-800
          [&[data-state=open]>.accordion-chevron]:rotate-180
        `,
        // data-[state=open]:pb-3
        // dark:focus-visible:outline-white/[.92]
        className
      )}
      {...props}
    >
      <div className="flex items-center gap-3 font-medium min-w-0">
        {icon ? (
          <div className="bg-primary-lightest-1 rounded-md p-1 text-grey-400 dark:bg-primary dark:text-grey-50">
            <Icon size="md" component={icon} />
          </div>
        ) : null}

        {children}
      </div>

      <Icon
        className="accordion-chevron shrink-0 transition-transform duration-200"
        size="md"
        component={ChevronDown}
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

export { AccordionTrigger }
