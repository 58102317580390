import * as DialogPrimitive from '@radix-ui/react-dialog'
import React from 'react'
import { cn } from '@/client/utils'
import type { DialogDescriptionProps } from './types'

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  DialogDescriptionProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-sm text-grey-500 dark:text-zinc-500', className)}
    {...props}
  />
))

DialogDescription.displayName = DialogPrimitive.Description.displayName

export { DialogDescription }
