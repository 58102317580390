import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import type { ParsedUrlQueryInput } from 'querystring';
import type { Action, App, User } from '@prisma/client';
import { Card } from '@tremor/react';
import { ChevronDown, Code02, CursorClick02, QrCode01, ShieldTick, SwitchHorizontal02, User02, XClose } from '@untitled-ui/icons-react';
import { type NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { AsteriskIcon, BracketEllipsesIcon, ClockStopwatchIcon, CompareIcon, CPUChip, FileSearch, FingerPrintIcon, GpuRequestIcon, LightBulpIcon, LineChartDownIcon, MinimizeIcon, ObserveIcon, PuzzlePieceIcon, SpacingWidth01Icon, StudioIcon, TargetIcon, TextInputIcon, UserCheck } from '@/client/assets/icons/icons';
import { Button, DropdownMenu, Icon, Skeleton } from '@/client/components';
import { PublishAction } from '@/client/containers/views/Studio/components/Topbar/components';
import { TabProvider } from '@/client/containers/views/Studio/components/Topbar/components/PublishAction/context';
import { AddActionMonitorDrawer, OpenMobileSidebarButton, Sidebar } from '@/common/components';
import { ActionUsageCard } from '@/common/components/actions/ActionUsageGraph';
import { EvalPassFailChart } from '@/common/components/evals/EvalPassFailChart';
import { MonitorDrawer } from '@/common/components/modals/evals/MonitorDrawer';
import { Action404 } from '@/common/components/notFound/404';
import { useWorkspace } from '@/common/components/ui/context';
import { FullLoaderPage } from '@/common/components/ui/FullLoaderPage';
import { useCurrentApp, useCurrentWorkspace, useFetchActionBySlug, useFetchActionEvals, useFetchAppBySlug } from '@/common/hooks';
import { useIsMobile } from '@/common/hooks/isMobile';
import { withAction } from '@/common/lib/ssr';
import type { DetailedEval, EvalMetadata, EvalTypeMetadata } from '@/common/types/eval';
import type { WorkspaceAppActionIParams } from '@/common/types/queryparams';
import { api } from '@/utils';
interface IParams extends ParsedUrlQueryInput {
  workspaceSlug: string;
  actionSlug: string;
  slug: string;
}
export const ActionMonitorsCards = ({
  action
}: {
  action: Action;
}) => {
  const workspace = useWorkspace();
  const {
    app
  } = useCurrentApp();
  const {
    evals,
    isLoading
  } = useFetchActionEvals(action.id, action.workspaceId, 'LIVE');
  if (isLoading) {
    return <Skeleton className="h-60" />;
  }
  if (!evals) {
    return <>No monitors yet. Add one?</>;
  }
  return <div className="sm:rounded-lg">
      <div className="min-w-full rounded-t-md bg-grey-25 text-xs">
        <div className="flex items-center justify-between min-w-full">
          <div className="font-medium uppercase tracking-wider text-grey-800 ">
            Live Monitoring & Evaluation
          </div>
          {evals.length > 0 && <AddEvalButton action={action} app={app} />}
        </div>
      </div>

      <div className={`grid grid-cols-1 gap-6 sm:grid-cols-1 ${evals.length >= 2 ? 'lg:grid-cols-3' : `lg:grid-cols-${evals.length}`} lg:grid-rows-1 pt-6`}>
        <ActionUsageCard workspace={workspace} action={action} />
        {evals.length > 0 ? <>
            {evals.map(evalRecord => <MonitorCard action={action} evalRecord={evalRecord} key={`eval-${evalRecord.id}`} />)}
          </> : <AddMonitorCard action={action} app={app} />}
      </div>
    </div>;
};
const AddMonitorCard = ({
  action,
  app
}: {
  action: Action;
  app: (App & {
    createdBy: User;
  }) | undefined;
}) => {
  return <>
      <Card>
        <div className="h-full flex flex-col justify-center">
          <div className="text-grey-700 font-medium text-md text-center">Monitor this Action</div>

          <div className="w-full mt-5 text-center">
            {app !== undefined && <AddEvalButton action={action} app={app} />}
          </div>
        </div>
      </Card>
    </>;
};
const AddEvalButton = ({
  action,
  app
}: {
  action: Action;
  app: (App & {
    createdBy: User;
  }) | undefined;
}) => {
  const [openCreateModal, toggleCreateModal] = useState(false);
  if (!app) {
    return null;
  }
  return <>
      {app !== undefined && <AddActionMonitorDrawer open={openCreateModal} setOpen={toggleCreateModal} app={app} action={action} />}

      <Button onClick={() => {
      toggleCreateModal(true);
    }} variant="outline" type="submit">
        <ObserveIcon className="h-4 w-4 mr-2" />
        Add Monitor
      </Button>
    </>;
};
export const getEvalIcon = (icon: string) => {
  switch (icon) {
    case 'CompareIcon':
      return CompareIcon;
    case 'ArrowsRightLeftIcon':
      return SwitchHorizontal02;
    case 'SpacingWidth01Icon':
      return SpacingWidth01Icon;
    case 'GpuRequestIcon':
      return GpuRequestIcon;
    case 'CursorArrowRippleIcon':
      return CursorClick02;
    case 'CodeBracketIcon':
      return Code02;
    case 'ShieldCheckIcon':
      return ShieldTick;
    case 'QrCodeIcon':
      return QrCode01;
    case 'UserIcon':
      return User02;
    case 'UserCheck':
      return UserCheck;
    case 'FileSearch':
      return FileSearch;
    case 'FingerPrintIcon':
      return FingerPrintIcon;
    case 'PuzzlePieceIcon':
      return PuzzlePieceIcon;
    case 'MinimizeIcon':
      return MinimizeIcon;
    case 'LightBulpIcon':
      return LightBulpIcon;
    case 'TargetIcon':
      return TargetIcon;
    case 'LineChartDownIcon':
      return LineChartDownIcon;
    case 'CPUChip':
      return CPUChip;
    case 'ClockStopwatchIcon':
      return ClockStopwatchIcon;
    case 'TextInputIcon':
      return TextInputIcon;
    case 'AsteriskIcon':
      return AsteriskIcon;
    case 'BracketEllipsesIcon':
      return BracketEllipsesIcon;
    default:
      return undefined;
  }
};
const MonitorCard = ({
  evalRecord,
  action
}: {
  evalRecord: DetailedEval;
  action: Action;
}) => {
  const {
    selectedEvalId
  } = (useRouter().query as IParams);
  const [drawerOpen, setDrawerOpen] = useState(selectedEvalId == evalRecord.id.toString());
  const evalType = evalRecord.evalTypes?.[0]?.evalType;
  if (!evalType) {
    return null;
  }
  const evalTypeMetadata = (evalType.metadata as EvalTypeMetadata);
  const icon = getEvalIcon(evalTypeMetadata.icon);
  const deleteEval = api.eval.delete.useMutation();
  const utils = api.useContext();
  const handleDelete = async (id: number) => {
    if (confirm('Are you sure you want to delete this eval?')) {
      await deleteEval.mutateAsync({
        evalId: id
      });
      await utils.eval.getAllForAction.invalidate({
        actionId: action.id
      });
    }
  };
  const {
    variables
  } = (evalRecord?.metadata as EvalMetadata);
  return <Card>
      <MonitorDrawer evalRecord={evalRecord} open={drawerOpen} setOpen={setDrawerOpen} />
      <div className="flex flex-col h-full group">
        <div onClick={() => setDrawerOpen(true)} className="text-grey-700 font-medium text-md flex gap-1 items-center flex-grow-0
          hover:text-primary-700 cursor-pointer mb-3">
          {icon && <Icon className="w-5 h-5 mr-2" component={icon} size={'xs'} />}
          {evalType.name}
        </div>
        <div className="flex-grow">
          <Button startIcon={XClose} variant="outline" className=" invisible group-hover:visible absolute top-5 right-5" onClick={() => {
          void handleDelete(evalRecord.id);
        }} />
          <div className="flex gap-2 flex-col mb-3">
            <div className="hidden">
              {variables?.map((variable, i) => <div key={`var-${i}`} className="flex gap-3">
                  <div className="text-grey-500 text-sm capitalize font-semibold">
                    {variable.name}:
                  </div>
                  <div className="text-grey-700 text-sm max-h-[200px] overflow-y-auto">
                    <pre>{variable.value}</pre>
                  </div>
                </div>)}
            </div>

            <div>
              <EvalPassFailChart evalRecord={evalRecord} sinceDays={7} />
            </div>
          </div>
        </div>
      </div>
    </Card>;
};
const ActionEvalsPage: NextPage = () => {
  const router = useRouter();
  const {
    actionSlug,
    slug
  } = (router.query as WorkspaceAppActionIParams);
  const {
    action,
    isLoading: isLoadingAction
  } = useFetchActionBySlug(actionSlug);
  const {
    app
  } = useFetchAppBySlug(slug);
  const {
    workspace
  } = useCurrentWorkspace();
  const isMobile = useIsMobile();
  if (isLoadingAction) {
    return <FullLoaderPage />;
  }
  if (!action) {
    return null;
  }
  return <Sidebar>
      <Head>
        <title>{action.name} / Klu.ai</title>
        <meta name="description" content={`Action ${action?.name}`} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-white dark:bg-black bg-opacity-50 backdrop-blur">
          <div className="max-w-8xl mx-auto flex flex-1 items-center justify-between px-4 sm:px-6">
            <OpenMobileSidebarButton />
            {workspace && <div className="flex flex-1 items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Link href={`/${workspace.slug}/apps/${app?.slug || ''}/actions/${action.slug}`}>
                    <h1 className="text-xl font-semibold text-grey-800 dark:text-white whitespace-nowrap">
                      {action.name}
                    </h1>
                  </Link>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <ChevronDown className="w-5 h-5 text-grey-400" />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <DropdownMenu.Label>{action.name}</DropdownMenu.Label>
                      <DropdownMenu.Separator />
                      <Link href={`/${workspace.slug}/apps/${app?.slug || ''}`}>
                        <DropdownMenu.Item>Back to {app?.name}</DropdownMenu.Item>
                      </Link>
                      {isMobile && app && <>
                          <DropdownMenu.Item onClick={() => void router.push(`/${workspace.slug}/apps/${app?.slug}/actions/${action.slug}/studio`)}>
                            <StudioIcon className="w-5 h-5 mr-2" />
                            Open
                          </DropdownMenu.Item>
                        </>}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </div>
                {!isMobile && app && <div className="flex items-center space-x-4">
                    <Button variant="outline" startIcon={StudioIcon} onClick={() => void router.push(`/${workspace.slug}/apps/${app.slug}/actions/${action.slug}/studio`)}>
                      Open
                    </Button>
                    <TabProvider>
                      <PublishAction workspace={workspace} action={action} appslug={app.slug} />
                    </TabProvider>
                  </div>}
              </div>}
          </div>
        </div>
        <main>{workspace && app && <ActionMonitorsCards action={action} />}</main>
      </div>
    </Sidebar>;
};
const ActionEval404Wrapper: NextPage = ({
  dne,
  ...props
}: {
  dne?: boolean;
}) => {
  if (dne) {
    return <Action404 />;
  }
  return <ActionEvalsPage {...props} />;
};
export default _withSuperJSONPage(ActionEval404Wrapper);
export const getServerSideProps = _withSuperJSONProps(withAction(), []);