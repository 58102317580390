import { api } from '@/utils'
import type { DetailedEvalRunItem, EvalRunItemMetadata } from '../types/eval'
import { useUser } from './user'

export function useFetchCompareEvalRuns({
  evalId,
  evalRunIds,
  workspaceId,
}: {
  evalId: number
  evalRunIds?: number[]
  workspaceId: number
}) {
  const { user } = useUser()

  if (!evalRunIds) {
    const { data, isLoading, error } = api.evalRun.getAll.useQuery(
      { evalId, page: 1, perPage: 5, workspaceId },
      {
        enabled: user !== undefined,
        refetchOnWindowFocus: false,
      }
    )
    return {
      evalRuns: data && data.evalRuns,
      isLoading,
      error,
    }
  } else {
    const { data, isLoading, error } = api.evalRun.getManyByIds.useQuery(
      { evalRunIds, workspaceId: workspaceId },
      {
        enabled: user !== undefined,
        refetchOnWindowFocus: false,
      }
    )
    return {
      evalRuns: data,
      isLoading,
      error,
    }
  }
}

export function useFetchEvalRuns({
  evalId,
  page,
  perPage,
  workspaceId,
}: {
  evalId: number
  page: number
  perPage: number
  workspaceId: number
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRun.getAll.useQuery(
    { evalId, page, perPage, workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )

  return {
    totalCount: data && data.total,
    evalRuns: data && data.evalRuns,
    isLoading,
    error,
  }
}

export function useFetchEvalRunsBySlug({
  evalSlug,
  page,
  perPage,
  workspaceId,
}: {
  evalSlug: string
  page: number
  perPage: number
  workspaceId: number
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRun.getAllBySlug.useQuery(
    { evalSlug, page, perPage, workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )

  return {
    totalCount: data && data.total,
    evalRuns: data && data.evalRuns,
    isLoading,
    error,
  }
}

export function useFetchEvalRunsDelta({
  evalId,
  workspaceId,
}: {
  evalId: number
  workspaceId: number
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRun.getDelta2.useQuery(
    { evalId, workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )

  return {
    totalCount: data && data.total,
    evalRuns: data && data.evalRuns,
    isLoading,
    error,
  }
}

export function useFetchEvalRun(evalId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRun.get.useQuery(
    { id: evalId, workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    evalRun: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunItems(evalRunId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRunItem.getAll.useQuery(
    { evalRunId },
    {
      enabled: user !== undefined,
    }
  )

  return {
    evalRunItems: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunItemsForDatasetItem({
  evalRunIds,
  datasetItemId,
}: {
  evalRunIds: number[]
  datasetItemId: number
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRunItem.getAllForDatasetItem.useQuery(
    { evalRunIds, datasetItemId },
    {
      enabled: user !== undefined,
    }
  )

  return {
    evalRunItems: data,
    isLoading,
    error,
  }
}

export function useRefetchEvalRunItemsUntilScored(evalRunId: number) {
  const { user } = useUser()

  const { data, isLoading, error } = api.evalRunItem.getAll.useQuery(
    { evalRunId },
    {
      enabled: user !== undefined,
      refetchInterval: function (data: DetailedEvalRunItem[] | undefined) {
        if (!data) return 1000
        if (data.length === 0) return 1000
        return shouldRefetchEvalRunItems(data) ? 1000 : false
      },
      refetchOnWindowFocus: false,
    }
  )

  return {
    evalRunItems: data,
    isLoading,
    error,
  }
}

const shouldRefetchEvalRunItems = (data: DetailedEvalRunItem[]) => {
  return !data.every((evalRunItem) => {
    // return evalRunItem.results != undefined

    const { status } = evalRunItem.metadata as EvalRunItemMetadata
    return ['failed', 'complete', 'cancelled'].includes(status as string)
  })
}
