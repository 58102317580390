import type { Action, Workspace } from '@prisma/client'
import type { DateRangePickerValue } from '@tremor/react'
import { AreaChart, Card, DateRangePicker } from '@tremor/react'
import { Sliders04 } from '@untitled-ui/icons-react'
import { add, format, startOfYesterday } from 'date-fns'
import { useState } from 'react'
import { InsightsIcon } from '@/client/assets/icons/icons'
import { Loader, Sheet, SheetContent, SheetHeader, SheetTitle } from '@/client/components'
import { getFormatString, getIntervalSize } from '@/common/components/graphs/data'
import { useWorkspaceFeaturesEnabled } from '@/common/hooks'
import { useFetchWorkspaceUsageDataGraph } from '@/common/hooks/observe'
import { featureFlags } from '@/utils/featureFlags'

export function ActionUsageCard({ workspace, action }: { workspace: Workspace; action: Action }) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <Card>
      <div className="flex flex-col h-full group">
        <div className="flex w-full flex-wrap justify-between gap-1 items-center flex-grow-0 hover:text-primary-700">
          <div
            onClick={() => setDrawerOpen(true)}
            className="text-grey-700 font-medium text-md cursor-pointer mb-3 flex space-between items-center"
          >
            <InsightsIcon className="w-5 h-5 mr-2 " />
            <h3 className="">Generations and Feedback</h3>
          </div>
        </div>

        <ActionUsageGraph workspace={workspace} action={action} hideDatepicker={true} />
        <Drawer open={drawerOpen} setOpen={setDrawerOpen} workspace={workspace} action={action} />
      </div>
    </Card>
  )
}

export function ActionUsageGraph({
  workspace,
  action,
  hideDatepicker,
}: {
  workspace: Workspace
  action: Action
  hideDatepicker?: boolean
}) {
  const { featuresEnabled } = useWorkspaceFeaturesEnabled(action.workspaceId)

  const [dateRangeShown, setDateRangeShown] = useState(!hideDatepicker)

  const [filterRange, setFilterRange] = useState<{
    startDate: Date
    endDate: Date
  }>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  })
  const [value, setValue] = useState<DateRangePickerValue>({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  })
  const intervalMin = getIntervalSize(
    filterRange.startDate.getTime(),
    filterRange.endDate.getTime()
  )

  const { data, isLoading } = useFetchWorkspaceUsageDataGraph(workspace.id, {
    startDate: filterRange.startDate.getTime(),
    endDate: filterRange.endDate.getTime(),
    intervalSize: intervalMin,
    actionId: action.id,
  })

  const formatString = getFormatString(
    filterRange.startDate.getTime(),
    filterRange.endDate.getTime()
  )

  function formatData() {
    // if (!data) return []
    // if theres no data return a list of 0s
    if (!data || data.length === 0) {
      const emptyData = []
      let currentDate = filterRange.startDate.getTime()
      const endDate = filterRange.endDate.getTime()

      // divide the range into 10 intervals
      const interval = (endDate - currentDate) / 10
      while (currentDate < filterRange.endDate.getTime()) {
        emptyData.push({
          interval_start: format(new Date(currentDate), formatString),
          Usage: 0,
          Feedback: 0,
          Errors: 0,
        })
        currentDate += interval
      }
      return emptyData
    }
    const mappedData = data.map((row) => {
      return {
        interval_start: format(new Date(row.interval_start), formatString),
        Usage: row.total_usage_count,
        Feedback: row.total_feedback_count,
        Errors: row.total_error_count,
      }
    })
    return mappedData
  }

  const liveData = formatData()

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12 h-[262px]">
        <Loader className="h-6 w-6 text-grey-400 dark:text-zinc-400" />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full group">
      <div className="flex w-full flex-wrap justify-between gap-1 items-center flex-grow-0 hover:text-primary-700">
        <div className="max-w-full mr-5">
          {dateRangeShown && (
            <DateRangePicker
              color="zinc"
              placeholder="Select a timeframe..."
              value={value}
              onValueChange={({ from: selectedStartDay, to: selectedEndDay }) => {
                setValue({ from: selectedStartDay, to: selectedEndDay })
                if (!selectedStartDay || !selectedEndDay) return
                if (
                  selectedStartDay.getTime() === selectedEndDay.getTime() ||
                  selectedStartDay === startOfYesterday()
                ) {
                  selectedEndDay = add(selectedEndDay, { days: 1 })
                }
                setFilterRange({
                  startDate: selectedStartDay,
                  endDate: selectedEndDay,
                })
              }}
              minDate={undefined}
              maxDate={undefined}
              className="max-w-full mt-0"
            />
          )}
        </div>
      </div>

      {hideDatepicker && (
        <div
          className={`cursor-pointer ${dateRangeShown ? '' : 'invisible'} group-hover:visible absolute top-5 right-5 h-5 w-5`}
          onClick={() => setDateRangeShown(!dateRangeShown)}
        >
          <Sliders04
            className={`w-5 h-5 ${dateRangeShown ? 'text-primary-700' : 'text-grey-400'}`}
          />
        </div>
      )}

      <AreaChart
        data={liveData}
        categories={
          featuresEnabled?.includes(featureFlags.errors)
            ? ['Usage', 'Feedback', 'Errors']
            : ['Usage', 'Feedback']
        }
        index="interval_start"
        colors={['blue', 'zinc', 'red']}
        showYAxis={false}
        showLegend={false}
        className="min-h-[250px]"
        showGridLines={false}
      />
    </div>
  )
}

const Drawer = ({
  action,
  workspace,
  open,
  setOpen,
}: {
  workspace: Workspace
  action: Action
  open: boolean
  setOpen: (val: boolean) => void
}) => {
  return (
    <>
      <Sheet open={open} onOpenChange={setOpen} modal={false}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetHeader>
            <SheetTitle
              className="text-grey-700 font-medium text-md flex gap-1 items-center 
          "
            >
              <InsightsIcon className="w-5 h-5 mr-2 " />
              <h3 className="">Generations and Feedback</h3>
            </SheetTitle>
          </SheetHeader>
          <div className="pt-[40px] relative">
            <ActionUsageGraph workspace={workspace} action={action} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  )
}
