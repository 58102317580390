import { AlertTriangle } from '@untitled-ui/icons-react'
import { Button, Dialog } from '@/client/components'

function UnsavedChangesForm({
  setOpen,
  confirmCallback,
}: {
  setOpen: (val: boolean) => void
  confirmCallback: () => void
}) {
  function confirmNavigate() {
    confirmCallback()
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">
              You have unsaved changes
            </h3>
            <div className="mt-2">
              <p className="text-sm text-grey-500">Are you sure you want to navigate away?</p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 sm:flex sm:px-6">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button onClick={() => void confirmNavigate()}>Confirm</Button>
      </div>
    </>
  )
}

export const UnsavedChangesModal = ({
  open,
  setOpen,
  confirmCallback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  confirmCallback: () => void
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <UnsavedChangesForm setOpen={setOpen} confirmCallback={confirmCallback} />
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
