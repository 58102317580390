import type { Workspace } from '@prisma/client'
import { usePresignedUpload } from 'next-s3-upload'
import React from 'react'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editWorkspaceLogoFormSchema } from './schema'
import type { EditWorkspaceLogoFormInputs } from './types'

export const useEditWorkspaceLogoForm = ({ workspace }: { workspace: Workspace }) => {
  const editWorkspace = api.workspace.editLogo.useMutation()
  const utils = api.useContext()
  const [uploading, setUploading] = React.useState(false)
  const { uploadToS3 } = usePresignedUpload()
  const [fileUploadKey, setFileUploadKey] = React.useState('')

  const { user } = useUser()

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Workspace logo updated',
      description: 'Your workspace logo was updated successfully',
    })
    setFileUploadKey(Date.now().toString()) //gross
  }, [])

  const onChangeFile = async (files: File[]) => {
    const file = files[0]
    if (file) {
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
      if (!validImageTypes.includes(file.type)) {
        toast.error({
          title: 'Invalid file type',
          description: 'Please upload a valid image file (.png, .jpg, .jpeg, .gif)',
        })
        return
      }

      setUploading(true)
      const { url } = await uploadToS3(file, {
        endpoint: {
          request: {
            body: {
              workspaceGuid: workspace.project_guid,
            },
            url: '/api/s3-public-upload',
          },
        },
      })
      setUploading(false)
      await saveWorkspace({ logoUrl: url })
    }
  }

  const saveWorkspace = React.useCallback(
    async (values: EditWorkspaceLogoFormInputs) => {
      const updatedWorkspace = await editWorkspace.mutateAsync({
        logoUrl: editWorkspaceLogoFormSchema.parse(values).logoUrl,
        workspaceId: workspace.id,
      })
      await utils.workspace.get.invalidate({ id: workspace.id })
      await utils.workspace.getBySlug.invalidate({ slug: updatedWorkspace.slug })
      await utils.workspace.getIsMemberOf.invalidate()

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.workspace_edited, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })
      onSuccess()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editWorkspace, user, onSuccess, utils.workspace.get, utils.workspace.getBySlug, workspace]
  )

  const onDelete = async () => {
    await saveWorkspace({ logoUrl: null })
  }

  return {
    uploading,
    onChangeFile,
    onDelete,
    fileUploadKey,
    setFileUploadKey,
  }
}
