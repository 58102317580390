import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api } from '@/utils'
import { deleteFeedbackBehaviourFormSchema } from './schema'
import type { DeleteFeedbackBehaviourFormInputs, FormProps } from './types'

export const useDeleteFeedbackBehaviourForm = ({
  feedbackBehaviour,
  onIsOpenChange,
}: FormProps) => {
  const deleteBehaviourFeedbackValueType =
    api.feedback.deleteBehaviourFeedbackValueType.useMutation()
  const utils = api.useContext()

  const initialValues = React.useMemo(() => {
    const initialValues: DeleteFeedbackBehaviourFormInputs = {}

    return initialValues
  }, [])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'User behavior signal deleted',
      description: 'User behavior signal was deleted successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: DeleteFeedbackBehaviourFormInputs,
      { resetForm }: FormikHelpers<DeleteFeedbackBehaviourFormInputs>
    ) => {
      await deleteBehaviourFeedbackValueType.mutateAsync({
        behaviourType: feedbackBehaviour,
        ...values,
      })
      await utils.feedback.getBehaviourFeedbackValues.invalidate()
      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      feedbackBehaviour,
      deleteBehaviourFeedbackValueType,
      onIsOpenChange,
      onSuccess,
      utils.feedback.getBehaviourFeedbackValues,
    ]
  )

  const formikConfig: FormikConfig<DeleteFeedbackBehaviourFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(deleteFeedbackBehaviourFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
