import { Check, Clipboard } from '@untitled-ui/icons-react'
import { useState } from 'react'

// import { useHotkeys } from 'react-hotkeys-hook'
// import { toast } from '@/client/components/Toast/use-toast'

interface GuidCopyButtonProps {
  guid: string
  label?: string
  className?: string
  alignment?: 'right' | 'left'
  apiKey?: boolean
}

export function GuidCopyButton({
  guid,
  label,
  className,
  apiKey = false,
  alignment = 'right',
}: GuidCopyButtonProps) {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(guid)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000) // Reset after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const Icon = copied ? Check : Clipboard
  const iconClasses = `h-3 w-3 ${
    copied
      ? 'text-green-700 dark:text-green-600'
      : 'text-white dark:text-zinc-400 group-hover:text-grey-700 dark:group-hover:text-zinc-700'
  }`

  return (
    <div
      className={`font-mono text-xs ${className || ''} ${
        alignment === 'right' ? 'flex-row' : 'flex-row-reverse'
      }`}
    >
      {label && !apiKey && (
        <p
          className={`${alignment === 'right' ? 'text-right' : 'text-left'} text-grey-600 dark:text-zinc-600`}
        >
          {label}
        </p>
      )}
      <div
        className={`group flex cursor-pointer items-start gap-x-1 pl-0 ${
          alignment === 'right' ? 'flex-row' : 'flex-row-reverse'
        }`}
        onClick={() => copyToClipboard()}
      >
        <div className={`group/icon flex flex-row relative`}>
          <Icon className={iconClasses} />
        </div>

        {apiKey ? (
          <p className="text-grey-800 dark:text-zinc-600">{label}</p>
        ) : (
          <p className="text-grey-800 dark:text-zinc-600">{guid.split('-')[0]}</p>
        )}
      </div>
    </div>
  )
}
