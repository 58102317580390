import type { Workspace } from '@prisma/client'
import { PlusCircle } from '@untitled-ui/icons-react'
import { useState } from 'react'
import { Button, Dialog } from '@/client/components'
import { Form } from './Form'

export const CreateEnvironment = ({ workspace }: { workspace: Workspace }) => {
  const [isOpen, onIsOpenChange] = useState(false)

  return (
    <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <Dialog.Trigger>
        <Button variant={'outline'} startIcon={PlusCircle}>
          New Environment
        </Button>
      </Dialog.Trigger>

      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="flex flex-col space-y-6">
            <Dialog.Title asChild>
              <h3 className="title3 grow px-6 pt-6">Create a new Environment</h3>
            </Dialog.Title>
            <Form workspace={workspace} onIsOpenChange={onIsOpenChange} />
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
