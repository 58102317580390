import { Folder } from '@untitled-ui/icons-react'
import { useStudio } from '@/client/containers/views/Studio/context'
import { useFetchApps } from '@/common/hooks'

export const useAppOptions = () => {
  const {
    state: { workspace },
  } = useStudio()
  const { isLoading: isLoadingApps, apps = [] } = useFetchApps(workspace.id)

  const appOptions = apps.map((app) => {
    return {
      value: app.id,
      label: app.name,
      icon: Folder,
    }
  })

  return {
    isLoadingApps,
    appOptions,
  }
}
