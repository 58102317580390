import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editEnvironmentFormSchema } from './schema'
import type { EditEnvironmentFormInputs, FormProps } from './types'

export const useEditEnvironmentForm = ({ workspace, environment, onIsOpenChange }: FormProps) => {
  const editEnvironment = api.workspace.editEnvironment.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: EditEnvironmentFormInputs = {
      name: environment.name,
    }

    return initialValues
  }, [environment.name])

  const onSuccess = React.useCallback((envName: string) => {
    toast.success({
      title: `${envName} updated`,
      description: 'Your environment was updated successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: EditEnvironmentFormInputs,
      { resetForm }: FormikHelpers<EditEnvironmentFormInputs>
    ) => {
      await editEnvironment.mutateAsync(
        {
          workspaceId: workspace.id,
          environmentId: environment.id,
          name: values.name,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'An error occurred',
              description: error.message,
            })
          },
        }
      )
      await utils.workspace.getEnvironments.invalidate({ workspaceId: workspace.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.environment_edited, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess(values.name)
      onIsOpenChange(false)
    },
    [
      editEnvironment,
      environment.id,
      onIsOpenChange,
      onSuccess,
      user,
      utils.workspace.getEnvironments,
      workspace.id,
      workspace.name,
      workspace?.slug,
    ]
  )

  const formikConfig: FormikConfig<EditEnvironmentFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editEnvironmentFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
