import { Folder, SearchSm } from '@untitled-ui/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Accordion, Icon, Input, Skeleton } from '@/client/components'
import { Actions } from './components'
import type { AppsProps } from './types'

export const Apps = ({ onIsOpenChange, isLoadingApps, apps, onActionOpened }: AppsProps) => {
  const [search, setSearch] = useState('')
  const [expandedApps, setExpandedApps] = useState<string[]>([])

  const filteredApps = useMemo(() => {
    if (!search) {
      return apps
    }

    //   filter by action name
    return apps
      .map((app) => {
        const filteredActions = app.Action.filter((action) =>
          action.name.toLowerCase().includes(search.toLowerCase())
        )
        return {
          ...app,
          Action: filteredActions,
        }
      })
      .filter((app) => app.Action.length > 0)
  }, [apps, search])

  useEffect(() => {
    setExpandedApps(filteredApps.map((app) => String(app.id)))
  }, [filteredApps])

  return (
    <div className="flex flex-col">
      {isLoadingApps ? (
        <div className="flex">
          <div className="flex-1 space-y-4">
            <Skeleton />

            <Skeleton />
          </div>
        </div>
      ) : apps.length > 0 ? (
        <>
          <div className="pb-4">
            <Input
              id="search"
              name="search"
              label={'Search action'}
              hideLabel
              autoComplete="off"
              placeholder="Search for Action"
              value={search}
              onChange={(e) => {
                void setSearch(e.target.value)
              }}
              icon={SearchSm}
              className={'pr-4 font-inter'}
            />
          </div>
          {filteredApps.length > 0 ? (
            <Accordion.Root
              className="w-full px-0"
              type="multiple"
              value={search || apps.length === 1 ? expandedApps : undefined}
              onValueChange={(value) => {
                setExpandedApps(value)
              }}
            >
              {filteredApps.map((app) => {
                return (
                  <Accordion.Item key={app.id} value={String(app.id)}>
                    <Accordion.Trigger className="body2 px-0 pr-4 py-4">
                      <Icon size="md" component={Folder} />

                      <p className="font-inter">{app.name}</p>
                    </Accordion.Trigger>

                    <Accordion.Content className="body2 px-0">
                      <Actions
                        appId={app.id}
                        onIsOpenChange={onIsOpenChange}
                        onActionOpened={onActionOpened}
                        actions={app.Action}
                      />
                    </Accordion.Content>
                  </Accordion.Item>
                )
              })}
            </Accordion.Root>
          ) : (
            <div className={'text-grey-500 text-center text-sm'}>No action matches your search</div>
          )}
        </>
      ) : (
        <p className="body2 text-grey-500">No apps available.</p>
      )}
    </div>
  )
}
