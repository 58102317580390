import { Code02, File05 } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { DropdownMenu, toast } from '@/client/components'
import { useStudio } from '@/client/containers/views/Studio/context'
import type { StudioQueryParams } from '@/client/containers/views/Studio/context/types'
import { useCmdCallback } from '@/client/hooks/shortcuts/useCmdEnter'
import type { DetailedActionVersion } from '@/common/components/actions/types'
import { useActionAPIModal } from '@/common/components/modals/actions/ViewActionAPIModal'
import { useActionVersionsModal } from '@/common/components/modals/actions/ViewActionVersionsModal'
import {
  useFetchActionVersionByGuid,
  useFetchActionVersions,
  useFetchEnvironments,
} from '@/common/hooks'
import type { ActionVersionMetadata } from '@/server/service/types'
import { usePersistStudioState } from '../../../Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '../../../Formik/hooks/useStudioFormikContext'
import { CopyButton } from './components/PublishDialog/components/CopyGUID'
import {
  DeployButton,
  DeployToEnvironmentButton,
} from './components/PublishDialog/components/Deploy'
import { DisableButton } from './components/PublishDialog/components/Disable'
import type { PublishDialogActionProps } from './components/PublishDialog/types'

export const useSelectVersion = () => {
  const { setFieldValue, values } = useStudioFormikContext()
  const { selectTab, selectedVersionGuid } = usePersistStudioState()
  const {
    state: { version },
    dispatch,
  } = useStudio()
  const { version: versionFromUrl } = useFetchActionVersionByGuid(selectedVersionGuid)

  const setValuesFromVersion = useCallback(
    async (version: DetailedActionVersion) => {
      const metadata = version.metadata as ActionVersionMetadata

      dispatch({ type: 'change-version', payload: version })

      // Chat Tab
      if (version.action_type === 'chat') {
        try {
          await setFieldValue('chat.templateMessages', JSON.parse(version.prompt))
        } catch (e) {
          console.error(e)
        }
      } else if (version.action_type === 'prompt') {
        try {
          const messages = (JSON.parse(version.prompt) as typeof values.completion.messages) ?? []
          await setFieldValue('completion.messages', messages)
          await setFieldValue('completion.promptTemplate', messages)
          const systemMessage =
            messages.find((m) => m.role === 'system')?.content ?? version.systemMessage
          await setFieldValue('completion.systemMessage', systemMessage)
        } catch (e) {
          console.error(e)
        }
      }

      // Common attributes
      await setFieldValue('outputInstructions', metadata.outputInstructions)
      await setFieldValue('outputFormat', metadata.outputFormat)
      if (metadata.workspaceModelProviderId) {
        await setFieldValue(
          'workspaceModelProviderId',
          metadata.workspaceModelProviderId.toString()
        )
      }
      if (metadata.modelName) {
        await setFieldValue('modelName', metadata.modelName)
      }
      await setFieldValue('modelConfig', metadata.model_config)
      await setFieldValue('toolIds', metadata.skillIds)
      await setFieldValue('indexIds', metadata.contextIds)
      await setFieldValue('variableDefaults', metadata.variableDefaults)

      if (version.action_type === 'chat') {
        await selectTab('chat')
      } else {
        await selectTab('completion')
      }

      toast.info({
        title: 'Version selected',
        description: `Version ${version.version_number} selected`,
      })
    },
    [dispatch, setFieldValue, selectTab, values]
  )

  useEffect(() => {
    if (versionFromUrl) {
      void dispatch({
        payload: versionFromUrl,
        type: 'change-version',
      })
      if (versionFromUrl.id !== version?.id) {
        void setValuesFromVersion(versionFromUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionFromUrl])

  return {
    setValuesFromVersion,
  }
}

export const PublishActionStudio = () => {
  const {
    state: { workspace, action },
  } = useStudio()
  const router = useRouter()
  const { slug } = router.query as Partial<StudioQueryParams>

  if (!workspace || !action) {
    return null
  }
  return <PublishAction workspace={workspace} action={action} appslug={slug || ''} />
}

export const PublishAction = ({
  workspace,
  action,
  appslug,
}: PublishDialogActionProps & {
  setValuesFromVersion?: (version: DetailedActionVersion) => Promise<void>
}) => {
  const { environments } = useFetchEnvironments(workspace.id)
  const { versions } = useFetchActionVersions(action.id)

  const [hasChanges, setHasChanges] = useState(false)
  const [isDeployed, setIsDeployed] = useState(false)

  const { render: renderActionAPIModal, setOpen: setOpenActionAPIModal } = useActionAPIModal()

  const { render: renderActionVersionsModal, setOpen: setOpenActionVersionsModal } =
    useActionVersionsModal()

  useEffect(() => {
    if (!versions || !action) return

    // check if the latest version is the current version
    const latestVersion = versions[0]
    if (!latestVersion) return

    const isDeployed = versions.some((version) => {
      return version.deployments.some((deployment) => {
        return (
          deployment.environment.name == 'Staging' ||
          deployment.environment.name == 'Production' ||
          deployment.environment.name == 'Preview'
        )
      })
    })

    setIsDeployed(isDeployed)

    // check if the latest version is in Production - that means there can't be any changes
    const isLatestVersionDeployed = latestVersion.deployments.some((deployment) => {
      return deployment.environment.name == 'Staging' || deployment.environment.name == 'Production'
    })
    setHasChanges(!isLatestVersionDeployed)
  }, [versions, action])

  const isShiftPressed = (e: KeyboardEvent) => {
    return e.shiftKey
  }
  const isCmdPressed = (e: KeyboardEvent) => {
    return e.metaKey || e.ctrlKey
  }
  const isVPressed = (e: KeyboardEvent) => {
    return e.key === 'v'
  }
  useCmdCallback(() => {
    setOpenActionVersionsModal(true)
  }, [isShiftPressed, isCmdPressed, isVPressed])

  if (!action || !environments) return null

  return (
    <>
      {renderActionAPIModal({
        action,
        workspace,
      })}
      {renderActionVersionsModal({
        action,
        workspace,
        appslug,
      })}

      <div className="inline-flex">
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <div>
              <DeployButton hasChanges={hasChanges} isDeployed={isDeployed} />
            </div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content align="end" className="w-64">
            <DropdownMenu.Label className="font-normal text-grey-500 text-xs">
              Deploy to environment
            </DropdownMenu.Label>

            {environments.map((environment) => {
              return (
                <DeployToEnvironmentButton
                  action={action}
                  workspace={workspace}
                  key={environment.id}
                  environment={environment}
                  versions={versions ? versions : []}
                />
              )
            })}

            {/*             {versions && versions.length > 1 ? (
              <UndeployAllExceptLatest versions={versions} action={action} />
            ) : null} */}

            <DropdownMenu.Separator />
            <DropdownMenu.Item
              onClick={() => setOpenActionVersionsModal(true)}
              className="flex items-center gap-2"
            >
              <File05 className="w-4 h-4" />
              <span>Versions</span>
              <DropdownMenu.Shortcut>&#8679;&#8984;V</DropdownMenu.Shortcut>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => setOpenActionAPIModal(true)}
              className="flex items-center gap-2"
            >
              <Code02 className="w-4 h-4" />
              <span>API</span>
            </DropdownMenu.Item>
            <CopyButton action={action} showIcon />
            {isDeployed && (
              <>
                <DropdownMenu.Separator />
                <DisableButton action={action} workspace={workspace} />
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </>
  )
}
