import type { Workspace } from '@prisma/client'
import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editWorkspaceFormSchema } from './schema'
import type { EditWorkspaceFormInputs } from './types'

export const useEditWorkspaceForm = ({ workspace }: { workspace: Workspace }) => {
  const editWorkspace = api.workspace.edit.useMutation()
  const utils = api.useContext()
  const router = useRouter()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: EditWorkspaceFormInputs = {
      name: workspace.name,
      updatedSlug: workspace.slug,
    }

    return initialValues
  }, [workspace.name, workspace.slug])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Workspace updated',
      description: 'Your workspace was updated successfully',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: EditWorkspaceFormInputs,
      { resetForm }: FormikHelpers<EditWorkspaceFormInputs>
    ) => {
      const updatedWorkspace = await editWorkspace.mutateAsync({
        slug: workspace.slug,
        updatedSlug: values.updatedSlug,
        name: values.name,
      })
      await utils.workspace.get.invalidate({ id: workspace.id })
      await utils.workspace.getBySlug.invalidate({ slug: updatedWorkspace.slug })
      await utils.workspace.getIsMemberOf.invalidate()

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.workspace_edited, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      if (values.updatedSlug !== initialValues.updatedSlug) {
        await router.push(`/${values.updatedSlug}/settings`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editWorkspace,
      user,
      onSuccess,
      utils.workspace.get,
      utils.workspace.getBySlug,
      workspace,
      router,
      initialValues,
    ]
  )

  const formikConfig: FormikConfig<EditWorkspaceFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editWorkspaceFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
