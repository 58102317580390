import type { Action, App } from '@prisma/client'
import { useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { ObserveIcon } from '@/client/assets/icons/icons'
import { Button, Icon, toast } from '@/client/components'
import { useFetchEvalTypes } from '@/common/hooks'
import type { EvalTypeMetadata } from '@/common/types/eval'
import { getEvalIcon } from '@/pages/[workspaceSlug]/apps/[slug]/actions/[actionSlug]/monitors'
import { api } from '@/utils'
import { EvalVariableInput } from '../modals/evals/EvalVariableInput'
import type { EvalSchema } from './schema'
import { evalSchemaDefinition } from './schema'

export function AddMonitorForm({
  app,
  setOpen,
  action,
}: {
  app: App
  setOpen: (val: boolean) => void
  action: Action
}) {
  const createEval = api.action.addEval.useMutation()

  const dataType = 'LIVE'
  const { evalTypes, isLoading: isLoadingEvalTypes } = useFetchEvalTypes({ dataType })

  const [loading, setLoading] = useState(false)
  const utils = api.useContext()

  function onSuccess() {
    toast.success({
      title: 'Monitor added',
      description: 'Successfully added the monitor',
    })
    setOpen(false)
  }

  const formik = useFormik<EvalSchema>({
    initialValues: {
      datasetName: '',
      dataType,
      name: `${action.name} Eval`,
      evalTypes: [],
      datasetId: 0,
      actionId: action.id,
      versionId: undefined,
    },
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema(evalSchemaDefinition),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true)

        const { evalTypes, ...evalValues } = values

        await createEval.mutateAsync({
          ...evalValues,
          evalTypes: evalTypes,
          appId: app.id,
          actionId: action.id,
          workspaceId: app.workspaceId,
        })
        void utils.eval.getAll.invalidate({ appId: app.id })
        void utils.eval.getAllForAction.invalidate({ actionId: action.id })
        setLoading(false)
        onSuccess()
        resetForm()
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    },
  })

  const enabledEvalTypeIds = useMemo(() => {
    return formik.values.evalTypes.map((evalType) => evalType.evalTypeId)
  }, [formik.values.evalTypes])

  const evalTypesWithIcons = useMemo(() => {
    return evalTypes?.map((evalType) => {
      const evalTypeMetadata = evalType.metadata as EvalTypeMetadata

      const icon = getEvalIcon(evalTypeMetadata.icon)

      return {
        id: 0,
        evalTypeId: evalType.id,
        name: evalType.name,
        icon,
        metadata: evalTypeMetadata,
      }
    })
  }, [evalTypes])

  const selectedEvalType = useMemo(() => {
    return evalTypes?.find((evalType) => evalType.id === enabledEvalTypeIds[0])
  }, [evalTypes, enabledEvalTypeIds])

  const selectedEvalTypeMetadata = useMemo(() => {
    return selectedEvalType?.metadata as EvalTypeMetadata
  }, [selectedEvalType])

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="rounded-lg bg-white">
        <div>
          <div className="p-6 text-center sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900 flex gap-2">
              <ObserveIcon className="w-6 h-6 " />
              Monitor and evaluate {action.name}
            </h3>

            <div className="mt-6 mb-3">
              {isLoadingEvalTypes || !evalTypesWithIcons ? (
                <div className="flex animate-pulse space-x-4">
                  <div className="flex-1 space-y-2">
                    <div className="h-2 rounded bg-grey-700"></div>
                    <div className="h-2 rounded bg-grey-700"></div>
                  </div>
                </div>
              ) : (
                <ul className="grid w-full gap-3 md:grid-cols-3 place-items-stretch">
                  {evalTypesWithIcons.map((evalType) => (
                    <li key={evalType.evalTypeId}>
                      <input
                        id={`evalTypeId-${evalType.evalTypeId}`}
                        type="radio"
                        name="evalTypeId"
                        className="hidden peer group"
                        checked={enabledEvalTypeIds.includes(evalType.evalTypeId)}
                        onChange={() => {
                          formik.setFieldValue('evalTypes', [evalType]).catch(console.error)
                        }}
                      />
                      <label
                        htmlFor={`evalTypeId-${evalType.evalTypeId}`}
                        className="relative inline-flex items-center justify-between w-full h-full p-5 text-grey-700 bg-white border border-grey-200 dark:border-zinc-800 hover:border-grey-300 rounded-lg cursor-pointer dark:hover:text-grey-300 dark:border-zinc-800 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-grey-800 hover:bg-grey-50 dark:text-zinc-500 dark:bg-grey-800 dark:hover:bg-zinc-900"
                      >
                        <div className="block">
                          <div className="w-full text-md font-semibold flex gap-1 items-center">
                            {evalType.icon && (
                              <Icon
                                className="w-5 h-5 mr-2"
                                component={evalType.icon}
                                size={'xs'}
                              />
                            )}
                            {evalType.name}
                          </div>
                          <div className="w-full mt-2">{evalType.metadata.description}</div>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {selectedEvalType &&
              selectedEvalTypeMetadata?.variables?.map((definition, i) => (
                <EvalVariableInput
                  key={`${selectedEvalType.id}-variable-${definition.name}-${i}`}
                  definition={definition}
                  evalTypeId={formik.values.evalTypes[0]?.evalTypeId || 0}
                  evalTypeIndex={0}
                  variableIndex={i}
                  formik={formik}
                />
              ))}
            {/* <FieldError fieldName="evalTypes" formik={formik} /> */}
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 dark:bg-black sm:flex sm:px-6">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button disabled={loading} type="submit" loading={loading}>
          {loading ? 'Saving...' : 'Add Monitor'}
        </Button>
      </div>
    </form>
  )
}
