import { useFormik, type FormikConfig } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editVersionEnvironmentFormSchema } from './schema'
import type { EditVersionEnvironmentFormInputs, UseEditVersionEnvironmentFormProps } from './types'

export const useEditVersionEnvironmentForm = ({
  action,
  version,
  workspace,
  onIsOpenChange,
}: UseEditVersionEnvironmentFormProps) => {
  const utils = api.useContext()
  const deployVersion = api.action.deployVersion.useMutation()
  const undeployVersion = api.action.undeployVersion.useMutation({
    onSuccess: () => {
      toast.success({
        title: `${version.name || 'Version'} deactivated`,
        description: 'Your version was deactivated successfully',
      })
    },
    onError: (err) => {
      toast.error({
        title: 'Error removing version from environment',
        description: err.message,
      })
    },
    onSettled: () => {
      void utils.action.getBySlug.invalidate({ slug: action.slug })
      void utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })
    },
  })

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: EditVersionEnvironmentFormInputs = {
      environmentIds: version.deployments.map((deployment) => deployment.environmentId),
    }

    return initialValues
  }, [version.deployments])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: `${version.name || 'Version'} deployed`,
      description: 'Your version was deployed successfully',
    })
  }, [version.name])

  const onSubmit = React.useCallback(
    async (
      values: EditVersionEnvironmentFormInputs
      // { resetForm }: FormikHelpers<EditVersionEnvironmentFormInputs>
    ) => {
      await deployVersion.mutateAsync({
        actionId: action.id,
        versionId: version.id,
        ...values,
      })
      await utils.action.getBySlug.invalidate({ slug: action.slug })
      await utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.action_version_deployed, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Action ID': action.id,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      onSuccess()
      onIsOpenChange(true)
    },
    [
      deployVersion,
      action.id,
      action.slug,
      version.id,
      utils.action.getBySlug,
      utils.actionVersion.getAllForAction,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const onUndeploy = React.useCallback(
    (environmentId: number) => {
      undeployVersion.mutate({
        actionId: action.id,
        versionId: version.id,
        environmentId,
      })
      onIsOpenChange(true)
    },
    [undeployVersion, action.id, version.id, onIsOpenChange]
  )

  const formikConfig: FormikConfig<EditVersionEnvironmentFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editVersionEnvironmentFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
    onUndeploy,
  }
}
