import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { ChevronRight } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import type { DropdownMenuSubTriggerProps } from './types'

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  DropdownMenuSubTriggerProps
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none focus:bg-grey-100 data-[state=open]:bg-grey-100 dark:focus:bg-zinc-900 dark:data-[state=open]:bg-zinc-900',
      inset && 'pl-8',
      className
    )}
    {...props}
  >
    {children}

    <Icon className="ml-auto" size="sm" component={ChevronRight} />
  </DropdownMenuPrimitive.SubTrigger>
))

DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName

export { DropdownMenuSubTrigger }
