import type { ToolType } from '@prisma/client'
import type { FieldProps, FormikProps } from 'formik'
import { Field, Formik, Form as FormikForm } from 'formik'
import React, { useMemo, useState } from 'react'
import { Button, Combobox, Input } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { useFetchToolTypes } from '@/common/hooks'
import { useFetchDatabaseProviders } from '@/common/hooks/connections'
import useCreateDatabaseProviderModal from '../../../databases/CreateDatabaseProvider/Modal'
import { SkillMetadataForm } from '../../common/metadata-form'
import { useCreateSkillForm } from './form'
import type { CreateSkillFormInputs, FormProps } from './types'

const SQL_QUERY_TOOL_TYPE_ID = 20
const SKILL_TYPES = [
  // 18, //Check website - should be klu_provided
  20, //SQL Query
  21, //API Request
  23, //OpenAiFunction
  // 17, //Code Interpreter - should be klu_provided
  // 24, //Image Generator
]

export function shouldShowSkillDescription(typeId: number | undefined) {
  if (!typeId) {
    return false
  }

  return SKILL_TYPES.includes(typeId)
}

export const Form = ({ workspace, onIsOpenChange, setNewSkill }: FormProps) => {
  const { formikConfig, saveDisabled, setRequiredMetadata } = useCreateSkillForm({
    workspace,
    onIsOpenChange,
    setNewSkill,
  })
  const [selectedToolType, setSelectedToolType] = useState<ToolType | null>(null)
  const { toolTypes } = useFetchToolTypes()
  const { providers } = useFetchDatabaseProviders(workspace.id)

  const { render: renderDatabaseProviderModal, setOpen: setShowDatabaseProviderCreationModal } =
    useCreateDatabaseProviderModal({ workspace })

  const toolTypeOptions = useMemo(() => {
    if (!toolTypes) {
      return []
    } else {
      return toolTypes
        .filter((toolType) => SKILL_TYPES.includes(toolType.id))
        .map((toolType) => ({
          value: String(toolType.id),
          label: toolType.name,
        }))
    }
  }, [toolTypes])

  async function onChangeSkillType(value: string, formik: FormikProps<CreateSkillFormInputs>) {
    {
      if (!value || !toolTypes) return
      const updatedToolType = toolTypes.find((types) => types.id === parseInt(value))!

      setSelectedToolType(updatedToolType)
      await formik.setFieldValue('typeId', parseInt(value))
      await setRequiredMetadata(updatedToolType.name, formik, toolTypes)
    }
  }

  return (
    <React.Fragment>
      {renderDatabaseProviderModal()}
      <Formik
        initialValues={formikConfig.initialValues}
        onSubmit={formikConfig.onSubmit}
        validationSchema={formikConfig.validationSchema}
        validateOnMount={false}
      >
        {(formik) => (
          <FormikForm>
            <div className="flex flex-col p-6 gap-6">
              <div>
                <Combobox.Single
                  label={''}
                  classNameContent="bg-white text-sm"
                  options={toolTypeOptions}
                  value={selectedToolType?.id.toString() || undefined}
                  placeholder="Select Skill type"
                  onChange={(value) => {
                    void onChangeSkillType(value, formik)
                    const selectedToolType = toolTypes?.find((type) => String(type.id) === value)
                    if (selectedToolType) {
                      void formik.setFieldValue('name', selectedToolType.name, false)
                    }
                  }}
                />
                <FieldError fieldName="typeId" formik={formik} />
              </div>

              {selectedToolType && selectedToolType.id === SQL_QUERY_TOOL_TYPE_ID && (
                <>
                  <div>
                    <Input
                      id="name"
                      name="name"
                      label="Name"
                      placeholder="Database Reader"
                      message={formik.errors.name}
                      hasError={!!formik.errors.name}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div>
                    <Field name={'databaseProviderId'}>
                      {({ field }: FieldProps) => {
                        return (
                          <>
                            <Combobox.Single
                              classNameContent="bg-white text-sm"
                              label="Connection"
                              options={
                                providers
                                  ? providers.map((provider) => {
                                      const metadata = provider.metadata as {
                                        provider: string
                                        config: Record<string, string>
                                      }
                                      return {
                                        value: provider.id.toString(),
                                        label: `${provider.name} (${metadata.provider})`,
                                      }
                                    })
                                  : []
                              }
                              {...field}
                              onChange={(newValue) => {
                                if (!newValue) return
                                void formik.setFieldValue(field.name, newValue)
                              }}
                              onSelectCreate={() => setShowDatabaseProviderCreationModal(true)}
                              placeholder="Select a provider"
                            />
                            <FieldError fieldName="databaseProviderId" formik={formik} />
                          </>
                        )
                      }}
                    </Field>
                  </div>
                </>
              )}

              {providers && selectedToolType && (
                <SkillMetadataForm toolType={selectedToolType} formik={formik} />
              )}

              <div className="flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 -mx-6">
                {onIsOpenChange && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      onIsOpenChange(false)
                    }}
                  >
                    Close
                  </Button>
                )}

                <Button type="submit" disabled={saveDisabled || !formik.isValid}>
                  Add
                </Button>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}
