import { useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { toast } from '@/client/components'
import { parseJSONPreprocessor } from '@/utils/zod'
import {
  MODELS_WITH_JSON_MODE,
  MODELS_WITH_STRUCTURED_OUTPUT,
} from '../../Tabs/Chat/hooks/useIsStructuredOutputSupported'
import { messageSchema } from '../../Tabs/Chat/schemas'
import type { StudioFormikKeys, StudioFormInputs } from '../types'

export const useStudioFormikContext = () => {
  const [showFullPromptSent, setShowFullPromptSent] = useState(false)

  const { setFieldValue: originalSetFieldValue, ...restFormikContext } =
    useFormikContext<StudioFormInputs>()

  const setFieldValue = <Key extends StudioFormikKeys>(
    field: Key,
    value: any, //TODO change to proper type https://github.com/jaredpalmer/formik/issues/1388#issuecomment-1862742795
    shouldValidate?: boolean
  ) => {
    return originalSetFieldValue(field, value, shouldValidate)
  }

  function userMessageHasImage(inputArray: Array<z.infer<typeof messageSchema>>) {
    for (const obj of inputArray) {
      if (obj.role === 'user' && obj.files && obj.files.length > 0) return true
    }
    return false
  }

  const submitStudioForm = (
    opts:
      | {
          isVisionSupported?: boolean
        }
      | undefined
  ) => {
    try {
      /** Parse checking validation, throws error if mismatch */
      z.preprocess(parseJSONPreprocessor, z.array(messageSchema)).parse(
        restFormikContext.values.chat.sessionMessages
      )
    } catch (e) {
      const error = e instanceof Error ? e.message : JSON.stringify(e)
      void restFormikContext.setFieldError('chat.sessionMessages', error)
      console.error('Error while generating', error)
      toast.error({
        title: 'Error while generating',
        description: error,
      })
      return
    }

    void setFieldValue('full_prompt_sent', undefined, false)
    const noEmptyMessages = restFormikContext.values.chat.sessionMessages?.filter((m) => {
      if (m.role === 'user') {
        return m.content && m.content.trim() !== ''
      }
      return true
    })

    if (!noEmptyMessages) return restFormikContext.handleSubmit()

    const haveImage = userMessageHasImage(noEmptyMessages)

    if (!opts?.isVisionSupported && haveImage) {
      const messageWithNoImages = noEmptyMessages.map((m) => {
        if (m.role !== 'user') return m
        return {
          ...m,
          files: [],
        }
      })
      void setFieldValue('chat.sessionMessages', messageWithNoImages)
    } else {
      void setFieldValue('chat.sessionMessages', noEmptyMessages)
    }

    restFormikContext.handleSubmit()
  }
  // we mainly want to ignore changes in variable values
  const dirty = useMemo(() => {
    return (
      restFormikContext.values.document.template !==
        restFormikContext.initialValues.document.template ||
      JSON.stringify(restFormikContext.values.completion.messages) !==
        JSON.stringify(restFormikContext.initialValues.completion.messages) ||
      restFormikContext.values.modelConfig !== restFormikContext.initialValues.modelConfig ||
      restFormikContext.values.modelName !== restFormikContext.initialValues.modelName ||
      restFormikContext.values.workspaceModelProviderId !==
        restFormikContext.initialValues.workspaceModelProviderId ||
      JSON.stringify(restFormikContext.values.indexIds) !==
        JSON.stringify(restFormikContext.initialValues.indexIds) ||
      JSON.stringify(restFormikContext.values.chat.templateMessages) !==
        JSON.stringify(restFormikContext.initialValues.chat.templateMessages) ||
      JSON.stringify(restFormikContext.values.toolIds) !==
        JSON.stringify(restFormikContext.initialValues.toolIds) ||
      restFormikContext.values.outputFormat !== restFormikContext.initialValues.outputFormat ||
      JSON.stringify(restFormikContext.values.outputInstructions) !==
        JSON.stringify(restFormikContext.initialValues.outputInstructions)
    )
  }, [restFormikContext.initialValues, restFormikContext.values])

  useEffect(() => {
    setShowFullPromptSent(false)
  }, [
    restFormikContext.values.chat.variableValues,
    restFormikContext.values.document.variableValues,
    restFormikContext.values.document.template,
    restFormikContext.values.completion.messages,
    restFormikContext.values.chat.templateMessages,
  ]) //anytime the template changes, hide the full prompt sent

  useEffect(() => {
    if (restFormikContext.values.full_prompt_sent) setShowFullPromptSent(true)
  }, [restFormikContext.values.full_prompt_sent]) // if the full prompt sent is set, show the full prompt sent

  // correctly set the response format based on the model
  // if the model does not support json mode, set the response format to undefined
  // if the model does not support structured output, set the response format and json_schema to undefined
  useEffect(() => {
    const { modelName } = restFormikContext.values
    const isJsonModeSupported = Boolean(
      MODELS_WITH_JSON_MODE.some((model) => modelName.includes(model))
    )
    const isStructuredOutputSupported = Boolean(
      MODELS_WITH_STRUCTURED_OUTPUT.some((model) => modelName.includes(model))
    )

    if (!isJsonModeSupported) {
      void setFieldValue('modelConfig.responseFormat', 'text', false)
      void setFieldValue('modelConfig.json_schema', undefined, false)
    }

    if (!isStructuredOutputSupported) {
      if (restFormikContext.values.modelConfig.responseFormat == 'json_schema') {
        void setFieldValue('modelConfig.responseFormat', 'text', false)
      }
      void setFieldValue('modelConfig.json_schema', undefined, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restFormikContext.values.modelName])

  useEffect(() => {
    if (
      window.location.href.includes('localhost') &&
      restFormikContext.errors &&
      Object.keys(restFormikContext.errors).length > 0
    ) {
      console.error('Studio form errors:', restFormikContext.errors)
      console.log('Studio form values:', restFormikContext.values)
    }
  }, [restFormikContext.errors, restFormikContext.values])

  return {
    ...restFormikContext,
    submitStudioForm,
    dirty,
    setFieldValue,
    showFullPromptSent: showFullPromptSent && restFormikContext.values.full_prompt_sent,
  }
}
