import { api } from '@/utils'
import type { DetailedEvalRunItem } from '../types/eval'
import { useUser } from './user'

export function useFetchEvals(appId: number, workspaceId: number, dataType?: 'LIVE' | 'TEST') {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getAll.useQuery(
    { appId, workspaceId, dataType },
    {
      enabled: user !== undefined,
    }
  )
  return {
    evals: data,
    isLoading,
    error,
  }
}

export function useFetchEval(evalId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.get.useQuery(
    { id: evalId, workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    eval: data,
    isLoading,
    error,
  }
}

export function useFetchEvalBySlug(evalSlug: string, workspaceSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getBySlug.useQuery(
    { slug: evalSlug, workspaceSlug },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    eval: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunItemsForEvalPaginated({
  evalId,
  evalRunId,
  sinceDays,
  page,
  perPage,
}: {
  evalId: number
  evalRunId?: number
  sinceDays?: number
  page: number
  perPage: number
}): {
  totalCount?: number
  evalRunItems?: DetailedEvalRunItem[]
  isLoading: boolean
  error: any
} {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRunItem.getAllForEvalPaginated.useQuery(
    { evalId, sinceDays, page, perPage, evalRunId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    totalCount: data && data.total,
    evalRunItems: data && data.evalRunItems,
    isLoading,
    error,
  }
}

export function useFetchEvalResultsByPeriod({
  evalId,
  days,
  groupByPeriod,
}: {
  evalId: number
  days: number
  groupByPeriod?: 'hour' | 'day' | 'week' | 'month' | 'lifetime'
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getResultsByPeriod.useQuery(
    { evalId, days, groupByPeriod },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    data: data,
    isLoading,
    error,
  }
}

export function useFetchEvalResultsByRun({ evalId }: { evalId: number }) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getResultsByRun.useQuery(
    { evalId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    data: data,
    isLoading,
    error,
  }
}

export function useFetchLatestEvalRunResults({ evalId }: { evalId: number }) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getResultsLatestRun.useQuery(
    { evalId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    data: data,
    isLoading,
    error,
  }
}

export function useFetchEvalTypesForEval({ evalId }: { evalId: number }) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getEvalTypes.useQuery(
    { evalId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    data: data,
    isLoading,
    error,
  }
}

export function useFetchEvalResultsByDatasetItem({
  evalId,
  evalRunIds,
}: {
  evalId: number
  evalRunIds?: number[]
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.eval.getResultsByDatasetItem.useQuery(
    { evalId, evalRunIds },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )

  return {
    results: data,
    isLoading,
    error,
  }
}
