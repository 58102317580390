import { Button, Input } from '@/client/components'
import { useEditProfileForm } from './Form/form'
import type { FormProps } from './Form/types'

export const ProfileEditForm = ({ workspace, user }: FormProps) => {
  const { formik, saveDisabled } = useEditProfileForm({
    workspace,
    user,
  })
  const { values, errors, handleChange, handleSubmit, isSubmitting } = formik

  return (
    <div className="flex flex-col space-y-6 max-w-lg">
      <Input
        id="name"
        name="name"
        label="Full Name"
        message={errors.name}
        hasError={!!errors.name}
        value={values.name}
        onChange={handleChange}
        placeholder="Tom Cruise"
      />

      <div>
        <Button
          onClick={() => {
            handleSubmit()
          }}
          variant="outline"
          disabled={isSubmitting || saveDisabled}
          loading={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  )
}
