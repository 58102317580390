import { z } from 'zod'
import { messageSchema } from '@/client/containers/views/Studio/components/Tabs/Chat/schemas'
import { fullPromptSentSchema } from '@/server/engine/studio'
import { openAIModelOutputTokenLimits } from '@/utils'

export const logitBiasItemSchema = z.object({
  tokenId: z.string(),
  biasValue: z.number().min(-100).max(100),
})

export const modelConfigFormSchema = z.object({
  temperature: z.number({ required_error: 'Temperature is required' }),
  maxResponseLength: z.number({ required_error: 'Max response length is required' }),
  stopSequence: z.string().array().nullable(),
  topP: z.number({ required_error: 'Top P is required' }),
  presencePenalty: z.number({ required_error: 'Presence penalty is required' }),
  frequencyPenalty: z.number({ required_error: 'Frequence penalty is required' }),
  numRetries: z.number({ required_error: 'Request retries is required' }),
  timeout: z.number({ required_error: 'LLM request timeout is required' }),
  seed: z.number().nullable(),
  responseFormat: z.enum(['text', 'json', 'json_schema']).nullable(),
  json_schema: z.string().optional(),
  logitBias: z.array(logitBiasItemSchema).default([]),
  parallel_tool_calls: z.boolean().default(true).optional().nullable(),
})

export const variableValuesSchema = z.record(z.string(), z.string().optional().default(''))

export const chatSchema = z.object({
  templateMessages: z.array(messageSchema).optional(),
  sessionMessages: z.array(messageSchema).optional(),
  sessionId: z.number().optional(),
  variableValues: variableValuesSchema.optional(), //rename to chatVariableValues
  // variableDefaults: variableValuesSchema.optional(),
  files: z.array(z.string().url()).optional(),
})

export const studioFormSchema = z
  .object({
    // Document Tab
    document: z.object({
      template: z.string().optional(),
      variableValues: variableValuesSchema,
      response: z
        .object({
          dataGuid: z.string().optional(),
          value: z.string().optional(),
        })
        .optional(),
    }),

    // Completion Tab
    completion: z.object({
      promptTemplate: z.string().optional(),
      systemMessage: z.string().optional(),
      messages: z.array(messageSchema).optional(),
      prompts: z.array(
        z.object({
          files: z.array(z.string().url()).optional(),
          variableValues: variableValuesSchema,
          // variableDefaults: variableValuesSchema.optional(),
          response: z
            .object({
              dataGuid: z.string().optional(),
              value: z.string().optional(),
            })
            .optional(),
        })
      ),
    }),
    // Common attributes
    outputInstructions: z.string().optional(),
    outputFormat: z.string().optional(),
    workspaceModelProviderId: z.string({ required_error: 'Provider is required' }),
    modelName: z.string({ required_error: 'Model is required' }),
    modelConfig: modelConfigFormSchema,
    toolIds: z.number().array().optional(),
    indexIds: z.number().array().optional(),
    shouldPersistPrompt: z.boolean(),
    versionNumber: z.number().optional(),
    variableDefaults: variableValuesSchema.optional(),
    full_prompt_sent: z.array(fullPromptSentSchema).optional(),

    textToSpeechSettings: z
      .object({
        model: z.string().optional(),
        voice: z.string().optional(),
        speed: z.number().min(0.25).max(4).default(1),
        autoplay: z.boolean().optional().default(false),
      })
      .optional(),

    // Chat Tab
    chat: chatSchema,

    compare: z.object({
      isActive: z.boolean(),
      tab: z.object({
        chat: z.array(
          z.object({
            ...chatSchema.shape,

            newMessage: z
              .object({
                role: z.enum(['user', 'tool']),
                content: messageSchema.shape.content,
                tool_call_id: messageSchema.shape.tool_call_id,
              })
              .optional(),

            workspaceModelProviderId: z.string({ required_error: 'Provider is required' }),
            modelName: z.string({ required_error: 'Model is required' }),
            modelConfig: modelConfigFormSchema,
            toolIds: z.number().array().optional(),
            indexIds: z.number().array().optional(),

            isSynced: z.boolean(),
          })
        ),
      }),
    }),
  })
  .superRefine((data, ctx) => {
    const { modelName, modelConfig } = data
    const maxTokenLimit = openAIModelOutputTokenLimits[modelName] || 16384
    if (modelConfig && modelConfig.maxResponseLength > maxTokenLimit) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: maxTokenLimit,
        type: 'number',
        inclusive: true,
        message: `maxResponseLength (${modelConfig.maxResponseLength}) exceeds the maximum allowed tokens (${maxTokenLimit}) for model ${modelName}`,
        path: ['modelConfig', 'maxResponseLength'],
      })
    }
  })
