import { InfoCircle } from '@untitled-ui/icons-react'
import { Tooltip } from '@/client/components'

export const InfoTooltip = ({
  icon,
  children,
}: {
  icon?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger>
        {icon || <InfoCircle className="h-4 w-4 text-grey-500 dark:text-zinc-500" />}
      </Tooltip.Trigger>
      <Tooltip.Content className="max-w-[250px] bg-white text-xs">{children}</Tooltip.Content>
    </Tooltip.Root>
  )
}
