import type { Workspace } from '@prisma/client'
import { useFormik, type FormikConfig } from 'formik'
import type { User } from 'next-auth'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { editProfileFormSchema } from './schema'
import type { EditProfileFormInputs } from './types'

export const useEditProfileForm = ({ user, workspace }: { user: User; workspace: Workspace }) => {
  const [initialValues, setInitialValue] = React.useState<EditProfileFormInputs>({
    name: user.name ?? '',
  })
  const editProfile = api.user.edit.useMutation()

  const onSuccess = React.useCallback((userName: string) => {
    toast.success({
      title: 'Profile updated',
      description: `Your profile was updated to ${userName}`,
    })
  }, [])

  const onSubmit = React.useCallback(
    async (values: EditProfileFormInputs) => {
      try {
        setInitialValue(values)

        await editProfile.mutateAsync({ name: values.name })

        if (user) {
          const id = user.id ? user.id : ''
          const email = user.email ? user.email : ''
          const uname = user.name ? user.name : ''
          identify_and_group(id, email, uname, workspace?.slug)
        }
        track(EVENT_NAMES.workflow_edited, {
          'Workspace ID': workspace.id,
          'Worspace Name': workspace.name,
          'User ID': user?.id,
          'User Email': user?.email,
          'User Name': user?.name,
        })

        onSuccess(values.name)
      } catch (e) {
        setInitialValue({ name: user.name ?? '' })
        console.error(e)
      }
    },
    [editProfile, user, onSuccess, workspace]
  )

  const formikConfig: FormikConfig<EditProfileFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(editProfileFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    saveDisabled,
  }
}
