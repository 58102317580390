import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components'
import { cn } from '@/client/utils'
import type { CheckboxProps } from './types'

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={`
        focus-visible:shadow-primary/[.20]
        ${cn(
          `
            data-[state=checked]:hover:bg-primary-lightest-1
            peer
            h-4
            w-4
            shrink-0
            rounded
            border
            border-grey-700
            hover:border-primary
            focus-visible:border-primary
            focus-visible:shadow-focus
            focus-visible:outline-none
            disabled:!border-grey-500
            disabled:!bg-grey-300
            disabled:!text-grey-500
            data-[state=checked]:border-primary
            data-[state=checked]:text-primary
          `,
          className
        )}`}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <Icon size="xs" component={Check} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
)

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
