import { CodeDocsIcon } from '@/client/assets/icons/icons'
import { Button, Icon } from '@/client/components'
import { useCurrentAction } from '@/common/hooks'
import type { ActionsProps } from './types'

export const Actions = ({ appId, onIsOpenChange, actions, onActionOpened }: ActionsProps) => {
  const { action: currentAction } = useCurrentAction()

  return (
    <div className="mt-1 flex flex-col gap-1 pr-4">
      {actions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((action) => {
          return (
            <Button
              key={action.id}
              className="gap-3 rounded px-2 py-2 hover:bg-grey-100"
              size="sm"
              variant="ghost"
              onClick={() => {
                if (currentAction?.id === action.id) {
                  onIsOpenChange(false)
                  return
                }
                void onActionOpened(action, appId)
                onIsOpenChange(false)
              }}
            >
              <div className="flex shrink-0 grow basis-0 items-center gap-3">
                <Icon className="shrink-0" size="sm" component={CodeDocsIcon} />

                <p className={'text-left font-inter text-grey-800 dark:text-zinc-500 font-light'}>
                  {action.name}
                </p>
              </div>

              <p className="font-regular uppercase font-mono text-xs">
                {action.model.workspaceModelProvider.provider.name} {action.model.llm}
              </p>
            </Button>
          )
        })}
    </div>
  )
}
