import * as snippet from '@segment/snippet';
import { type Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { type AppType } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';
import { Toaster, Tooltip } from '@/client/components';
import { RouteHistoryProvider } from '@/client/context/routeHistory';
import { env } from '../env.mjs';
import '../styles/globals.css';
import '../styles/handlebars_syntax.scss';
import '../styles/react_query_builder.scss';
import { ThemeProvider } from '@/common/components/ui/context';
import { api } from '../utils/api';
import { analytics } from '../utils/segment';
function renderSnippet() {
  const opts = {
    apiKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    page: true
  };
  return snippet.min(opts);
}
function ProductionWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  React.useEffect(() => {
    const handleRouteChange = () => {
      void analytics.page();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
  return <>
      {children}
      <Script id="segment-script" dangerouslySetInnerHTML={{
      __html: renderSnippet()
    }} />
    </>;
}
const MyApp: AppType<{
  session: Session | null;
}> = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}) => {
  return <ProductionWrapper>
      <ThemeProvider>
        <SessionProvider session={session}>
          <RouteHistoryProvider>
            <Toaster />
            <Tooltip.Provider delayDuration={100}>
              <Component {...pageProps} />
            </Tooltip.Provider>
          </RouteHistoryProvider>
        </SessionProvider>
      </ThemeProvider>
    </ProductionWrapper>;
};
export default api.withTRPC(MyApp);