import { ThumbsDown, ThumbsUp } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import type { FeedbackSwitcherProps } from './types'

const commonButtonStyles = 'flex items-center justify-center gap-2 p-2'

const commonButtonColors =
  'bg-white 0 text-grey-500 hover:bg-grey-50 hover:text-grey-800 dark:bg-black text-grey-400 dark:hover:bg-zinc-900 dark:hover:text-grey-50'

const FeedbackSwitcher = React.forwardRef<HTMLDivElement, FeedbackSwitcherProps>(
  ({ className, value, onChange }, ref) => {
    const like = !!value
    const dislike = value !== undefined && !value

    const handleOnChange = (newValue: boolean) => {
      if (!onChange) {
        return
      }

      if (value === newValue) {
        onChange(undefined)

        return
      }

      onChange(newValue)
    }

    return (
      <div
        className={cn(
          'flex w-fit overflow-hidden rounded-lg border border-grey-300 hover:border-grey-800 dark:border-zinc-800 dark:hover:border-white/[.92]',
          { 'border-success': like },
          { 'border-error': dislike },
          className
        )}
        ref={ref}
      >
        <button
          className={cn(
            commonButtonStyles,
            like
              ? 'bg-success-lightest-1 hover:bg-success-lightest-2 dark:hover:bg-success-dark text-success dark:bg-success dark:text-white'
              : commonButtonColors
          )}
          onClick={() => {
            handleOnChange(true)
          }}
        >
          <Icon size="sm" component={ThumbsUp} />
        </button>

        <button
          className={cn(
            commonButtonStyles,
            dislike
              ? 'bg-error-lightest-1 hover:bg-error-lightest-2 dark:hover:bg-error-dark text-error dark:bg-error dark:text-white'
              : commonButtonColors
          )}
          onClick={() => {
            handleOnChange(false)
          }}
        >
          <Icon size="sm" component={ThumbsDown} />
        </button>
      </div>
    )
  }
)

FeedbackSwitcher.displayName = 'FeedbackSwitcher'

export { FeedbackSwitcher }
