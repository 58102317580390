import type { Dataset, Finetune, User } from '@prisma/client'
import { z } from 'zod'
import type { chatSchema } from '@/client/containers/views/Studio/components/Formik/schemas'
import type { AssistantMessage } from '@/common/types/messages'

export type SingleAppMetadataCache = {
  actionCount: number | null
  datapointCount: number | null
  lastRequest: string | null
}

// a map of appId to SingleAppMetadataCache
export type AppMetadataCache = Record<number, SingleAppMetadataCache>

export type SingleContextMetadataCache = {
  documentCount: number | 0
  contextSourceCount: number | null
  lastRequest: string | null
  files: string[] | null
  batchJobIds?: number[] | null
  // loader_config: {
  //   name: string
  //   value: string
  //   required?: boolean
  // }[]
}

export type InsightDataCountRow = {
  name: Date | null
  value: number | null
}

export type InsightCategoryRow = {
  category: string
}

export type InsightTopicCategoryRow = {
  category: string | null
  topic: string | null
  count: number
}

export type InsightTopicCategoryResult = {
  name: string
  category: string
  value: number
}

export type InsightHelpfulnessRow = {
  helpfulness: string | null
  count: number
}
export type InsightSentimentRow = {
  sentiment: string | null
  count: number
}

export type InsightModerationRow = {
  moderation: string | null
  count: number
}

export type ContextMetadataCache = Record<string, SingleContextMetadataCache>

export const ContextSplitterConfigSchema = z.object({
  chunkSize: z.number().min(50).max(4000),
  chunkOverlap: z.number().min(0).max(100),
  // splitter: z.string().optional().default('sentence'),
  separator: z.string().optional().default(' '),
  // code_language: z.string().optional().default('python'),
})
export type ContextSplitterConfig = z.infer<typeof ContextSplitterConfigSchema>

export const ContextSourceSplitterConfigSchema = z.object({
  splitter: z.string().optional().default('sentence'),
  separator: z.string().optional().default(' '),
  // code_language: z.string().optional().default('python'),
})
export type ContextSourceSplitterConfig = z.infer<typeof ContextSourceSplitterConfigSchema>

export const ContextSourceMetadataSchema = z.object({
  documentsCount: z.number().nullable(),
  documentsCreatedCount: z.number().nullable(),
  documentsEmbeddedCount: z.number().nullable(),
  task_id: z.string().optional(),
})

export type ContextSourceMetadata = z.infer<typeof ContextSourceMetadataSchema>

export type SingleActionMetadataCache = {
  skillCount: number | null
  contextCount: number | null
  datapointCount: number | null
  lastRequest: string | null
  variables?: string[] | null
  variableDefaults?: Record<string, string | undefined> | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outputVariables?: Record<string, any> | null // OutputFormat type
  deployments: {
    environment: string
    environmentId: number
    versionId: number
  }[]
}

// a map of actionId to SingleActionMetadataCache
export type ActionMetadataCache = Record<number, SingleActionMetadataCache>

export type ActionVersionMetadata = {
  variables?: string[] | null
  variableDefaults?: Record<string, string | undefined> | null
  contextIds: number[] | null
  skillIds: number[] | null
  outputFormat?: string
  outputInstructions?: string
  systemMessage?: string | null
  modelId?: number
  modelName?: string
  model_config: Record<string, string | number | string[] | object | null | boolean> | null
  dataPointCount: number
  lastRequest: string | null
  workspaceModelProviderId?: number
  chat: z.infer<typeof chatSchema> | null
}

export type DataMetadata = {
  source: string
  inputCost?: number
  outputCost?: number
  generationCost?: number
  model_config: any
  error_log?: string
  debug_log?: string
  environment?: string
}

export type WorkflowMetadata = {
  blockCount: number | null
  lastRequest: string | null
  values?: Record<string, any> | null
  studioState?: Record<string, any> | null
}

export type DatasetMetadata = {
  dataCount: number | null
  dataCountForFinetune: number | null
  tokenSumCount: number | null
  batchJobId?: number
}

export type DatasetCuratorMetadata = {
  lastRunAt?: string | null
  batchJobIds?: number[]
  enabled?: boolean
  runHistory?: {
    id: string
    batchJobIds: number[]
    runAt: Date
    dataMatchedCount: number
    message?: string
    datasetItemsAddedCount: number
    datasetItemsCount: number
    datasetLimitReached?: boolean
  }[]
}

export type DatasetCuratorFileSourceConfig = {
  chunkSize: number
  textSplitter: string
  fileSourceAttributeTarget: 'input' | 'output'
}

export type DetailedDataset = Dataset & {
  createdBy: User
  finetunes: Finetune[]
}

export type ActionInputs = Record<string, any>

export type WorkspaceModelProviderMetadata = {
  keys?: any
  lastRequest?: string
  isGlobalKluProvider?: boolean
  isGlobalEmbeddingProvider?: boolean
  lastError?: string
  lastErrorTime?: string
  lastErrorCode?: number
  lastErrorHeaders?: any
}
export type ContextDocumentSearchResult = {
  id: number
  guid: string
  content: string
  metadata: Record<string, unknown>
  score: number
  updatedAt: Date
  createdAt: Date
}

export type ContextPromptResult = {
  message?: AssistantMessage | null
  sourceNodes: ContextDocumentSearchResult[]
}
