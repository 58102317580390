import type { Environment } from '@prisma/client'
import { Button, DropdownMenu } from '@/client/components'
import type { DetailedActionVersion } from '@/common/components/actions/types'
import { useDeployAction } from './Form/form'
import type { DeployActionButtonProps, UseDeployActionEnvironmentFormProps } from './Form/types'

export function DeployButton({ hasChanges, isDeployed }: DeployActionButtonProps) {
  const buttonLabel = (
    <>
      {isDeployed ? (
        <>
          <div className="h-2 w-2 rounded-full bg-success-200 ring-1 ring-green-500 dark:ring-grey-800 ring-offset-1 ring-offset-white" />
          <span className="transition ease-in-out duration-400 group-hover:block whitespace-nowrap">
            {hasChanges ? 'Deploy latest' : 'Deployed'}
          </span>
        </>
      ) : (
        <>
          <div className="h-2 w-2 rounded-full bg-grey-200 ring-1 ring-grey-400 dark:ring-grey-800 ring-offset-1 ring-offset-white" />
          <span>Deploy</span>
        </>
      )}
    </>
  )

  return (
    <>
      <Button
        variant="outline"
        className="px-6 relative group inline-flex items-center rounded-l-md rounded-r-md gap-2 focus:z-10 hover:z-10"
      >
        {buttonLabel}
      </Button>
    </>
  )
}

export const useDeployVersion = ({
  versions,
  environment,
}: {
  environment: Environment
  versions: DetailedActionVersion[]
}) => {
  function isDeployed() {
    return versions.some((version) => {
      return version.deployments.some((deployment) => {
        return deployment.environment.name == environment.name
      })
    })
  }

  function getDeployedVersions() {
    return versions.filter((version) => {
      return version.deployments.some((deployment) => {
        return deployment.environment.id === environment.id
      })
    })
  }

  const deployed = isDeployed()
  const deployedVersions = getDeployedVersions()

  return {
    isDeployed: deployed,
    deployedVersions,
  }
}

export function DeployToEnvironmentButton({
  workspace,
  action,
  environment,
  versions,
}: UseDeployActionEnvironmentFormProps & { versions: DetailedActionVersion[] }) {
  const { formik } = useDeployAction({
    workspace,
    action,
  })

  const { isDeployed, deployedVersions } = useDeployVersion({ versions, environment })

  const { handleSubmit } = formik

  async function handleDeployToEnvironment() {
    await formik.setFieldValue('environmentId', environment.id)
    handleSubmit()
  }

  return (
    <DropdownMenu.Item onClick={() => void handleDeployToEnvironment()}>
      <div className="w-full flex items-center space-x-3">
        {isDeployed ? (
          <div className="h-2 w-2 ml-1 rounded-full bg-success-200 ring-1 ring-green-500 dark:ring-grey-800 ring-offset-2 ring-offset-white" />
        ) : (
          <div className="h-2 w-2 ml-1 rounded-full bg-grey-200 ring-1 ring-grey-400 dark:ring-grey-800 ring-offset-2 ring-offset-white" />
        )}
        <div className="flex flex-col">
          <div>
            {environment.name} {isDeployed ? ' deployed' : ''}
          </div>
          {isDeployed && (
            <div className="py-0.5">
              {deployedVersions.slice(0, 1).map((v) => (
                <div key={v.id} className="text-xs text-grey-400">
                  Version #{v.version_number}
                </div>
              ))}
              {deployedVersions.length > 1 && (
                <div className="text-xs text-grey-400">
                  And {deployedVersions.length - 1}{' '}
                  {deployedVersions.length - 1 === 1 ? 'other' : 'others'}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DropdownMenu.Item>
  )
}
