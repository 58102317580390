import * as PopoverPrimitive from '@radix-ui/react-popover'
import React from 'react'
import { cn } from '@/client/utils'
import styles from './styles.module.css'
import type { PopoverContentProps } from './types'

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(
  (
    {
      className,
      align = 'center',
      sideOffset = 4,
      matchTargetWidth = false,
      transformOrigin = true,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          `
          flex
          flex-col
          gap-3
          rounded-lg
          border
          border-grey-300
          bg-white
          p-3
          z-50
          text-grey-800
          shadow-light-sm
          outline-none
          data-[state=open]:animate-in
          data-[state=closed]:animate-out
          data-[state=closed]:fade-out-0
          data-[state=open]:fade-in-0
          data-[state=closed]:zoom-out-95
          data-[state=open]:zoom-in-95
          data-[side=bottom]:slide-in-from-top-2
          data-[side=left]:slide-in-from-right-2
          data-[side=right]:slide-in-from-left-2
          data-[side=top]:slide-in-from-bottom-2
          dark:border-zinc-800
          dark:text-grey-200
          dark:bg-black
          dark:text-grey-800
        `,
          matchTargetWidth && styles.PopoverContentConstraintWidth,
          transformOrigin && styles.PopoverContentTransformOrigin,
          className
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  )
)

PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { PopoverContent }
