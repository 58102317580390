import { AlertTriangle, Link03, Trash03 } from '@untitled-ui/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/client/components'
import { CopyButton } from '@/client/components/MarkdownRenderer/CodeBlock/components'
import { useFetchEvalRun } from '@/common/hooks/evalRuns'
import { EvalRunDetails } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/runs/[runId]'
import type { ActionVersionMetadata } from '@/server/service/types'
import { dateAgo } from '@/utils'
import { api } from '@/utils/api'

export const EvalRunDrawer = ({
  evalRunId,
  open,
  setOpen,
  workspaceId,
}: {
  evalRunId: number
  open: boolean
  setOpen: (val: boolean) => void
  workspaceId: number
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { evalRun, isLoading } = useFetchEvalRun(evalRunId, workspaceId)
  const deleteEvalRun = api.evalRun.delete.useMutation()
  const utils = api.useContext()
  const router = useRouter()
  useEffect(() => {
    if (open) {
      const query = { ...router.query, evalRunId }
      void router.push({ pathname: router.pathname, query })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evalRunId])

  if (!evalRun) return null

  const handleDelete = async (id: number, evalId: number) => {
    await deleteEvalRun.mutateAsync({ id })
    await utils.evalRun.getAll.invalidate({ evalId })
    setOpen(false)
  }

  const permalink = `${window.location.href}?evalRunId=${evalRun.id}`

  const handleOpenChange = (val: boolean) => {
    if (val) {
      const query = { ...router.query, evalRunId }

      void router.push({ pathname: router.pathname, query })
    } else {
      delete router.query.evalRunId
      void router.push(router)
    }
    setOpen(val)
  }

  const actionVersion = evalRun.actionVersion

  const { modelName } = actionVersion.metadata as ActionVersionMetadata

  return (
    <React.Fragment>
      {showDeleteModal && (
        <DeleteEvalRunModal
          open={showDeleteModal}
          onOpenChange={setShowDeleteModal}
          name={evalRun.Eval.name}
          callback={{ onDelete: async () => await handleDelete(evalRun.id, evalRun.evalId) }}
        />
      )}
      <Sheet open={open} onOpenChange={handleOpenChange}>
        <SheetContent side="eval">
          <SheetHeader>
            <SheetTitle className="flex flex-col gap-2">
              <div className="flex gap-1 items-center group">
                <Link className="mr-3 flex flex-col gap-3" href={permalink}>
                  Run #{evalRun.run_number}
                  <div className="text-sm font-normal flex items-center gap-2">
                    {modelName}

                    <div className="bg-grey-300 rounded-md p-1 text-xs text-grey-800 font-bold font-mono">
                      v{actionVersion.version_number}
                    </div>
                    <div className="text-grey-400">{dateAgo(new Date(evalRun.createdAt))}</div>
                  </div>
                </Link>

                <div
                  className="cursor-pointer hidden group-hover:flex items-center justify-center"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash03
                    className="h-5 w-5 text-grey-400 hover:text-grey-800"
                    aria-hidden="true"
                  />
                </div>
                <div className="cursor-pointer hidden group-hover:flex items-center justify-center">
                  <CopyButton text={permalink} icon={Link03} />
                </div>
              </div>
            </SheetTitle>
            <SheetDescription>
              {evalRun && !isLoading ? (
                <div className="pt-3">
                  <EvalRunDetails evalRun={evalRun} />
                </div>
              ) : (
                <div className="animate-pulse roundeded-full h-10 bg-grey-300 flex space-x-4"></div>
              )}
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </React.Fragment>
  )
}

export const DeleteEvalRunModal = ({
  open,
  onOpenChange,
  name,
  callback,
}: {
  open: boolean
  onOpenChange: (val: boolean) => void
  name: string
  callback: {
    onDelete: () => void | Promise<void>
  }
}) => {
  const [isLoading, setLoading] = useState(false)
  const onDelete = async () => {
    setLoading(true)
    await callback.onDelete()
    setLoading(false)
    onOpenChange(false)
  }
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="w-full sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-grey-900">Delete {name}</h3>
                <div className="mt-2">
                  <p className="text-sm text-grey-700">Are you sure you want to delete {name}?</p>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 sm:flex">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Close
            </Button>
            <Button
              variant="destructive"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => void onDelete()}
            >
              {isLoading ? 'Deleting...' : 'Delete Eval Run'}
            </Button>
          </div>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
