import type { Tool, Workspace } from '@prisma/client'
import { useEffect, useState } from 'react'
import { SkillIcon } from '@/client/assets/icons/icons'
import {
  Button,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/client/components'
import { Form } from './Form'

export const CreateSkill = ({ workspace }: { workspace: Workspace }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newSkill, setNewSkill] = useState<Tool | null>(null)

  return (
    <>
      <CreateSkillDrawer
        workspace={workspace}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setNewSkill={setNewSkill}
        newSkill={newSkill}
      />
      <Button onClick={() => setIsOpen(true)} startIcon={SkillIcon}>
        Add Skill
      </Button>
    </>
  )
}

export const CreateSkillDrawer = ({
  workspace,
  isOpen,
  setIsOpen,
  newSkill,
  setNewSkill,
}: {
  workspace: Workspace
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  newSkill?: Tool | null
  setNewSkill?: (tool: Tool | null) => void
}) => {
  useEffect(() => {
    if (setNewSkill) {
      setNewSkill(null)
    }
  }, [isOpen, setNewSkill])

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent className="max-w-4xl sm:max-w-5xl">
          <SheetTitle className="p-4">
            <header className="flex items-center justify-between">
              <div>
                <h1 className="text-xl font-medium leading-6">Add Skill</h1>
              </div>
            </header>
          </SheetTitle>
          <SheetHeader>
            <SheetDescription>
              {workspace && !newSkill && (
                <>
                  <Form
                    workspace={workspace}
                    onIsOpenChange={setIsOpen}
                    setNewSkill={setNewSkill}
                  />
                </>
              )}
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}
