import * as SelectPrimitive from '@radix-ui/react-select'
import { ChevronDown } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import type { SelectTriggerProps } from './types'

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, hasError, children, ...props }, ref) => {
  const { disabled } = props

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        `
          flex
          h-9
          w-full
          items-center
          justify-between
          rounded-md
          border
          border-zinc-300
          hover:border-zinc-400
          bg-white
          hover:bg-zinc-50
          px-3
          py-2
          text-sm
          font-regular
          focus:border-zinc-400
          focus:outline-none
          focus:ring-0
          disabled:border-zinc-100
          dark:disabled:text-zinc-600
          dark:disabled:bg-zinc-950
          disabled:cursor-not-allowed
          shadow-sm
          disabled:text-sm
          data-[state=open]:border-zinc-400
          data-[placeholder]:text-zinc-500
          data-[placeholder]:hover:text-zinc-600 dark:text-zinc-600
          dark:border-zinc-800
          dark:bg-black
          dark:ring-offset-zinc-900
          dark:focus:border-white
          dark:disabled:border-zinc-700
          dark:data-[state=open]:border-white
          dark:data-[placeholder]:text-zinc-400
          [&[data-state=open]>.select-chevron]:rotate-180
        `,
        {
          'border-error focus-within:border-error': hasError,
        },
        className
      )}
      {...props}
    >
      {children}

      <SelectPrimitive.Icon asChild>
        <Icon
          className={cn(
            'select-chevron transition-transform duration-200 ml-2',
            disabled ? 'text-grey-300 dark:text-zinc-600' : 'text-grey-400'
          )}
          size="sm"
          component={ChevronDown}
        />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  )
})

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

export { SelectTrigger }
