import type { ContextSource, Workspace } from '@prisma/client'
import type { LoaderConfig } from 'api/src/routers/contextSources/schemas'
import type { FieldArrayRenderProps } from 'formik'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, toast } from '@/client/components'
import { FieldError } from '@/common/components'
import {
  ContextSourceSplitterConfigSchema,
  type ContextSourceSplitterConfig,
} from '@/server/service/types'
import { api } from '@/utils'
// import { TextSplitterSelect } from '../contexts/TextSplitterSelect'
import { MetadataItem } from './CreateContextSource'
import { DeleteContextSourceModal } from './DeleteContextSourceModal'

export const UpdateContextSource = ({
  contextSource,
  workspace,
  setDrawerIsOpen,
}: {
  contextSource: ContextSource
  workspace: Workspace
  setDrawerIsOpen: (isOpen: boolean) => void
}) => {
  const [loading, setLoading] = useState(false)
  const updateContextSource = api.contextSource.update.useMutation()

  const utils = api.useContext()

  const schema = z.object({
    name: z.string().min(1),
    splitterConfig: ContextSourceSplitterConfigSchema,
    loaderConfig: z
      .array(
        z.object({
          name: z.string({ required_error: 'This field is required ' }),
          value: z.string({ required_error: 'This field is required ' }),
        })
      )
      .default([]),
  })

  type FormSchema = z.infer<typeof schema>

  async function onSubmit(data: FormSchema) {
    setLoading(true)
    await updateContextSource.mutateAsync(
      {
        id: contextSource.id,
        name: data.name,
        splitterConfig: data.splitterConfig,
        loaderConfig: data.loaderConfig || [],
      },
      {
        onSuccess: () => {
          setLoading(false)
          toast.success({
            title: 'Context source updated',
            description: "Successfully updated the context source's details",
          })
        },
        onError: (error) => {
          setLoading(false)
          toast.error({
            title: 'Something went wrong',
            description: error.message,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
    await utils.context.get.invalidate({ id: contextSource.contextId })
    await utils.contextSource.getAll.invalidate({ contextId: contextSource.contextId })
  }

  const initialSplitterConfig = contextSource.splitterConfig as ContextSourceSplitterConfig
  const initialLoaderConfig = (contextSource.loaderConfig || []) as LoaderConfig

  const initialValues: FormSchema = {
    name: contextSource.name,
    splitterConfig: initialSplitterConfig,
    loaderConfig: initialLoaderConfig,
  }

  const formik = useFormik<FormSchema>({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: onSubmit,
    validateOnBlur: false,
  })

  return (
    <>
      <div className="rounded-md border border-grey-200 dark:border-zinc-800 bg-white px-4 pb-4 pt-5 shadow-sm sm:p-6 sm:pb-4">
        {workspace && contextSource && (
          <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <div>
                <div className="flex flex-col">
                  <label htmlFor="content" className="block text-sm font-medium">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      placeholder={formik.values.name}
                      id="name"
                      className="block w-full min-w-0 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                  </div>
                </div>
              </div>

              {contextSource.loaderId != 1 && formik.values.loaderConfig && (
                <div className="my-6">
                  <p className="text-lg font-medium">Loader Configuration</p>
                  <div className="mt-2">
                    <FieldArray name="loaderConfig">
                      {({}: FieldArrayRenderProps) => (
                        <div>
                          {formik.values.loaderConfig.length > 0 &&
                            formik.values.loaderConfig.map((item, index) => (
                              <MetadataItem item={item} index={index} key={index} />
                            ))}
                          {/* {contextSource.loaderId != 1 && (
                            <>
                              <button
                                type="button"
                                className="mt-6 rounded border border-grey-300 bg-grey-100 px-4 py-2 transition duration-100 ease-in-out hover:border-grey-400"
                                onClick={() => push({ name: '', value: '', required: false })}
                              >
                                Add Item
                              </button>
                            </>
                          )} */}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              )}

              <div className="mt-6">
                <p className="text-lg font-medium">Document Settings</p>
              </div>

              {/* <div className="mt-6">
                <p className="text-sm text-grey-700">Text Splitter</p>
                <div className="mt-2">
                  <div className="flex flex-row gap-6 relative group">
                    <TextSplitterSelect
                      formik={formik}
                      setSplitter={(splitter) => {
                        void formik.setFieldValue('splitterConfig.splitter', splitter)
                      }}
                      splitter={formik.values.splitterConfig.splitter}
                    />
                  </div>
                </div>
              </div> */}

              {/* {formik.values.splitterConfig.splitter == 'code' && (
                <div className="mt-6">
                  <p className="text-sm text-grey-700">Code Language</p>
                  <div className="mt-2">
                    <div className="flex flex-row gap-6 relative group">
                      <select
                        name="splitterConfig.code_language"
                        id="splitterConfig.code_language"
                        className="block w-full min-w-0 max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.splitterConfig.code_language}
                      >
                        <option value="ruby">Ruby</option>
                        <option value="python">Python</option>
                        <option value="js">Javascript</option>
                        <option value="ts">Typescript</option>
                        <option value="java">Java</option>
                        <option value="go">Go</option>
                        <option value="php">PHP</option>
                        <option value="scala">Scala</option>
                        <option value="swift">Swift</option>
                        <option value="html">HTML</option>
                        <option value="markdown">Markdown</option>
                      </select>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="mt-6">
                <p className="text-sm text-grey-700">Separator</p>
                <div className="mt-2">
                  <div className="flex flex-row gap-6 relative group">
                    <input
                      name="splitterConfig.separator"
                      placeholder="Default is an empty space (' ')"
                      type="text"
                      className="block w-full min-w-0 max-w-lg flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.splitterConfig.separator}
                    />
                  </div>
                  <FieldError fieldName="splitterConfig.separator" formik={formik} />
                </div>
              </div>

              <div className="mt-3 justify-between py-5 sm:flex">
                <Button disabled={loading} loading={loading} type="submit">
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </FormikProvider>
          </form>
        )}
      </div>
      <ContextSourceDangerZone
        contextSource={contextSource}
        onDeletedCallback={() => {
          setDrawerIsOpen(false)
        }}
      />
    </>
  )
}

function ContextSourceDangerZone({
  contextSource,
  onDeletedCallback,
}: {
  contextSource: ContextSource
  onDeletedCallback?: () => void
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <DeleteContextSourceModal
        contextSource={contextSource}
        open={open}
        setOpen={setOpen}
        onDeletedCallback={onDeletedCallback}
      />
      <div className="mt-3 pt-3">
        <div className="space-y-6 rounded-md border border-grey-200 dark:border-zinc-800 bg-white dark:bg-black px-4 pb-4 pt-5 shadow-sm sm:space-y-5 sm:p-6 sm:pb-6">
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <h3 className="text-lg font-medium leading-6">Danger Zone</h3>
                {/* <p className="mt-1 max-w-2xl text-sm text-grey-500 dark:text-zinc-500">Destructive options</p> */}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start">
            <Button onClick={() => setOpen(!open)}>Delete this source?</Button>
            <p className="ml-3 rounded-full bg-grey-50 p-2 px-3 font-mono text-xs">
              Warning: This cannot be undone, proceed with caution
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
