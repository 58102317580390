import type { Workspace } from '@prisma/client'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function DeleteWorkspaceForm({
  setOpen,
  workspace,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteWorkspace = api.workspace.delete.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  async function onSuccess() {
    await utils.workspace.getAll.invalidate()
    toast.success({
      title: 'Workspace deleted',
      description: 'Successfully deleted the workspace',
    })
    setOpen(false)
    await router.push(`/`)
  }

  async function onDelete() {
    try {
      setLoading(true)
      await deleteWorkspace.mutateAsync({ workspaceId: workspace.id })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.workspace_deleted, {
        'Workspace ID': workspace.id,
        'Workspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })
      await onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Delete {workspace.name}</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-500">
                Are you sure you want to delete {workspace.name}?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 bg-grey-50 px-4 py-5 sm:flex sm:px-6">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete Workspace'}
        </Button>
      </div>
    </>
  )
}

export const DeleteWorkspaceModal = ({
  open,
  setOpen,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <DeleteWorkspaceForm setOpen={setOpen} workspace={workspace} />
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
