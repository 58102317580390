import { useFetchDataResultByGuid, useFetchPublicDataResultByGuid } from '@/common/hooks'
import { CompletionRenderer } from '../../tables/datatable-completion-render'
import { PendingCompletionRenderer } from '../../tables/datatable-pending-completion'

export function RenderFromDataRecordGuid({ dataRecordGuid }: { dataRecordGuid: string }) {
  const { data, isLoading } = useFetchDataResultByGuid(dataRecordGuid, 5000)
  if (isLoading) return <>Loading...</>
  if (!data) return <>No data found</>
  return (
    <>
      <span className="pr-3 pt-3 text-left text-xs font-normal uppercase text-grey-600 dark:text-zinc-600">
        Completion
      </span>
      <div>
        {data.output === '' ? (
          <PendingCompletionRenderer dataId={data.id} />
        ) : (
          <CompletionRenderer
            completion={data.output}
            numOutputTokens={data.num_output_tokens ? data.num_output_tokens : undefined}
            metadata={data.metadata as Record<string, unknown>}
          />
        )}
      </div>
    </>
  )
}

export function PublicRenderFromDataRecordGuid({ dataRecordGuid }: { dataRecordGuid: string }) {
  const { data, isLoading } = useFetchPublicDataResultByGuid(dataRecordGuid, 5000)
  if (isLoading) return <>Loading...</>
  if (!data) return <>No data found</>
  return (
    <>
      <span className="pr-3 pt-3 text-left text-xs font-normal uppercase text-zinc-600">
        Completion
      </span>
      <div>
        {data.output === '' ? (
          <PendingCompletionRenderer dataId={data.id} />
        ) : (
          <CompletionRenderer
            completion={data.output}
            numOutputTokens={data.num_output_tokens ? data.num_output_tokens : undefined}
            metadata={data.metadata as Record<string, unknown>}
          />
        )}
      </div>
    </>
  )
}
