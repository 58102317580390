import type { FC } from 'react'
import React from 'react'
import { ToolCallsRenderer } from '@/client/containers/views/Studio/components/Tabs/Chat/components/Main/components/Messages/components/ChatMessage'
import { cn } from '@/client/utils'
import type { StreamingData } from '@/common/stream/stream/streamStore'
import { useStreamStore } from '@/common/stream/stream/streamStore'

export const StreamRenderer: FC<{ dataGuid: string | null; className?: string }> = ({
  dataGuid,
  className,
}) => {
  const { getStream } = useStreamStore()
  const stream = getStream(dataGuid)

  return (
    <>
      {stream === null ||
      stream.status === 'not-started' ||
      stream.status === 'waiting-on-first-data' ? (
        <div className={cn('animate-pulse flex items-center', className)}>
          <div className="h-4 bg-grey-300 rounded w-full"></div>
        </div>
      ) : (
        <TextOrJsonStreamRenderer stream={stream} className={className} />
      )}
      {stream?.metadata && stream.metadata.debug_log && (
        <details>
          <summary className="mt-3 cursor-pointer text-xs text-grey-600 dark:text-zinc-600">
            Chain of thought
          </summary>
          <div className="text-2xs mt-3 rounded-md bg-black p-2 text-grey-200">
            <pre className="whitespace-pre-wrap">{stream.metadata.debug_log}</pre>
          </div>
        </details>
      )}
      {stream?.metadata && stream.metadata.error_log && (
        <details>
          <summary className="mt-3 cursor-pointer text-xs text-red-400">Error log</summary>
          <div className="text-2xs mt-3 rounded-md bg-black p-2 text-grey-200">
            <pre className="whitespace-pre-wrap">{stream.metadata.error_log}</pre>
          </div>
        </details>
      )}

      {stream?.tool_calls && (
        <ToolCallsRenderer toolCalls={stream.tool_calls} streaming={stream.status == 'streaming'} />
      )}
    </>
  )
}

const TextOrJsonStreamRenderer: FC<{ stream: StreamingData; className?: string }> = ({
  stream,
  className,
}) => {
  let outputJson: Record<string, unknown> = {}
  try {
    if (!stream.value) return

    outputJson = JSON.parse(stream.value) as Record<string, unknown>
  } catch (error) {
    // this is super noisy
    // console.error(error)
  }

  return (
    <div className={cn('flex flex-col gap-4 font-inter text-sm')}>
      {Object.keys(outputJson).length > 0 ? (
        <pre className="max-w-fit whitespace-pre-wrap text-sm">
          {JSON.stringify(outputJson, null, 2)}
        </pre>
      ) : (
        <div className={cn('font-inter', className)}>{stream.value}</div>
      )}
    </div>
  )
}
