import { AlertTriangle } from '@untitled-ui/icons-react'
import { Button, Label, Slider } from '@/client/components'
import { FieldError, Loader } from '@/common/components/forms'
import { SelectAllRows } from '../../context'
import { useGenerateMoreDatasetForm } from './form'
import type { FormProps } from './types'

export const GenerateMoreDatasetItemsForm = ({
  onIsOpenChange,
  isOpen,
  onUpdateSuccess,
}: FormProps & {
  onDeleteSuccess?: () => void
}) => {
  const { formik, loading, selectedIds, selectAllRows, dataCount } = useGenerateMoreDatasetForm({
    onIsOpenChange,
    isOpen,
    onUpdateSuccess,
  })

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div>
          <div className="mt-1 flex items-center justify-between pb-2">
            <label htmlFor="user" className="block text-sm text-grey-600 dark:text-zinc-600">
              {selectAllRows ? (
                <span className="text-grey-600 dark:text-zinc-600">
                  All rows selected ({dataCount} items)
                </span>
              ) : (
                <>
                  {selectedIds && (
                    <>
                      <span className="font-medium text-grey-800">{selectedIds.length}</span>{' '}
                      currently selected.
                    </>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 dark:border-zinc-800 pt-4">
            {!loading && (
              <div className="py-3 flex flex-col space-y-6">
                <div className="space-y-3">
                  <Label
                    htmlFor="attribute"
                    title="How many generation"
                    description={`For each item selected, create another ${formik.values.count} like it:`}
                  />
                  <Slider
                    min={1}
                    max={10}
                    step={1}
                    onValueChange={([value]) => {
                      void formik.setFieldValue('count', value)
                    }}
                  />
                </div>

                <div className="space-y-3">
                  <Label htmlFor="transformation" title="Instructions" />
                  <textarea
                    className="w-full h-24 p-2 border border-grey-200 dark:border-zinc-800 rounded-lg"
                    placeholder="Enter instructions for how to generate more items from the selected items (optional)"
                    value={formik.values.instructions as string}
                    onChange={(e) => {
                      formik.setFieldValue('instructions', e.target.value).catch(console.error)
                    }}
                  />

                  <FieldError fieldName="config" formik={formik} />
                </div>

                <div className="flex items-center space-x-3 border-[1px] p-3 border-yellow-400 bg-yellow-100 text-orange-800 rounded-md">
                  <AlertTriangle className="w-4 h-4 " />
                  <p className="font-mono text-xs">This cannot be undone, proceed with caution</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 flex flex-col space-y-2 border-t border-grey-200 dark:border-zinc-800 pt-4">
            <SelectAllRows />

            {loading && <Loader className="h-6 w-6" />}
          </div>
        </div>
      </div>
      <div className="mt-3 flex items-center space-x-4 justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button className="w-full" onClick={() => onIsOpenChange(false)} variant="outline">
          Cancel
        </Button>
        <Button className="w-full" onClick={() => formik.handleSubmit()}>
          Save
        </Button>
      </div>
    </div>
  )
}
