import type { Data, User } from '@prisma/client'
import type { SortingState } from '@tanstack/react-table'
import { useState } from 'react'
import { api } from '@/utils'
import type { DataTableFilter } from '../components/tables/v2/types'
import type { DetailedEvalRunItem } from '../types/eval'
import { useUser } from './user'

const shouldRefetchFn = function (data: DataWithMetadata) {
  const createdAt = new Date(data.createdAt)
  const now = new Date()
  const maxAge = 5 * 60 * 1000

  if (
    data.output !== '' ||
    (data.metadata && (data.metadata.debug_log || data.metadata.error_log)) ||
    now.getTime() - createdAt.getTime() > maxAge
  ) {
    return false
  }
  return true
}

type DataWithMetadata = Data & {
  createdBy: User
  metadata: {
    debug_log: string
    error_log: string
  }
}

export function useFetchDataResultByGuid(
  dataGuid: string,
  interval: number,
  shouldRefetch = true,
  refetchOnWindowFocus = true
) {
  const { user } = useUser()
  const {
    data,
    isLoading: isPrimitiveLoading,
    error,
  } = api.data.getByGuid.useQuery(
    { guid: dataGuid },
    {
      enabled: user !== undefined && dataGuid != '',
      refetchInterval: function (data) {
        return shouldRefetch
          ? !data || shouldRefetchFn(data as unknown as DataWithMetadata)
            ? interval || 5000
            : false
          : false
      },
      refetchOnWindowFocus,
    }
  )

  return {
    data,
    isLoading: dataGuid === '' ? false : isPrimitiveLoading,
    error,
  }
}

export function useFetchPublicDataResultByGuid(dataGuid: string, interval: number) {
  const { data, isLoading, error } = api.publicAction.getDataByGuid.useQuery(
    { guid: dataGuid },
    {
      enabled: true,
      refetchInterval: function (data) {
        return !data || shouldRefetchFn(data as DataWithMetadata) ? interval || 5000 : false
      },
    }
  )

  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchDataResult(dataId: number, interval: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.get.useQuery(
    { dataId: dataId },
    {
      enabled: user !== undefined,
      refetchInterval: function (data) {
        return !data || shouldRefetchFn(data as unknown as DataWithMetadata)
          ? interval || 5000
          : false
      },
    }
  )

  return {
    data,
    isLoading,
    error,
  }
}

export function usePublicFetchActionDataHistory(actionGuid: string, limit: number) {
  const { data, isLoading, error } = api.publicAction.getData.useQuery({
    guid: actionGuid,
    limit: limit,
  })
  return {
    dataHistory: data,
    isLoading,
    error,
  }
}

export function useInfiniteFetchAppDataHistory(
  appId: number,
  workspaceId: number,
  columnFilters: DataTableFilter,
  columnSorting: SortingState,
  pageSize?: number
) {
  const [pageState, setPageState] = useState({
    pageIndex: 0,
    pageSize: pageSize ?? 50,
  })
  const { user } = useUser()
  const { data, isLoading, error, fetchNextPage, isFetching } =
    api.data.getInfiniteAllForApp.useInfiniteQuery(
      {
        appId,
        filters: columnFilters,
        sorting: columnSorting,
        limit: pageState.pageSize,
      },
      {
        enabled: user !== undefined,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    )

  const handleFetchNextPage = async () => {
    await fetchNextPage()
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex + 1,
    })
  }

  const handleFetchPage = (pageIndex: number) => {
    setPageState({
      ...pageState,
      pageIndex,
    })
  }

  const handleFetchPreviousPage = () => {
    setPageState({
      ...pageState,
      pageIndex: pageState.pageIndex - 1,
    })
  }

  const pageCount = data?.pages[pageState.pageIndex]?.pageCount ?? 1

  return {
    dataHistory: data?.pages[pageState.pageIndex]?.dataItems,
    isLoading,
    isFetching,
    error,
    handleFetchNextPage,
    handleFetchPreviousPage,
    handleFetchPage,
    hasNextPage: pageState.pageIndex < pageCount - 1,
    hasPreviousPage: pageState.pageIndex > 0,
    pageState,
    setPageState,
    pageCount,
  }
}

export function useFetchAppDataReviewCount(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getFeedbackAppCount.useQuery(
    { appId },
    {
      enabled: user !== undefined,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    feedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchAppDataErrorCount(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getAppDataErrorCount.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    errorCount: data,
    isLoading,
    error,
  }
}

export function useFetchActionDataErrorCount(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getActionDataErrorCount.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    errorCount: data,
    isLoading,
    error,
  }
}

export function useFetchActionDataReviewCount(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getFeedbackActionCount.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    feedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchAppDataPositiveFeedbackCount(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getPositiveFeedbackAppCount.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    positiveFeedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchActionDataPositiveFeedbackCount(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getPositiveFeedbackActionCount.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    positiveFeedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchActionUsageDataGraph(
  workspaceId: number,
  filters: {
    startDate: number
    endDate: number
    intervalSize: number
    actionId: number
  }
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.observe.getUsageGraphData.useQuery(
    { workspaceId, filters },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )
  return {
    data,
    isLoading,
    error,
  }
}

export function useFetchInputTokens(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getSumOfInputTokens.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    inputTokens: data,
    isLoading,
    error,
  }
}

export function useFetchOutputTokens(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getSumOfOutputTokens.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    outputTokens: data,
    isLoading,
    error,
  }
}

export function useFetchActionInputTokens(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getSumOfActionInputTokens.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    inputTokens: data,
    isLoading,
    error,
  }
}

export function useFetchTotalTokens(dataIds: number[]) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getSumOfTokens.useQuery(
    { dataIds },
    {
      enabled: user !== undefined,
    }
  )
  return {
    tokens: data,
    isLoading,
    error,
  }
}

export function useFetchActionOutputTokens(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getSumOfActionOutputTokens.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    outputTokens: data,
    isLoading,
    error,
  }
}
export function useFetchDataForSession(sessionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getAllForSession.useQuery(
    { sessionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    sessionData: data,
    isLoading,
    error,
  }
}

export function useFetchDataSources(appId: number, columnFilters?: DataTableFilter) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getDataSourcesForApp.useQuery(
    { appId, filters: columnFilters ?? [] },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  return {
    sources: data,
    isLoading,
    error,
  }
}

export function useFetchExperimentDataPositiveFeedbackCount(
  experimentId: number,
  actionId: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExperimentPositiveFeedbackCount.useQuery(
    { experimentId, actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    positiveFeedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchExperimentDataNegativeFeedbackCount(
  experimentId: number,
  actionId: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExperimentNegativeFeedbackCount.useQuery(
    { experimentId, actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    negativeFeedbackCount: data,
    isLoading,
    error,
  }
}

export function useFetchExperimentAvgPromptSize(experimentId: number, actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExperimentAvgPromptSize.useQuery(
    { experimentId, actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    avgPromptSize: data,
    isLoading,
    error,
  }
}

export function useFetchFullPrompt(dataId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getFullPrompt.useQuery(
    { dataId: dataId, workspaceId },
    { enabled: user !== undefined }
  )
  return {
    prompt: data,
    isLoading,
    error,
  }
}

export function useFetchRequestResponse(dataId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getRequestResponse.useQuery(
    { dataId: dataId },
    { enabled: user !== undefined }
  )
  return {
    requestAndResponse: data,
    isLoading,
    error,
  }
}

export function useFetchExperimentActionCost(experimentId: number, actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExperimentCost.useQuery(
    { actionId, experimentId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    actionCost: data,
    isLoading,
    error,
  }
}

export function useFetchActionCostForApp(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getActionsCostForApp.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    actionCostsForApp: data,
    isLoading,
    error,
  }
}

export function useFetchAppCost(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getAppCost.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    appCost: data,
    isLoading,
    error,
  }
}

export function useFetchActionCost(actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getActionCost.useQuery(
    { actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    actionCost: data,
    isLoading,
    error,
  }
}

export function useFetchExperimentAvgPromptCompletionTime(experimentId: number, actionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExperimentAvgPromptCompletionTime.useQuery(
    { experimentId, actionId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    avgPromptCompletionTime: data,
    isLoading,
    error,
  }
}

export function useFetchExtUserIds(appId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.data.getExtUserIds.useQuery(
    { appId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    extUserIds: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunItemsForData({
  dataId,
  dataType,
}: {
  dataId: number
  dataType?: 'LIVE' | 'TEST' | undefined
}): {
  evalRunItems?: DetailedEvalRunItem[]
  isLoading: boolean
  error: any
} {
  const { user } = useUser()
  const { data, isLoading, error } = api.evalRunItem.getAllForData.useQuery(
    { dataId, dataType },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    evalRunItems: data && data.evalRunItems,
    isLoading,
    error,
  }
}
