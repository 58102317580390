import type { EvalDataType, EvalsOnEvalTypes } from '@prisma/client'
import { Check, Edit02, XClose } from '@untitled-ui/icons-react'
import { type FormikProps } from 'formik'
import React from 'react'
import { Button, Icon, Input } from '@/client/components'
import { cn } from '@/client/utils'
import { useFetchEvalType } from '@/common/hooks'
import type { EvalTypeMetadata } from '@/common/types/eval'
import { getEvalIcon } from '@/pages/[workspaceSlug]/apps/[slug]/actions/[actionSlug]/monitors'
import type { EvalSchema } from '../evals/schema'
import { EvalVariableInput } from '../modals/evals/EvalVariableInput'

export const EvalTypeForm = ({
  evalTypeIndex,
  evalType,
  formik,
  removeEvalType,
  evalDataType,
}: {
  evalTypeIndex: number
  evalType: EvalsOnEvalTypes & { newRecord?: boolean }
  formik: FormikProps<EvalSchema>
  removeEvalType?: (id: number) => void
  evalDataType: EvalDataType
}) => {
  const { evalType: evalTypeType } = useFetchEvalType({ evalTypeId: evalType.evalTypeId })

  const [name, setName] = React.useState(evalType.name ?? '')
  const [isEditing, setIsEditing] = React.useState(false)

  const metadata = React.useMemo(() => {
    if (evalTypeType) {
      return evalTypeType.metadata as EvalTypeMetadata
    }
    return evalType.metadata as EvalTypeMetadata
  }, [evalType.metadata, evalTypeType])

  const { description, variables, icon } = metadata
  const evalIcon = getEvalIcon(icon ?? '')

  return (
    <div>
      <div className="relative justify-between block w-full h-full p-4 space-y-3 bg-white border rounded-lg items-top text-grey-700 border-grey-200 dark:border-zinc-800dark:border-grey-300/20 dark:text-zinc-500 dark:bg-grey-800 ">
        <label htmlFor={`evalTypeId-${evalType.evalTypeId}`} className="flex flex-col w-full gap-3">
          <div className="flex items-start justify-between w-full">
            <div className="flex items-start w-full gap-1">
              {evalIcon && (
                <Icon
                  className="p-1.5 h-9 w-9 border-[1.5px] border-grey-200 dark:border-zinc-800 rounded-md"
                  component={evalIcon}
                  size={'xxl'}
                />
              )}
              <div className="flex flex-col">
                <Input
                  id={`evalTypes.${evalTypeIndex}.name`}
                  label="Evaluation name"
                  autoComplete="off"
                  hideLabel
                  className={cn(
                    'font-medium text-base flex-grow min-w-fit [&>#wrapper>#wrapper-child]:px-2 [&>#wrapper>#wrapper-child]:py-0 [&>#wrapper>#wrapper-child]:h-8',
                    {
                      '[&>#wrapper]:border-transparent': !isEditing,
                    }
                  )}
                  placeholder={evalType.name ?? "Enter the eval type's name"}
                  onFocus={() => setIsEditing(true)}
                  onBlur={() => setIsEditing(false)}
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                <div className="w-full ml-2 text-sm text-grey-500">{description}</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Button
                size="xs"
                className="w-9"
                variant="outline-hover"
                icon={isEditing ? Check : Edit02}
                onClick={() => {
                  setIsEditing((prev) => !prev)
                }}
                tooltip={{
                  content: isEditing ? 'Done editing' : 'Edit evaluation name',
                  side: 'top',
                }}
              />
              {typeof removeEvalType == 'function' && (
                <Button
                  size="xs"
                  className="w-9"
                  variant="outline-hover"
                  icon={XClose}
                  onClick={() => {
                    void removeEvalType(evalType.id)
                  }}
                  tooltip={{
                    content: 'Delete evaluation',
                    side: 'top',
                  }}
                />
              )}
            </div>
          </div>
        </label>
        {variables?.map((definition, i) => (
          <EvalVariableInput
            key={`${evalTypeIndex}-${evalType.evalTypeId}-variable-${definition.name}-${i}`}
            definition={definition}
            evalTypeId={evalType.evalTypeId}
            evalTypeIndex={evalTypeIndex}
            variableIndex={i}
            formik={formik}
            evalDataType={evalDataType}
          />
        ))}
      </div>
    </div>
  )
}
