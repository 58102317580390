import type { Workspace } from '@prisma/client'
import { useFormik } from 'formik'
import { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { Button, Dialog, toast } from '@/client/components'
import { FieldError } from '@/common/components/forms'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function UserInviteForm({
  workspace,
  setOpen,
}: {
  workspace: Workspace
  setOpen: (val: boolean) => void
}) {
  const [isLoading, setLoading] = useState(false)
  const { user } = useUser()
  const inviteUser = api.workspace.inviteUser.useMutation()
  const utils = api.useContext()

  const schema = z.object({
    email: z.string().email(),
  })

  type FormSchema = z.infer<typeof schema>
  const formik = useFormik<FormSchema>({
    initialValues: {
      email: '',
    },
    validationSchema: toFormikValidationSchema(schema),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      await inviteUser.mutateAsync(
        {
          workspaceId: workspace.id,
          email: values.email,
        },
        {
          onSuccess: () => {
            resetForm()
            if (user) {
              const id = user.id ? user.id : ''
              const email = user.email ? user.email : ''
              const uname = user.name ? user.name : ''
              identify_and_group(id, email, uname, workspace?.slug)
            }
            track(EVENT_NAMES.member_invited, {
              'Workspace ID': workspace.id,
              'Workspace Name': workspace.name,
              'Invited User Email': values.email,
              'User ID': user?.id,
              'User Email': user?.email,
              'User Name': user?.name,
            })
            toast.success({
              title: 'Invite sent',
              description: `We've sent an invite to ${values.email}.`,
            })
          },
          onError: (res: { message: string }) => {
            toast.error({
              title: 'Error',
              description: res.message ?? 'Failed to Send Invite',
            })
          },
          onSettled: () => {
            setLoading(false)
          },
        }
      )
      await utils.invite.getAll.invalidate({ workspaceId: workspace.id })
      setOpen(false)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="p-6">
        <div>
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-800 dark:text-white">
              Invite Teammates
            </h3>
            <div className="mt-2">
              <p className="text-sm text-grey-600 dark:text-zinc-600">
                Send invitation email and add to workspace
              </p>
              <div className="mt-6">
                <div className="flex flex-row rounded-md">
                  <input
                    name="email"
                    placeholder="E-mail"
                    autoComplete="off"
                    type="email"
                    id="email"
                    className="block w-full min-w-0 flex-1 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
                <FieldError fieldName="email" formik={formik} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between rounded-b-lg border-t border-grey-200 dark:border-zinc-800 bg-grey-50 py-4 px-6 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button type="submit" disabled={isLoading} loading={isLoading}>
          {isLoading ? 'Sending...' : 'Send Invite'}
        </Button>
      </div>
    </form>
  )
}

export const InviteUserModal = ({
  open,
  setOpen,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {workspace && <UserInviteForm workspace={workspace} setOpen={setOpen} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
