import { Button, Input } from '@/client/components'
import { useEditWorkspaceForm } from './Form/form'
import type { FormProps } from './Form/types'

export const WorkspaceEditForm = ({ workspace }: FormProps) => {
  const { formik, saveDisabled } = useEditWorkspaceForm({ workspace })
  const { values, errors, handleChange, handleSubmit, isSubmitting } = formik

  return (
    <div className="flex flex-col space-y-6 max-w-lg">
      <Input
        id="name"
        name="name"
        label="Workspace Name"
        message={errors.name}
        hasError={!!errors.name}
        value={values.name}
        onChange={handleChange}
      />

      <Input
        id="updatedSlug"
        prefix="https://app.klu.ai/"
        name="updatedSlug"
        placeholder="cyberdyne"
        label="Workspace URL"
        message={errors.updatedSlug}
        hasError={!!errors.updatedSlug}
        value={values.updatedSlug}
        onChange={handleChange}
      />

      <div>
        <Button
          onClick={() => {
            handleSubmit()
          }}
          variant="outline"
          disabled={isSubmitting || saveDisabled}
          loading={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  )
}
