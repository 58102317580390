import { cn } from '@/client/utils'
import type { FieldMessageProps } from './types'

const FieldMessage = ({ className, message, disabled, hasError }: FieldMessageProps) => {
  return (
    <div
      className={cn(
        'body2 mt-1.5',
        hasError ? 'text-error' : 'text-grey-500',
        { 'text-grey-300 dark:text-zinc-600': disabled },
        className
      )}
    >
      {message}
    </div>
  )
}

export { FieldMessage }
