import type { Action, App, Dataset, Eval, Workspace } from '@prisma/client'
import { Beaker02, Plus } from '@untitled-ui/icons-react'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { DatasetIcon } from '@/client/assets/icons/icons'
import { Button, Dialog, Label, toast } from '@/client/components'
import { ComboboxSingleV2 } from '@/client/components/Combobox/SingleV2'
import { useFetchEvalTypes } from '@/common/hooks'
import { useFetchDatasets } from '@/common/hooks/datasets'
import { api } from '@/utils'
import { AddEvalTypeInput } from '../evalType/AddEvalTypeInput'
import { EvalTypeForm } from '../evalType/EvalTypeForm'
import { FieldError } from '../forms'
import type { EvalSchema } from './schema'
import { evalSchemaBase } from './schema'

const evalNameSchema = evalSchemaBase.pick({ datasetName: true }).required({
  datasetName: true,
})

type EvalNameSchema = z.infer<typeof evalNameSchema>

function CreateDatasetModal({
  setOpen,
  open,
  callbacks,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  callbacks?: {
    onSuccess?: (datasetName: string) => void
  }
}) {
  const formik = useFormik<EvalNameSchema>({
    initialValues: {
      datasetName: '',
    },
    validateOnBlur: true,
    validationSchema: toFormikValidationSchema(
      evalNameSchema.superRefine((values, ctx) => {
        if (!values.datasetName) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['datasetName'],
            message: 'Dataset name is required',
          })
        }
      })
    ),
    onSubmit: (values, { resetForm }) => {
      try {
        callbacks?.onSuccess?.(values.datasetName)

        toast.success({
          title: 'Dataset created',
          description: 'Successfully created the dataset',
        })

        setOpen(false)

        resetForm()
      } catch (error) {
        console.error(error)
      }
    },
  })
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <h3 className="text-lg font-medium leading-6">Add Dataset</h3>
              <div className="mt-6">
                <Label title="Dataset Name" />
                <div className="mt-2">
                  <div className="flex flex-row rounded-md">
                    <input
                      name="datasetName"
                      placeholder="Eval datasets"
                      type="text"
                      autoComplete="off"
                      id="datasetName"
                      className="flex-1 block w-full min-w-0 rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
                      onChange={(e) => {
                        formik.setFieldValue('datasetName', e.target.value).catch(console.error)
                      }}
                      value={formik.values.datasetName}
                    />
                  </div>
                  <FieldError fieldName="datasetName" formik={formik} />
                </div>
              </div>
            </div>
            <div className="justify-between px-4 py-5 border-t rounded-b-lg border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black sm:flex sm:px-6">
              <Button
                variant="outline"
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-black ease-in-out bg-white border rounded-md shadow-sm border-grey-300 hover:border-grey-400 dark:bg-grey-700 sm:w-auto sm:text-sm"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>

              <Button type="submit">Save</Button>
            </div>
          </form>
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export const evalSchemaValidation = evalSchemaBase.superRefine((values, ctx) => {
  if (values.dataType === 'LIVE') {
    return //no test set required
  }
  if (values.datasetId && values.datasetId > 1) {
    if (!values.datasetName || values.datasetName === '') return
  } else {
    if (values.datasetName && values.datasetName !== '') return

    if (!values.datasetName || values.datasetName === '') {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['datasetId'],
        message: 'Select a data set',
      })
    }
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['datasetId'],
      message: 'Select a data set',
    })
  }
})

export function CreateEvalForm({
  app,
  setOpen,
  action,
  dataset,
  workspace,
}: {
  app: App
  setOpen: (val: boolean) => void
  action: Action
  dataset?: Dataset
  workspace: Workspace
}) {
  const router = useRouter()
  const [selectedEvalType, setSelectedEvalType] = useState<number | null>(null)
  const createEval = api.action.addEval.useMutation()
  const dataType = 'TEST'

  const { datasets, isLoading: isLoadingDatasets } = useFetchDatasets(app.id, app.workspaceId)
  const { evalTypes, isLoading: isLoadingEvalTypes } = useFetchEvalTypes({ dataType })

  const [createNewDataset, setCreateNewDataset] = React.useState(false)

  const [newDatasets, setNewDatasets] = useState<string[]>([])
  const [selectedDataset, setSelectedDataset] = useState<string | null>(
    dataset ? dataset.id.toString() : null
  )

  const [loading, setLoading] = useState(false)
  const utils = api.useContext()

  const onSuccess = (evalRecord: Eval) => {
    setOpen(false)
    toast.success({
      title: `${evalRecord.name} created`,
      description: 'Successfully created the evaluation',
    })

    router
      .push(`/${workspace.slug}/apps/${app.slug}/evaluate/evals/${evalRecord.slug}`)
      .catch(console.error)
  }

  const formik = useFormik<EvalSchema>({
    initialValues: {
      datasetName: '',
      dataType,
      name: `${action.name} Eval`,
      // evalTypeVariables: {},
      evalTypes: [],
      datasetId: dataset?.id || 0,
      actionId: action.id,
      versionId: undefined,
    },
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema(evalSchemaValidation),
    onSubmit: async (values, { resetForm }) => {
      if (values.evalTypes.length === 0) {
        toast.error({
          title: 'No evaluators selected',
          description: 'You must select at least one evaluator',
        })

        return
      }
      try {
        setLoading(true)

        const { evalTypes, ...evalValues } = values

        const evalRecord = await createEval.mutateAsync({
          ...evalValues,
          evalTypes: evalTypes.map((evalType) => ({
            evalTypeId: evalType.evalTypeId,
            metadata: evalType.metadata,
            name: evalType.name,
          })),
          appId: app.id,
          actionId: action.id,
          workspaceId: app.workspaceId,
        })
        void utils.eval.getAll.invalidate({ appId: app.id })
        void utils.eval.getAllForAction.invalidate({ actionId: action.id })
        setLoading(false)
        onSuccess(evalRecord)
        resetForm()
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    },
  })

  const addEvalType = (evalTypeId: number) => {
    const evalType = evalTypes?.find((evalType) => evalType.id === evalTypeId)
    if (!evalType) {
      return
    }

    formik
      .setFieldValue('evalTypes', [
        ...formik.values.evalTypes,
        ...[
          {
            id: Math.random() * 100,
            newRecord: true,
            evalTypeId: evalType.id,
            evalId: 1,
            metadata: evalType.metadata,
            name: evalType.name,
          },
        ],
      ])
      .catch(console.error)
  }

  const removeEvalType = (evalTypeId: number) => {
    const evalTypes = formik.values.evalTypes.filter((evalType) => evalType.id !== evalTypeId)
    formik.setFieldValue('evalTypes', evalTypes).catch(console.error)
  }

  return (
    <React.Fragment>
      <CreateDatasetModal
        setOpen={setCreateNewDataset}
        open={createNewDataset}
        callbacks={{
          onSuccess: (datasetName) => {
            formik.setFieldValue('datasetName', datasetName).catch(console.error)
            setSelectedDataset(datasetName)
            setNewDatasets((prev) => [...prev, datasetName])
            formik.setFieldError('datasetName', undefined)
            formik.setFieldError('datasetId', undefined)
          },
        }}
      />
      <form
        onSubmit={formik.handleSubmit}
        className="relative flex flex-col justify-between h-full"
      >
        <div className="px-6 pt-6 bg-white rounded-lg">
          <div>
            <div className="text-center sm:text-left">
              <h3 className="flex gap-2 text-lg font-medium">
                <Beaker02 className="hidden w-6 h-6" />
                Select evaluations for {action.name}
              </h3>

              <div className="mt-6">
                <div>
                  {isLoadingEvalTypes || !evalTypes ? (
                    <div className="flex space-x-4 animate-pulse">
                      <div className="grid w-full grid-cols-1 gap-3 place-items-stretch">
                        <div className="h-40 rounded bg-grey-200"></div>
                        <div className="h-40 rounded bg-grey-200"></div>
                        <div className="h-40 rounded bg-grey-200"></div>
                        <div className="h-40 rounded bg-grey-200"></div>
                        <div className="h-40 rounded bg-grey-200"></div>
                        <div className="h-40 rounded bg-grey-200"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="grid w-full gap-3 my-6 place-items-stretch">
                        {formik.values.evalTypes.map((evalType, i) => (
                          <EvalTypeForm
                            key={`${evalType.evalTypeId}-${i}`}
                            evalTypeIndex={i}
                            evalType={{
                              ...{ id: i, evalTypeId: i, evalId: i, metadata: {}, name: null },
                              ...evalType,
                            }}
                            formik={formik}
                            removeEvalType={removeEvalType}
                            evalDataType={dataType}
                          />
                        ))}
                      </div>

                      <AddEvalTypeInput
                        evalTypes={evalTypes}
                        selectedEvalType={selectedEvalType}
                        setSelectedEvalType={setSelectedEvalType}
                        addEvalType={addEvalType}
                        matchTargetWidth
                      >
                        <Button type="button" startIcon={Plus} variant="outline" className="w-full">
                          Add evaluator
                        </Button>
                      </AddEvalTypeInput>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 justify-between p-6 mt-6 bg-white border-t rounded-b-lg border-grey-200 dark:border-zinc-800 dark:bg-black sm:flex">
          <div className="flex items-center gap-2">
            <ComboboxSingleV2
              placeholder="Select dataset for evaluator"
              search={{
                style: 'default',
                placeholder: 'Search dataset',
              }}
              isLoading={isLoadingDatasets}
              title="Dataset"
              groups={[
                ...(newDatasets.length > 0
                  ? [
                      {
                        heading: 'New',
                        options: newDatasets.map((dataset) => ({
                          value: dataset,
                          label: dataset,
                          icon: DatasetIcon,
                        })),
                      },
                    ]
                  : []),
                ...(datasets && datasets.length > 0
                  ? [
                      {
                        heading: 'Existing',
                        options: datasets.map((dataset) => ({
                          value: dataset.id.toString(),
                          label: dataset.name,
                          icon: DatasetIcon,
                        })),
                      },
                    ]
                  : []),
              ]}
              content={{
                align: 'start',
              }}
              item={{
                icon: {
                  className: 'text-grey-400 dark:text-white',
                },
              }}
              value={selectedDataset ?? ''}
              onChange={(newValue, group) => {
                if (!newValue) return

                if (!group) return

                if (group === 'New') {
                  formik.setFieldValue('datasetName', newValue).catch(console.error)
                } else {
                  formik.setFieldValue('datasetId', parseInt(newValue)).catch(console.error)
                }

                setSelectedDataset(newValue)
              }}
              onSelectCreate={() => {
                setCreateNewDataset(true)
              }}
            />
            <FieldError fieldName="datasetId" formik={formik} className="mt-0" />
          </div>

          <div className="flex items-center gap-2">
            {evalTypes && formik.values.evalTypes.length > 0 ? (
              <AddEvalTypeInput
                evalTypes={evalTypes}
                selectedEvalType={selectedEvalType}
                setSelectedEvalType={setSelectedEvalType}
                addEvalType={addEvalType}
              >
                <Button startIcon={Plus} variant="outline">
                  Add evaluator
                </Button>
              </AddEvalTypeInput>
            ) : null}
            <Button disabled={loading} type="submit" loading={loading}>
              {loading ? 'Saving...' : 'Create'}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}
