import type { Dataset, ModelProvider, Workspace } from '@prisma/client'
import { useMemo, useState } from 'react'
import { getProviderIcon } from '@/client/assets/icons/providers/getProviderIcon'
import { Button, Combobox, Input, Skeleton } from '@/client/components'
import { useCustomField } from '@/client/hooks'
import { azureRegionToModelMapping } from '@/common/config/constants'
import { useFetchModelProviders } from '@/common/hooks'
import { useFetchTotalDatasetTokens } from '@/common/hooks/datasets'
import type { WorkspaceModelProvider } from '@/common/types/workspaceModelProvider'
import { azureBaseModels, openAIBaseModels, togetherBaseModels } from '@/lib/finetunes'
import { useCreateFinetuneForm } from './form'
import type { FormProps } from './types'

function FormFields({
  dataset,
  app,
  formik,
  finetunes,
  selectedWorkspaceModelProvider,
}: FormProps & {
  dataset: Dataset
  formik: ReturnType<typeof useCreateFinetuneForm>['formik']
  finetunes: ReturnType<typeof useCreateFinetuneForm>['finetunes']
  selectedWorkspaceModelProvider: WorkspaceModelProvider & { provider: ModelProvider }
}) {
  const { values } = formik
  const { tokens, isLoading: isTokensLoading } = useFetchTotalDatasetTokens(
    dataset.id,
    app.workspaceId
  )
  let baseModels: string[] = []

  const finetuneProvider = selectedWorkspaceModelProvider?.provider.name

  if (finetuneProvider === 'OpenAI') {
    baseModels = openAIBaseModels
  } else if (finetuneProvider === 'Azure OpenAI') {
    const url = selectedWorkspaceModelProvider?.url || ''
    const region = url?.split('https://')[1]?.split('.')[0] || ''

    baseModels = azureRegionToModelMapping[region] || azureBaseModels
  } else if (finetuneProvider === 'Together AI') {
    baseModels = togetherBaseModels
  } else {
    baseModels = []
  }

  const { handleChangeField, getFieldError } = useCustomField({ formik })

  const newEstimatedCost = (model?: string): number => {
    const tokensCount = tokens || 0
    const selectedModel = model || 'gpt-3.5-turbo'
    let costPerToken = 0.000008
    const epochCount = 3

    if (selectedModel.includes('gpt-3.5-turbo')) costPerToken = 0.000008
    else if (selectedModel.includes('gpt-4o-mini')) costPerToken = 0.000003
    else if (selectedModel.includes('gpt-4o-2024')) costPerToken = 0.000045
    else if (selectedModel.includes('gpt-4-')) costPerToken = 0.00009
    else if (selectedModel.includes('davinci-002')) costPerToken = 0.000006
    else if (selectedModel.includes('babbage-002')) costPerToken = 0.0000004
    return Number((tokensCount * costPerToken * epochCount).toFixed(2))
  }

  return (
    <>
      <div className="px-6 flex flex-col space-y-4">
        {(() => {
          const baseModelError = getFieldError('baseModel')
          return (
            <Combobox.Single
              classNameContent="bg-white text-sm font-inter"
              placeholder="Select a model"
              label="Base Model"
              groups={[
                {
                  heading: 'Models',
                  options: baseModels.map((name) => {
                    return {
                      label: name,
                      value: name,
                    }
                  }),
                },
                ...(finetuneProvider === 'openai' && finetunes && finetunes.length > 0
                  ? [
                      {
                        heading: 'Fine-tuned Models',
                        options: finetunes
                          .filter((finetune) => finetune.finetuned_model?.startsWith('ft:')) // should be just checking if not null, but there's some empty lines in the db. also this won't work in the future with non openai finetuning
                          .map((finetune) => ({
                            label: `${finetune.finetuned_model!}`,
                            value: finetune.finetuned_model!,
                          })),
                      },
                    ]
                  : []),
              ]}
              hasError={!!baseModelError}
              message={baseModelError}
              value={formik.values.baseModel}
              onChange={(newValue) => {
                if (!newValue) return
                void handleChangeField('baseModel', newValue)
              }}
            />
          )
        })()}
      </div>
      <div className="px-6 flex flex-col space-y-4">
        {(() => {
          const nameError = getFieldError('name')
          return (
            <Input
              id="name"
              name="name"
              autoComplete="off"
              label="Fine-tune Name"
              placeholder={`${app.name.replace(/\s+/g, '-').toLowerCase()}-${
                dataset ? dataset.name.replace(/\s+/g, '-').toLowerCase() : 'all-data'
              }`}
              message={nameError}
              hasError={!!nameError}
              value={values.name}
              onChange={(e) => {
                void handleChangeField('name', e.currentTarget.value)
              }}
            />
          )
        })()}
        <p className="mt-2 text-grey-400 text-xs">OpenAI sets a maximum of 18 characters</p>
      </div>
      <div className="px-6 flex flex-col space-y-4">
        <Input
          id="overideSystemMessage"
          name="overideSystemMessage"
          autoComplete="off"
          label="Override System Message"
          placeholder="Optional"
          message={formik.errors.overideSystemMessage}
          hasError={!!formik.errors.overideSystemMessage && formik.touched.overideSystemMessage}
          value={formik.values.overideSystemMessage}
          onChange={formik.handleChange}
        />
      </div>
      <div className="px-6 text-sm text-grey-600 dark:text-zinc-600">
        {tokens && tokens < 1 ? (
          <span>Training cost varies by model and token count </span>
        ) : isTokensLoading ? (
          <span>Calculating estimated cost...</span>
        ) : (
          <span>Estimated training cost: ${newEstimatedCost(formik.values.baseModel)}</span>
        )}
      </div>
    </>
  )
}

const useFinetuneProviders = ({ workspace }: { workspace: Workspace }) => {
  const { providers, isLoading } = useFetchModelProviders(workspace.id)

  const workspaceModelProviders: (WorkspaceModelProvider & { provider: ModelProvider })[] =
    useMemo(() => {
      const fineTuneSupported = ['OpenAI', 'Azure OpenAI', 'Together AI']

      if (!providers) return []
      return providers?.filter((provider) => {
        return fineTuneSupported.includes(provider.provider.name) && !provider.klu_provided
      })
    }, [providers])

  return { workspaceModelProviders, isLoading }
}

export const CreateFinetuneForm = ({
  dataset,
  workspace,
  app,
  onIsOpenChange,
  isOpen,
  toggleCreateWorkspaceModelProviderModal,
}: FormProps) => {
  const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(dataset)
  const { formik, finetunes, datasets } = useCreateFinetuneForm({
    dataset,
    workspace,
    app,
    onIsOpenChange,
    isOpen,
    toggleCreateWorkspaceModelProviderModal,
  })
  const { handleSubmit } = formik
  const { workspaceModelProviders, isLoading } = useFinetuneProviders({
    workspace,
  })

  const workspaceModelProviderOptions = useMemo(() => {
    return workspaceModelProviders.map((provider) => {
      const name = provider.name || provider.provider.name
      return {
        icon: getProviderIcon(provider.providerId),
        label: name,
        value: provider.id.toString(),
      }
    })
  }, [workspaceModelProviders])

  const selectedWorkspaceModelProvider = useMemo(() => {
    return workspaceModelProviders.find(
      (provider) => provider.id === formik.values.workspaceModelProviderId
    )
  }, [workspaceModelProviders, formik.values.workspaceModelProviderId])

  const noValidProvider = workspaceModelProviders.length === 0

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-4 py-2 px-6 ">
        <Combobox.Single
          placeholder="Select a provider"
          label="Finetune Provider"
          options={workspaceModelProviderOptions}
          hasError={
            !!formik.errors.workspaceModelProviderId && formik.touched.workspaceModelProviderId
          }
          message={formik.errors.workspaceModelProviderId}
          value={formik.values.workspaceModelProviderId.toString()}
          onChange={(newValue) => {
            if (!newValue) return
            void formik.setFieldValue('workspaceModelProviderId', parseInt(newValue))
          }}
        />
        {isLoading ? (
          <div className="flex flex-col space-y-2 py-1 opacity-50">
            <Skeleton className="h-5 w-10 rounded-md" />
            <Skeleton className="h-10 w-full rounded-md" />
          </div>
        ) : !noValidProvider ? (
          <Combobox.Single
            classNameContent="bg-white text-sm font-inter"
            placeholder="Select a dataset"
            label="Dataset"
            options={
              datasets
                ? datasets.map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id.toString(),
                  }))
                : []
            }
            hasError={!!formik.errors.datasetId && formik.touched.datasetId}
            message={formik.errors.datasetId}
            value={formik.values.datasetId.toString()}
            onChange={(newValue) => {
              if (!newValue) return
              void formik.setFieldValue('datasetId', parseInt(newValue))
              setSelectedDataset(datasets?.find((d) => d.id === parseInt(newValue)))
            }}
          />
        ) : (
          <div className="flex flex-col space-y-3">
            <p className="text-sm">Please add your LLM Provider API key.</p>

            <Button
              variant="outline"
              startIcon={getProviderIcon(1)}
              onClick={() => {
                toggleCreateWorkspaceModelProviderModal(true)
              }}
            >
              Connect LLM Provider
            </Button>
          </div>
        )}
      </div>
      {formik.values.datasetId > 0 &&
        selectedDataset &&
        !noValidProvider &&
        selectedWorkspaceModelProvider && (
          <>
            <FormFields
              dataset={selectedDataset}
              workspace={workspace}
              app={app}
              onIsOpenChange={onIsOpenChange}
              isOpen={isOpen}
              finetunes={finetunes}
              formik={formik}
              toggleCreateWorkspaceModelProviderModal={toggleCreateWorkspaceModelProviderModal}
              selectedWorkspaceModelProvider={selectedWorkspaceModelProvider}
            />
          </>
        )}
      <div className="flex items-center justify-between border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={noValidProvider}
          loading={formik.isSubmitting}
          onClick={() => {
            handleSubmit()
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}
