import { MetricBubble } from '@/pages/[workspaceSlug]/apps/[slug]/evaluate/evals/[evalSlug]/runs/[runId]'

export const ScoreCell = ({ value }: { value: number | string }) => {
  return (
    <div className="flex flex-col gap-2 mb-3 pt-3 p-1">
      {typeof value === 'string' ? (
        <>{value}</>
      ) : (
        <>
          {isNaN(Number(value)) ? (
            <div className="w-full h-6 bg-grey-200 rounded animate-pulse"></div>
          ) : (
            <MetricBubble label={false} value={`${Number(value).toLocaleString()}`} />
          )}
        </>
      )}
    </div>
  )
}

export const ReasonCell = ({ value }: { value: string }) => {
  return value === '' ? (
    <div className="w-full h-6 bg-grey-200 rounded animate-pulse py-3"></div>
  ) : (
    <p className="whitespace-pre-wrap py-3 max-h-[200px] overflow-y-auto overflow-x-hidden leading-normal">
      {value}
    </p>
  )
}
