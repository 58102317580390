import { ChevronDown } from '@untitled-ui/icons-react'
import React from 'react'
import { Icon } from '@/client/components'
import { cn } from '@/client/utils'

export const ComboboxSkeleton: React.FC<{ className?: string; label?: string }> = ({
  className,
  label,
}) => {
  return (
    <div className={cn('relative', className)}>
      <label className={cn('body2 mb-1.5 block font-medium text-grey-300 dark:text-zinc-600')}>
        {label}
      </label>

      <button
        className={
          'flex w-full animate-pulse cursor-wait items-center justify-end rounded-md border border-grey-100 bg-white dark:bg-black px-3 py-2 outline-none dark:border-zinc-800 dark:bg-grey-700'
        }
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        aria-expanded={false}
      >
        <Icon
          className={'shrink-0 text-grey-500 transition-transform duration-200 dark:text-zinc-600'}
          size="md"
          component={ChevronDown}
        />
      </button>
    </div>
  )
}
