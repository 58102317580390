import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandItemProps } from './types'

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  CommandItemProps
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      `
        relative
        flex  
        cursor-pointer
        select-none
        items-center
        rounded-lg
        px-3
        py-2
        outline-none
        aria-selected:bg-grey-200
        aria-selected:text-grey-900
        data-[disabled]:pointer-events-none
        data-[disabled]:opacity-50
        dark:aria-selected:bg-zinc-900
        dark:aria-selected:text-grey-50
      `,
      className
    )}
    {...props}
  />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

export { CommandItem }
