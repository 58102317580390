import { api } from '@/utils'
import { useUser } from './user'

export function useFetchFinetunes(appId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getAll.useQuery(
    { appId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    finetunes: data,
    isLoading,
    error,
  }
}

export function useFetchFinetuneBySlug(finetuneSlug: string, workspaceSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getBySlug.useQuery(
    { slug: finetuneSlug, workspaceSlug },
    {
      enabled: user !== undefined,
      refetchInterval: 10000,
    }
  )
  return {
    finetune: data,
    isLoading,
    error,
  }
}

export function useFetchFinetunesForDataset(datasetGuid: string, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getAllForDataset.useQuery(
    { datasetGuid, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    finetunes: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunsForFinetune(finetuneId: number, workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getEvalRuns.useQuery(
    { id: finetuneId, workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    evalRuns: data,
    isLoading,
    error,
  }
}
