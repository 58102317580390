// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

import type { Workspace } from '@prisma/client'
import { AlertCircle, Menu01 } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState, type ReactNode } from 'react'
import {
  APIKeyIcon,
  AppData,
  AppEval,
  // CompareIcon,
  ConnectionIcon,
  ConnectionIcon2,
  ContextIcon,
  DatasetIcon,
  FeedbackIcon,
  //KluSparkIcon,
  //MessageIcon,
  //ObserveIcon,
  FinetuneIcon,
  // AppIcon,
  FolderIcon,
  HomeIcon,
  InsightsIcon,
  PlanIcon,
  SkillIcon,
  //SkillIcon,
  StudioIcon,
} from '@/client/assets/icons/icons'
import { Sheet, SheetContent, SheetHeader, Tooltip } from '@/client/components'
import { WorkspaceSwitcher } from '@/client/containers/views/WorkspaceSwitcher'
import { cn } from '@/client/utils'
import { useResizableSidebar } from '@/client/utils/resizableSidebar/useResizableSidebar'
import { GlobalModals, InviteUserModal, UserAvatar } from '@/common/components'
import { FullLoaderPage } from '@/common/components/ui/FullLoaderPage'
import { ActiveLink } from '@/common/components/ui/nav/ActiveLink'
import {
  useCurrentAction,
  useCurrentApp,
  useCurrentWorkspace,
  useFetchApps,
  useUser,
} from '@/common/hooks'
import { useIsMobile } from '@/common/hooks/isMobile'
import CommandKDialog, { Hotkeys } from './CommandK'
import { ApplicationProvider, SidebarProvider, useApplication, useSidebar } from './context'
import { OnlineUsersNavItem } from './nav/OnlineUsersNavItem'
import * as Theme from './Theme'

const COLLAPSE_AT = 200

interface NavItemType {
  name: string
  description?: string
  href?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any
  parentPath?: string
  spacer?: boolean
  children?: NavItemType[]
  isHome?: boolean
}

const navigation: NavItemType[] = [
  { name: 'Apps', href: '', isHome: true, icon: HomeIcon },
  { name: 'Studio', href: '/studio', icon: StudioIcon },
  // { name: 'Observe', href: '/observe', icon: ObserveIcon },
  { name: 'Insights', href: '/insights', icon: InsightsIcon },
  // { name: 'Templates', href: '/templates', icon: TemplatesIcon },
  { name: 'Spacer', spacer: true, href: '' },
  { name: 'Connections', href: '/connections', icon: ConnectionIcon },
  { name: 'Context', href: '/context', icon: ContextIcon },
  { name: 'Skills', href: '/skills', icon: SkillIcon },
]

const navNUX: NavItemType[] = [
  { name: 'Apps', href: '', isHome: true, icon: HomeIcon },
  { name: 'Studio', href: '/studio', icon: StudioIcon },
  // { name: 'Templates', href: '/templates', icon: TemplatesIcon },
  // { name: 'Spacer', spacer: true, href: '' },
  // { name: 'Connections', href: '/connections', icon: ConnectionIcon },
  // { name: 'Context', href: '/context', icon: ContextIcon },
]

export function NavItem({
  item,
  orgUrl,
  showTooltip = false,
}: {
  item: NavItemType
  orgUrl: string
  showTooltip?: boolean
}) {
  const router = useRouter()
  const isActive = item.isHome
    ? router.asPath === orgUrl + (item.href || '')
    : item.name === 'Studio' && router.asPath.includes('/studio')
      ? true
      : !['Studio', 'Evaluate', 'Datasets', 'Curators', 'Compare', 'Finetune'].includes(
            item.name
          ) && !item.href?.includes('studio')
        ? router.asPath === orgUrl + (item.href || '') ||
          (router.asPath.startsWith(`${orgUrl}${item.href || ''}/actions/`) &&
            !router.asPath.includes('studio') &&
            !router.asPath.includes('conversations'))
        : router.asPath.startsWith(orgUrl + (item.href || ''))

  if (item.spacer) {
    return (
      <div>
        <hr className="my-2 dark:border-zinc-800" />
      </div>
    )
  }

  return (
    <div className={'flex'}>
      {showTooltip ? (
        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger>
            <ActiveLink
              href={orgUrl + (item.href || '')}
              forceActive={isActive}
              activeClassName="bg-grey-100 text-grey-900 dark:bg-zinc-900 dark:text-grey-200"
              className="group mt-0.5 flex items-center rounded-md px-1.5 py-1.5 text-sm truncate font-normal text-grey-700 hover:bg-grey-200 hover:text-grey-900 dark:text-zinc-400 dark:hover:bg-zinc-900 dark:hover:text-grey-200"
            >
              {item.icon && <item.icon className={'h-5 w-5 flex-shrink-0'} aria-hidden="true" />}
            </ActiveLink>
          </Tooltip.Trigger>
          <Tooltip.Content side={'right'}>{item.name}</Tooltip.Content>
        </Tooltip.Root>
      ) : (
        <ActiveLink
          href={orgUrl + (item.href || '')}
          forceActive={isActive}
          activeClassName="bg-grey-100 text-grey-900 dark:bg-zinc-900 dark:text-grey-200"
          className="group mt-0.5 w-full flex items-center rounded-md px-1.5 py-1.5 text-sm truncate font-normal text-grey-700 hover:bg-grey-200 hover:text-grey-900 dark:text-zinc-400 dark:hover:bg-zinc-900 dark:hover:text-grey-200"
        >
          {item.icon && <item.icon className={'mr-4 h-5 w-5 flex-shrink-0'} aria-hidden="true" />}
          {item.name}
        </ActiveLink>
      )}
    </div>
  )
}

export function OpenMobileSidebarButton() {
  const { setSidebarOpen } = useSidebar()
  return (
    <button
      type="button"
      className="md:hidden inline-flex h-12 w-12 items-center justify-center rounded-md hover:text-grey-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-grey-700"
      onClick={() => setSidebarOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <Menu01 className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}

function ChildSidebar({ children, workspace }: { children: ReactNode; workspace: Workspace }) {
  const { apps } = useFetchApps(workspace.id)
  const { app } = useCurrentApp()
  const { action } = useCurrentAction()
  const { user } = useUser()
  const [openModal, toggleOpenInviteModal] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const { sidebarOpen, setSidebarOpen } = useSidebar()

  const {
    billing: { isFlaggedAsRisky },
  } = useApplication()

  const { sidebarRef, startResizing, sidebarWidth } = useResizableSidebar({
    collapseAt: COLLAPSE_AT,
  })
  const isOnlyIconsSidebar = sidebarWidth < COLLAPSE_AT

  const orgUrl = workspace ? `/${workspace.slug}` : ``

  const [navigationWithAppData, setNavigationWithAppData] = useState<NavItemType[]>(navigation)

  useEffect(() => {
    if (!app?.id) {
      if (apps && apps.length === 0) {
        setNavigationWithAppData(navNUX)
      } else {
        setNavigationWithAppData(navigation)
      }
      return
    }

    setNavigationWithAppData([
      { name: 'Home', href: '', isHome: true, icon: HomeIcon },
      { name: 'Spacer', spacer: true, href: '' },
      {
        name: app.name,
        href: `/apps/${app.slug}`,
        icon: FolderIcon,
      },
      { name: 'Studio', href: `/apps/${app.slug}/studio`, icon: StudioIcon },
      { name: 'Spacer2', spacer: true, href: '' },
      { name: 'Datasets', href: `/apps/${app.slug}/datasets`, icon: DatasetIcon },
      { name: 'Logs', href: `/apps/${app.slug}/data`, icon: AppData },
      { name: 'Curators', href: `/apps/${app.slug}/curators`, icon: ConnectionIcon2 },
      /*
      REMOVING SESSIONS FOR NOW
      LET'S REWORK THIS TO LIST SESSIONS ACROSS APP
      FILTER BY ACTION IF USER WANTS TO
      {
        name: 'Sessions',
        href: `/apps/${app.slug}/actions/${action.id}/conversations`,
        icon: MessageIcon,
      },
      */
      { name: 'Spacer3', spacer: true, href: '' },
      { name: 'Evaluate', href: `/apps/${app.slug}/evaluate`, icon: AppEval },
      { name: 'Finetune', href: `/apps/${app.slug}/optimization`, icon: FinetuneIcon },
      // { name: 'Compare', href: `/apps/${app.slug}/compare`, icon: CompareIcon },
    ])
  }, [action?.action_type, action?.id, app?.slug, app?.name, app?.id, apps])

  return (
    <>
      {/*  Sidebar for Mobile */}
      <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen} modal={false}>
        <SheetContent className="max-w-4xl sm:max-w-5xl p-0" side="left">
          <SheetHeader className="h-full">
            <div className="h-full font-everett select-none flex min-h-0 flex-1 flex-col overflow-hidden border-r border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black dark:text-grey-500">
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div className="mb-4 flex min-h-16 h-16 items-center border-b border-grey-200 dark:border-zinc-800 px-4">
                  <WorkspaceSwitcher />
                </div>

                <nav className="flex-1 space-y-0.5 px-4">
                  {navigationWithAppData.map((item) => (
                    <NavItem
                      item={item}
                      key={item.name}
                      orgUrl={orgUrl}
                      showTooltip={isOnlyIconsSidebar}
                    />
                  ))}
                </nav>
                <div className="px-4 pb-4">
                  {apps && apps.length != 0 && (
                    <OnlineUsersNavItem isOnlyIconsSidebar={isOnlyIconsSidebar} />
                  )}
                  {/* <Notifications /> */}
                  <NavItem
                    item={{
                      name: 'API Key',
                      href: `/settings/developers`,
                      icon: APIKeyIcon,
                    }}
                    orgUrl={orgUrl}
                    showTooltip={isOnlyIconsSidebar}
                  />
                  <NavItem
                    item={{
                      name: 'Klu Feedback',
                      href: `https://feedback.klu.ai/roadmap?email=${user?.email || ''}`,
                      icon: FeedbackIcon,
                    }}
                    orgUrl={''}
                    showTooltip={isOnlyIconsSidebar}
                  />
                  <NavItem
                    item={{
                      name: 'Manage Plan',
                      href: `/settings/plans`,
                      icon: PlanIcon,
                    }}
                    orgUrl={orgUrl}
                    showTooltip={isOnlyIconsSidebar}
                  />
                  {user && (
                    <NavItem
                      item={{
                        name: 'Settings',
                        href: `/settings`,
                        icon: () => (
                          <UserAvatar
                            user={user}
                            className={`h-5 w-5 flex-shrink-0 ring-1 ring-black/20 ring-offset-1 ring-offset-white ${
                              !isOnlyIconsSidebar ? 'mr-4' : ''
                            }`}
                          />
                        ),
                      }}
                      orgUrl={orgUrl}
                      showTooltip={isOnlyIconsSidebar}
                    />
                  )}
                </div>
              </div>
              {/* <div className="flex flex-shrink-0 border-t border-grey-700 p-3"></div> */}
            </div>
          </SheetHeader>
        </SheetContent>
      </Sheet>

      {/* Static sidebar for desktop */}
      <div
        className="hidden font-everett md:fixed z-10 md:inset-y-0 md:flex md:flex-row"
        ref={sidebarRef}
        style={{ width: sidebarWidth }}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="select-none flex min-h-0 flex-1 flex-col overflow-hidden border-r border-grey-200 dark:border-zinc-800 bg-grey-50 dark:bg-black dark:text-grey-500">
          <div className="flex flex-1 flex-col overflow-y-auto">
            <div className="mb-4 flex min-h-16 h-16 items-center border-b border-grey-200 dark:border-zinc-800 px-4 ">
              <WorkspaceSwitcher />
            </div>
            <nav className="flex-1 space-y-0.5 px-4">
              {navigationWithAppData.map((item) => (
                <NavItem
                  item={item}
                  key={item.name}
                  orgUrl={orgUrl}
                  showTooltip={isOnlyIconsSidebar}
                />
              ))}
            </nav>
            <div className="px-4 pb-4">
              {isFlaggedAsRisky && (
                <div className="flex p-1.5">
                  <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger>
                      <AlertCircle className="h-5 w-5 text-red-500" />
                    </Tooltip.Trigger>
                    <Tooltip.Content side={'right'}>
                      This account is limited. Contact support for more information.
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
              )}

              {/* <Notifications /> */}
              {apps && apps.length != 0 && (
                <OnlineUsersNavItem isOnlyIconsSidebar={isOnlyIconsSidebar} />
              )}

              <NavItem
                item={{
                  name: 'API Key',
                  href: `/settings/developers`,
                  icon: APIKeyIcon,
                }}
                orgUrl={orgUrl}
                showTooltip={isOnlyIconsSidebar}
              />

              {apps && apps.length != 0 && (
                <NavItem
                  item={{
                    name: 'Klu Feedback',
                    href: `https://feedback.klu.ai/roadmap?email=${user?.email || ''}`,
                    icon: FeedbackIcon,
                  }}
                  orgUrl={''}
                  showTooltip={isOnlyIconsSidebar}
                />
              )}

              {apps && apps.length != 0 && (
                <NavItem
                  item={{
                    name: 'Manage Plan',
                    href: `/settings/plans`,
                    icon: PlanIcon,
                  }}
                  orgUrl={orgUrl}
                  showTooltip={isOnlyIconsSidebar}
                />
              )}

              {apps && apps.length != 0 && <Theme.Switcher />}

              {user && (
                <NavItem
                  item={{
                    name: 'Settings',
                    href: `/settings`,
                    icon: () => (
                      <UserAvatar
                        user={user}
                        className={`h-5 w-5 flex-shrink-0 ring-1 ring-black/20 ring-offset-1 ring-offset-white ${
                          !isOnlyIconsSidebar ? 'mr-4' : 'h-5 w-5'
                        }`}
                      />
                    ),
                  }}
                  orgUrl={orgUrl}
                  showTooltip={isOnlyIconsSidebar}
                />
              )}
            </div>
          </div>
          {/* <div className="flex flex-shrink-0 border-t border-grey-700 p-3"></div> */}
        </div>
      </div>

      {/*Content*/}
      <div className="flex h-full flex-1 flex-col">
        <main className="min-h-full flex-1 flex-col">
          <div
            className={cn({
              'absolute -left-1 z-20 h-screen w-2 cursor-col-resize transition-colors hover:bg-grey-200 dark:hover:bg-zinc-700 duration-200 ease-in-out':
                !isMobile,
            })}
            style={{ marginLeft: isMobile ? 0 : sidebarWidth }}
            onMouseDown={startResizing}
          />
          <div
            className={'fixed top-0 bottom-0 overflow-y-scroll w-full'}
            style={{ paddingLeft: isMobile ? 0 : sidebarWidth }}
          >
            {children}
          </div>
        </main>
      </div>
      {workspace && (
        <InviteUserModal
          open={openModal}
          setOpen={() => toggleOpenInviteModal(!openModal)}
          workspace={workspace}
        />
      )}
    </>
  )
}

export function Sidebar({ children }: { children: ReactNode }) {
  const { workspace, isLoading, error } = useCurrentWorkspace()
  const router = useRouter()
  const hasBeenIdentified = useRef(false)

  useEffect(() => {
    if (error || (workspace === undefined && !isLoading)) {
      void router.push(`/404`)
    }
  }, [workspace, error, router, isLoading])

  useEffect(() => {
    if (workspace && !hasBeenIdentified.current) {
      void fetch(`/api/novu/identify?workspaceSlug=${workspace.slug}`, {
        credentials: 'include',
      })
      hasBeenIdentified.current = true
    }
  }, [workspace])

  if (isLoading) {
    return <FullLoaderPage />
  }

  if (!workspace) {
    return null
  }

  return (
    <ApplicationProvider workspace={workspace}>
      <SidebarProvider>
        <ChildSidebar workspace={workspace}>
          {children}
          <CommandKDialog />
          <GlobalModals />
          <Hotkeys />
        </ChildSidebar>
      </SidebarProvider>
    </ApplicationProvider>
  )
}
