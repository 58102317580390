import * as DialogPrimitive from '@radix-ui/react-dialog'
import React from 'react'
import { DialogOverlay } from '@/client/components/Dialog/Overlay'
import { DialogPortal } from '@/client/components/Dialog/Portal'
import { cn } from '@/client/utils'
import type { DialogContentProps } from './types'

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />

    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        `
          fixed
          left-[50%]
          top-[50%]
          z-50
          grid
          w-full
          max-w-lg
          translate-x-[-50%]
          translate-y-[-50%]
          gap-6
          border-none
          border-grey-200
          bg-white
          text-grey-800
          shadow-lg
          outline-none
          duration-200
          focus-visible:shadow-focus
          data-[state=open]:animate-in
          data-[state=closed]:animate-out
          data-[state=closed]:fade-out-0
          data-[state=open]:fade-in-0
          data-[state=closed]:zoom-out-95
          data-[state=open]:zoom-in-95
          data-[state=closed]:slide-out-to-left-1/2
          data-[state=closed]:slide-out-to-top-[48%]
          data-[state=open]:slide-in-from-left-1/2
          data-[state=open]:slide-in-from-top-[48%]
          dark:border-none
          dark:border-zinc-800
          dark:ring-1
          dark:ring-zinc-800
          dark:bg-black
          dark:text-zinc-50
          sm:rounded-lg
          md:w-full
          overflow-hidden
        `,
        className
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
))

DialogContent.displayName = DialogPrimitive.Content.displayName

export { DialogContent }
