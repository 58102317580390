import type {
  CheckboxSelectionCallbackParams,
  ColDef,
  GridApi,
  HeaderCheckboxSelectionCallbackParams,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react' // React Grid Logic
import { dateAgo, humanize } from '@/utils'
import '@ag-grid-community/styles/ag-grid.css' // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import type { Action, Dataset, DatasetItem } from '@prisma/client'
import { ChevronDown } from '@untitled-ui/icons-react'
import { useEventListener } from 'liveblocks.config'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  CloneIcon,
  DeleteDataIcon,
  DownloadIcon,
  EditIcon,
  GenerateIcon,
  TagIcon,
} from '@/client/assets/icons/icons'
import { Button, Checkbox, Icon, Popover, Progress, toast } from '@/client/components'
import { useFetchBatchJobs, useFetchDatasetItems } from '@/common/hooks'
import type { WorkspaceAppDatasetItemIParams } from '@/common/types/queryparams'
import { DatasetItemDrawer } from '@/pages/[workspaceSlug]/apps/[slug]/datasets/[datasetGuid]'
import type { DatasetMetadata } from '@/server/service/types'
import { api } from '@/utils'
import { ExpectedOutputCell } from '../../evalRuns/grid/cells/ExpectedOutputCell'
import { InputCell } from '../../evalRuns/grid/cells/InputCell'
import { useWorkspace } from '../../ui/context'
import { useDatasetBulkAction } from '../context'
import { DataExportModal } from '../DataExport'
import { DeduplicateDatasetModal } from '../DeduplicateDataset'
import { DeleteDatasetItemsModal } from '../DeleteDatasetItems'
import { GenerateMoreDatasetItemsModal } from '../GenerateMore'
import { TransformDatasetItemsModal } from '../TransformDatasetItems'
import { UpdateDatasetItemsModal } from '../UpdateDatasetItems'

export const DatasetItemsGrid = ({
  dataset,
  initialPage,
  action,
}: {
  dataset: Dataset
  action?: Action
  initialPage?: number
}) => {
  const [page] = useState(initialPage || 1)
  const [perPage] = useState(5000)
  // Hack - really we should be fetching everything and letting ag-grid handle pagination
  // or - we need to rework pagination/filtering, because currently ag-grid is filtering/sorting on the client side
  // but pagination is happening on the server side

  const { datasetItems, isLoading } = useFetchDatasetItems({
    datasetId: dataset.id,
    page,
    perPage,
    workspaceId: dataset.workspaceId,
  })

  const router = useRouter()

  const { datasetItemGuid } = router.query as WorkspaceAppDatasetItemIParams
  const [selectedDatasetItemGuid, setSelectedDatasetItemGuid] = useState<string | undefined>(
    datasetItemGuid ? datasetItemGuid : undefined
  )

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isTransformModalOpen, setIsTransformModalOpen] = useState(false)
  const [isDeduplicateModalOpen, setIsDeduplicateModalOpen] = useState(false)
  const [isGenerateMoreModalOpen, setIsGenerateMoreModalOpen] = useState(false)

  const { selectDatasetItems, isOpen, onIsOpenChange, gridRef } = useDatasetBulkAction()

  const trainingItemsCount = useMemo(() => {
    return datasetItems?.filter((item) => item.labels.includes('Training')).length
  }, [datasetItems])
  const evaluationItemsCount = useMemo(() => {
    return datasetItems?.filter((item) => item.labels.includes('Evaluation')).length
  }, [datasetItems])

  const workspace = useWorkspace()

  const rows: DatasetItem[] = useMemo(() => {
    if (isLoading || !datasetItems) return []

    return datasetItems
  }, [isLoading, datasetItems])

  const columns: ColDef[] = useMemo((): ColDef[] => {
    if (!workspace || !rows || rows.length < 1) return []

    return (
      [
        {
          field: 'input',
          headerName: 'Input',
          flex: 2,
          cellRenderer: InputCell,
        },
        {
          field: 'output',
          headerName: 'Output',
          flex: 2,
          cellRenderer: ExpectedOutputCell,
          checkboxSelection: false,
        },
        {
          field: 'labels',
          headerName: 'Split',

          cellRenderer: LabelCellRenderer,
          checkboxSelection: false,
        },
        {
          field: 'tags',
          headerName: 'Tags',

          cellRenderer: TagsCellRenderer,
          checkboxSelection: false,
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          hide: true,
          valueFormatter: (params) => {
            return (params.value as Date).toLocaleString()
          },
          checkboxSelection: false,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          hide: true,
          valueFormatter: (params) => {
            return (params.value as Date).toLocaleString()
          },
          checkboxSelection: false,
        },
        {
          field: 'actions',
          headerComponent: TableColumnSelector,
          sortable: false,
          resizable: false,
          width: 50,
          minWidth: null,
          suppressNavigable: true,
          lockPosition: 'right',
          suppressMovable: true,
          checkboxSelection: false,
        },
      ] as ColDef[]
    ).filter((x) => !!x)
  }, [workspace, rows])

  const defaultColDef = useMemo(() => {
    return {
      headerComponentParams: {},
      filter: true,
      minWidth: 100,
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn,
    }
  }, [])

  function isFirstColumn(
    params: CheckboxSelectionCallbackParams | HeaderCheckboxSelectionCallbackParams
  ) {
    const displayedColumns = params.api.getAllDisplayedColumns()
    const thisIsFirstColumn = displayedColumns[0] === params.column
    return thisIsFirstColumn
  }

  function selectNextRow() {
    const rows = datasetItems
    if (!rows) return null
    const currentIndex = rows.findIndex((row) => row.guid === selectedDatasetItemGuid)
    const nextIndex = currentIndex + 1
    if (nextIndex >= rows.length) return null
    const row = rows[nextIndex]
    if (!row) return null
    setSelectedDatasetItemGuid(row.guid)
  }

  function selectPreviousRow() {
    const rows = datasetItems
    if (!rows) return null
    const currentIndex = rows.findIndex((row) => row.guid === selectedDatasetItemGuid)
    const previousIndex = currentIndex - 1
    if (previousIndex < 0) return null
    const row = rows[previousIndex]
    if (!row) return null
    setSelectedDatasetItemGuid(row.guid)
  }

  function hasPreviousRow() {
    const rows = datasetItems
    if (!rows) return false
    const currentIndex = rows.findIndex((row) => row.guid === selectedDatasetItemGuid)
    const previousIndex = currentIndex - 1
    return previousIndex >= 0
  }

  function hasNextRow() {
    const rows = datasetItems
    if (!rows) return false
    const currentIndex = rows.findIndex((row) => row.guid === selectedDatasetItemGuid)
    const nextIndex = currentIndex + 1
    return nextIndex < rows?.length
  }

  const onQuickFilterChanged = useMemo(
    () =>
      debounce((value: string) => {
        gridRef?.current?.api.setGridOption('quickFilterText', value)
      }, 250),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      {selectedDatasetItemGuid && (
        <DatasetItemDrawer
          datasetItemGuid={selectedDatasetItemGuid}
          dataset={dataset}
          action={action}
          workspace={workspace}
          open={true}
          setOpen={(val) => {
            setSelectedDatasetItemGuid(val ? selectedDatasetItemGuid : undefined)
          }}
          selectNextRow={selectNextRow}
          selectPreviousRow={selectPreviousRow}
          hasPreviousRow={hasPreviousRow()}
          hasNextRow={hasNextRow()}
        />
      )}

      {rows.length > 0 && (
        <>
          <div className="flex items-center justify-between">
            <div className="">
              <div className="space-x-2">
                <Button
                  variant="outline"
                  onClick={() => setIsUpdateModalOpen(true)}
                  tooltip={{
                    content: 'Update tags or split',
                    align: 'start',
                  }}
                  icon={TagIcon}
                />
                <Button
                  variant="outline"
                  tooltip={{
                    content: 'Relabel data',
                    align: 'start',
                  }}
                  onClick={() => setIsTransformModalOpen(true)}
                  icon={EditIcon}
                />
                <Button
                  variant="outline"
                  onClick={() => setIsGenerateMoreModalOpen(true)}
                  tooltip={{
                    content: 'Generate more data',
                    align: 'start',
                  }}
                  icon={GenerateIcon}
                />
                <Button
                  variant="outline"
                  onClick={() => setIsDeduplicateModalOpen(true)}
                  tooltip={{
                    content: 'Deduplicate data',
                    align: 'start',
                  }}
                  icon={CloneIcon}
                />
                <Button
                  variant="outline"
                  tooltip={{ content: 'Export data', align: 'start' }}
                  onClick={() => onIsOpenChange(true)}
                  icon={DownloadIcon}
                />
                <Button
                  variant="outline"
                  tooltip={{
                    content: 'Delete data',
                    align: 'start',
                  }}
                  onClick={() => setIsDeleteModalOpen(true)}
                  icon={DeleteDataIcon}
                />
              </div>
              <BulkActionStatus dataset={dataset} />
            </div>
            <div className="text-xs text-gray-500 bg-gray-200 px-4 border border-gray-300 rounded-full py-1">
              {rows.length} items{' '}
              {trainingItemsCount && evaluationItemsCount
                ? `(${trainingItemsCount} training, ${evaluationItemsCount} evaluation)`
                : ''}
            </div>
            <div className="">
              <input
                type="text"
                onInput={(event) => {
                  onQuickFilterChanged((event.target as HTMLInputElement).value)
                }}
                id="quickFilter"
                placeholder="Quick filter..."
                className="w-full flex-1 placeholder:text-xs rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
              />
            </div>
          </div>

          <DataExportModal isOpen={isOpen} onIsOpenChange={onIsOpenChange} />
          <DeleteDatasetItemsModal
            isOpen={isDeleteModalOpen}
            onIsOpenChange={setIsDeleteModalOpen}
          />
          <UpdateDatasetItemsModal
            isOpen={isUpdateModalOpen}
            workspaceId={workspace.id}
            onIsOpenChange={setIsUpdateModalOpen}
            datasetId={dataset.id}
          />
          <TransformDatasetItemsModal
            isOpen={isTransformModalOpen}
            onIsOpenChange={setIsTransformModalOpen}
          />
          <DeduplicateDatasetModal
            isOpen={isDeduplicateModalOpen}
            onIsOpenChange={setIsDeduplicateModalOpen}
          />
          <GenerateMoreDatasetItemsModal
            isOpen={isGenerateMoreModalOpen}
            onIsOpenChange={setIsGenerateMoreModalOpen}
          />

          <div className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              rowData={rows}
              rowHeight={200}
              columnDefs={columns}
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              suppressRowHoverHighlight={true}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              pagination={true}
              paginationPageSizeSelector={[20, 50, 100, 200]}
              modules={[ClientSideRowModelModule]}
              onRowClicked={(event) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setSelectedDatasetItemGuid(event.data.guid as string)
              }}
              onRowSelected={(event) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                void selectDatasetItems(event.data.id as number)
              }}
              className="pb-10"
            />
          </div>

          {/* Paginate client side instead */}
          {/* <Pagination
            onPageChange={setPage}
            total={total || 0}
            perPage={perPage}
            setPerPage={setPerPage}
            currentPage={page}
          /> */}
        </>
      )}
    </>
  )
}

const LabelCellRenderer = ({ value }: { value: string[] }) => {
  const labels = value.length > 0 ? value : ['Training', 'Evaluation']

  return (
    <div className="inline-flex gap-2 py-3">
      {labels.map((label) => (
        <div
          key={label}
          className={`bg-blue-100 text-blue-800 px-2.5 py-0.5 border border-blue-400 rounded text-xs capitalize font-normal`}
        >
          {label}
        </div>
      ))}
    </div>
  )
}

export const TagsCellRenderer = ({ value }: { value: string[] }) => {
  return (
    <div className="inline-flex gap-2 py-3">
      {value?.map((label) => (
        <div
          key={label}
          className={` text-grey-800 px-2.5 py-0.5 border border-grey-400 rounded text-xs capitalize font-normal`}
        >
          {label}
        </div>
      ))}
    </div>
  )
}

const TableColumnSelector = ({ api }: { api: GridApi }) => {
  const [open, setOpen] = useState(false)
  const [refresh, setRefresh] = useState('')

  if (!api) return null

  api.addEventListener('columnVisible', () => {
    setRefresh(Math.random().toString())
    // setRefresh('101112112121212121212121212121')
  })

  const columns: ColDef[] =
    api
      .getColumns()
      ?.map((x) => x.getColDef())
      ?.filter((x) => !['spacer', 'actions'].includes(x.field!) && !x.pinned)
      .filter(Boolean) || []

  const visibleColumns = api
    .getAllDisplayedColumns()
    .map((x) => x.getColDef())
    .map((x) => x.field)

  const toggleColumn = (field: string) => {
    api.setColumnsVisible([field], !api.getColumn(field)?.isVisible())
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={true}>
      <Popover.Trigger>
        <Icon size="md" component={ChevronDown} />
      </Popover.Trigger>

      <Popover.Content collisionPadding={20} sticky="always">
        <ul className="flex flex-col gap-2" key={refresh}>
          {columns.map((column) => (
            <li key={column.field} className="text-xs text-grey-800">
              <label className="flex gap-2 items-center cursor-pointer select-none">
                <Checkbox
                  checked={visibleColumns.includes(column.field)}
                  onCheckedChange={() => {
                    toggleColumn(column.field!)
                  }}
                />
                {column.headerName}
              </label>
            </li>
          ))}
        </ul>
      </Popover.Content>
    </Popover.Root>
  )
}

const BulkActionStatus = ({ dataset }: { dataset: Dataset }) => {
  const utils = api.useContext()
  const deleteBatchJob = api.batchJob.delete.useMutation()
  const [progress, setProgress] = useState(0)
  const timer = useRef<NodeJS.Timeout | null>(null)

  const metadata = dataset.metadata as DatasetMetadata

  const { batchJobs } = useFetchBatchJobs([metadata.batchJobId || 0])

  useEventListener(({ event }) => {
    console.log(event)
    console.log(metadata)
    const { progress, name, batchJobId } = event as {
      progress: number
      name: string
      batchJobId: number
    }
    if (batchJobId == metadata.batchJobId && name == 'batchJobProgress') {
      setProgress(progress)
    }
  })

  useEffect(() => {
    if (progress >= 100 && timer.current === null) {
      void utils.dataset.getByGuid.invalidate({ guid: dataset.guid })
      void utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
      void utils.batchJob.getByIds.invalidate({ ids: [metadata.batchJobId || 0] })
      toast.success({
        title: 'Bulk action complete',
        description: 'Successfully completed the bulk action',
      })
      timer.current = setTimeout(() => {
        setProgress(0)
        timer.current = null
      }, 5000)
    }
  }, [
    dataset,
    metadata.batchJobId,
    progress,
    utils.batchJob.getByIds,
    utils.dataset.getByGuid,
    utils.datasetItem.getAll,
  ])

  const handleDelete = async (batchJobId: number) => {
    await deleteBatchJob.mutateAsync({ id: batchJobId })
    void utils.dataset.getByGuid.invalidate({ guid: dataset.guid })
    void utils.datasetItem.getAll.invalidate({ datasetId: dataset.id })
    void utils.batchJob.getByIds.invalidate({ ids: [batchJobId || 0] })
  }

  return (
    <div>
      {batchJobs &&
        batchJobs.map((job) => (
          <div key={job.id} className="text-grey-500 text-sm group flex items-center">
            {humanize(job.name)} started {dateAgo(new Date(job.createdAt))}.{' '}
            <div className={` bg-grey-100 text-grey-800 px-2.5 py-0.5 rounded text-sm capitalize`}>
              {job.status}
            </div>
            <div
              className="cursor-pointer hidden group-hover:flex items-center justify-center ml-2"
              onClick={() => {
                void handleDelete(job.id)
              }}
            >
              <DeleteDataIcon
                className="h-5 w-5 text-grey-400 hover:text-grey-800"
                aria-hidden="true"
              />
            </div>
          </div>
        ))}
      {progress > 0 && (
        <div className="flex items-center text-sm text-grey-500 gap-3">
          {progress && <Progress value={progress} className="h-3 w-[80px]" />}
        </div>
      )}
    </div>
  )
}
