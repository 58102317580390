import React from 'react'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Combobox,
  Input,
  Label,
  Tabs,
  Textarea,
} from '@/client/components'
import { Progress } from '@/client/components/Progress'
import { Select } from '@/common/components'
import { FieldError, FileUpload } from '@/common/components/forms'
import { useFetchOpenAIFiles } from '@/common/hooks'
import {
  useAddDatasetItemRowForm,
  useImportDataForm,
  useImportDataFromOpenAIFileForm,
} from './form'
import type { FormProps } from './types'

function FileUploadProgress({
  file,
}: {
  file: {
    file: File
    progress: number
    uploaded: number
    size: number
  }
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>File upload progress</CardTitle>
        <CardDescription>Uploading {file.file.name}...</CardDescription>
      </CardHeader>
      <CardContent>
        {file.progress === 100 ? (
          <div>File uploaded!</div>
        ) : (
          <Progress value={file.progress} className="w-[60%]" />
        )}
      </CardContent>
    </Card>
  )
}

const LocalImportForm = ({ workspace, onIsOpenChange, dataset }: FormProps) => {
  const { formik, saveDisabled, setFiles, uploadedFiles, files, labelOptions } = useImportDataForm({
    dataset,
    workspace,
    onIsOpenChange,
  })
  const { values, errors, handleChange, handleSubmit, handleBlur } = formik

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        {!dataset && (
          <Input
            id="name"
            name="datasetName"
            label="Dataset Name"
            placeholder="ChatCompletion Data"
            message={errors.datasetName}
            hasError={!!errors.datasetName}
            value={values.datasetName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )}
        <div className="h-20">
          <FileUpload
            allowDrop
            multiple={false}
            onChangeFiles={(files) => {
              setFiles(files)
              const filename = files[0]?.name
              if (formik.values.datasetName === '' && filename) {
                formik.setFieldValue('datasetName', filename).catch(console.error)
              }
            }}
            allowedFileTypes={['json']}
          />
        </div>
        <div>
          {uploadedFiles.length > 0 ? (
            <>
              {uploadedFiles.map((file, i) => {
                return <FileUploadProgress key={i} file={file} />
              })}
            </>
          ) : (
            <>
              {files.map((file, i) => {
                return (
                  <div className="flex items-center justify-between space-x-2" key={i}>
                    <div className="max-w-[300px] truncate">{file.name}</div>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setFiles([])
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                )
              })}
            </>
          )}
        </div>

        <div className="space-y-3">
          <Label
            htmlFor="labels"
            title="Split"
            description="Mark these items to be used for Training, Evaluation, or both (default)"
          />
          <Select.Single
            defaultOption={{ name: 'Both', id: '' }}
            fieldName="label"
            placeholder="Select dataset for evaluations"
            position="above"
            options={labelOptions}
            showAvatars={false}
            onSelect={(val) => {
              if (val) {
                void formik.setFieldValue('labels', val.id)
              }
            }}
          />

          <FieldError fieldName="label" formik={formik} />
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const OpenAIFileImportForm = ({ workspace, onIsOpenChange, dataset }: FormProps) => {
  const { formik, saveDisabled, labelOptions } = useImportDataFromOpenAIFileForm({
    workspace,
    onIsOpenChange,
    dataset,
  })
  const { values, errors, handleChange, handleSubmit } = formik
  const { files, isLoading } = useFetchOpenAIFiles(workspace.slug)

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        {!dataset && (
          <Input
            id="name"
            name="datasetName"
            label="Dataset Name"
            placeholder="ChatCompletion Data"
            message={errors.datasetName}
            hasError={!!errors.datasetName}
            value={values.datasetName}
            onChange={handleChange}
          />
        )}

        <Combobox.Single
          classNameContent="bg-white text-sm font-inter"
          label="OpenAI File"
          isLoading={isLoading}
          options={files ? files.map((file) => ({ label: file.filename, value: file.id })) : []}
          hasError={!!errors.fileId}
          message={errors.fileId}
          value={values.fileId}
          onChange={(newValue) => {
            if (!newValue) return
            void formik.setFieldValue('fileId', newValue)
          }}
        />

        <div className="space-y-3">
          <label
            htmlFor="labels"
            className="body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500"
          >
            Split
            <br />
            <span className="text-grey-500 font-normal text-xs">
              Mark this item to be used for Training, Evaluation, or both (default)
            </span>
          </label>
          <Select.Single
            defaultOption={{ name: 'Both', id: '' }}
            fieldName="label"
            placeholder="Select dataset for evaluations"
            position="above"
            options={labelOptions}
            showAvatars={false}
            onSelect={(val) => {
              if (val) {
                void formik.setFieldValue('labels', val.id)
              }
            }}
          />

          <FieldError fieldName="label" formik={formik} />
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export const AddDatasetItemRow = ({ workspace, dataset, onIsOpenChange }: FormProps) => {
  const { formik, saveDisabled, labelOptions } = useAddDatasetItemRowForm({
    workspace,
    dataset,
    onIsOpenChange,
  })
  const { values, handleChange, handleBlur, handleSubmit } = formik

  return (
    <div className="flex flex-col space-y-4">
      <div className="px-6 flex flex-col space-y-4">
        <div className="whitespace-pre-wrap rounded-lg text-xs">
          <Textarea
            id="input"
            name="input"
            label="Input"
            placeholder={`Input: String or JSON`}
            className="block w-full min-w-0 flex-1 font-inter rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.input as string}
            rows={2}
          />
          <FieldError fieldName="input" formik={formik} />
        </div>
        <div className="whitespace-pre-wrap rounded-lg text-xs">
          <Textarea
            id="output"
            name="output"
            label="Output"
            placeholder={`Output: string`}
            className="block w-full min-w-0 flex-1 font-inter rounded-md border-grey-300 focus:border-grey-500 focus:ring-grey-500 sm:text-sm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.output}
            rows={2}
          />
          <FieldError fieldName="input" formik={formik} />
        </div>

        <div className="space-y-3">
          <label
            htmlFor="labels"
            className="body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500"
          >
            Split
            <br />
            <span className="text-grey-500 font-normal text-xs">
              Mark this item to be used for Training, Evaluation, or both (default)
            </span>
          </label>
          <Select.Single
            defaultOption={{ name: 'Both', id: '' }}
            fieldName="label"
            placeholder="Select dataset for evaluations"
            position="above"
            options={labelOptions}
            showAvatars={false}
            onSelect={(val) => {
              if (val) {
                void formik.setFieldValue('labels', val.id)
              }
            }}
          />

          <FieldError fieldName="label" formik={formik} />
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between overflow-hidden border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5">
        <Button
          variant="outline"
          onClick={() => {
            onIsOpenChange(false)
          }}
        >
          Close
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export const DataImportForm = ({ workspace, onIsOpenChange, dataset }: FormProps) => {
  const [selectedTab, selectTab] = React.useState('Local')

  return (
    <div>
      <Tabs.Root
        className="flex flex-col justify-center pt-2"
        value={selectedTab}
        onValueChange={(tab) => {
          void selectTab(tab)
        }}
      >
        <Tabs.List>
          <Tabs.Trigger className="text-sm flex items-center" value={'Local'}>
            Local File
          </Tabs.Trigger>
          <Tabs.Trigger className="text-sm flex items-center" value={'OpenAI'}>
            OpenAI File
          </Tabs.Trigger>
          {dataset && (
            <Tabs.Trigger className="text-sm flex items-center" value={'Manual'}>
              Manual
            </Tabs.Trigger>
          )}
        </Tabs.List>

        <Tabs.Content
          value="Local"
          forceMount={true}
          className={`${selectedTab != 'Local' ? 'hidden' : ''}`}
        >
          <LocalImportForm
            workspace={workspace}
            onIsOpenChange={onIsOpenChange}
            dataset={dataset}
          />
        </Tabs.Content>
        <Tabs.Content
          value="OpenAI"
          forceMount={true}
          className={`${selectedTab != 'OpenAI' ? 'hidden' : ''}`}
        >
          <OpenAIFileImportForm
            workspace={workspace}
            onIsOpenChange={onIsOpenChange}
            dataset={dataset}
          />
        </Tabs.Content>
        <Tabs.Content
          value="Manual"
          forceMount={true}
          className={`${selectedTab != 'Manual' ? 'hidden' : ''}`}
        >
          <AddDatasetItemRow
            workspace={workspace}
            dataset={dataset}
            onIsOpenChange={onIsOpenChange}
          />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  )
}
