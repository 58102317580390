import { XClose } from '@untitled-ui/icons-react'
import { Button } from '@/client/components'
import { FileUpload } from '@/common/components'
import { useEditWorkspaceLogoForm } from './Form/form'
import type { FormProps } from './Form/types'

export const WorkspaceLogoForm = ({ workspace }: FormProps) => {
  const { onChangeFile, uploading, onDelete, fileUploadKey } = useEditWorkspaceLogoForm({
    workspace,
  })

  return (
    <div className="flex space-x-6 max-w-lg">
      <div className="flex gap-3 items-center">
        <div>
          <FileUpload
            key={fileUploadKey}
            className="w-25"
            onChangeFiles={(e) => void onChangeFile(e)}
            label="Upload new logo"
            multiple={false}
            variant={'outline'}
          />
        </div>

        {workspace.logoUrl && !uploading && (
          <Button startIcon={XClose} onClick={() => void onDelete()} variant="outline"></Button>
        )}
      </div>

      {uploading && <p className="text-sm">Uploading...</p>}
    </div>
  )
}
