import { useFormik, type FormikConfig, type FormikHelpers } from 'formik'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { useFetchFinetunes, useUser } from '@/common/hooks'
import { useFetchDatasets } from '@/common/hooks/datasets'
import type { DatasetMetadata } from '@/server/service/types'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { createFinetuneFormSchema } from './schema'
import type { CreateFinetuneFormInputs, FormProps } from './types'

export const useCreateFinetuneForm = ({ dataset, workspace, app, onIsOpenChange }: FormProps) => {
  const { finetunes } = useFetchFinetunes(app.id, workspace.id)
  const { datasets } = useFetchDatasets(app.id, workspace.id)
  const createFinetune = api.finetune.createFromDataset.useMutation()
  const utils = api.useContext()

  const { user } = useUser()

  const initialValues = React.useMemo(() => {
    const initialValues: CreateFinetuneFormInputs = {
      name: '',
      baseModel: '',
      datasetId: dataset?.id ?? 0,
      overideSystemMessage: undefined,
      workspaceModelProviderId: 0,
    }

    return initialValues
  }, [dataset])

  const onSuccess = React.useCallback(() => {
    toast.success({
      title: 'Finetune creation started',
      description: 'Your finetune is being created',
    })
  }, [])

  const onSubmit = React.useCallback(
    async (
      values: CreateFinetuneFormInputs,
      { resetForm }: FormikHelpers<CreateFinetuneFormInputs>
    ) => {
      const selectedDataset = datasets?.find((d) => d.id === values.datasetId)

      if (!selectedDataset) {
        toast.error({
          title: 'No dataset selected',
          description: 'Please select a dataset to create a finetune',
        })
        return
      }
      const metadata = selectedDataset.metadata as DatasetMetadata
      if (!metadata?.dataCount || metadata?.dataCount < 10) {
        toast.error({
          title: 'This dataset is too small',
          description:
            'OpenAI requires at least 10 datapoints to optimize. Add more data to your dataset',
        })
        return
      }
      await createFinetune.mutateAsync(
        {
          appId: app.id,
          ...values,
        },
        {
          onError: (error) => {
            toast.error({
              title: 'Error creating finetune',
              description: error.message,
            })
          },
          onSuccess: () => {
            toast.success({
              title: 'Finetune created',
              description: 'Your finetune was created successfully',
            })
          },
        }
      )
      await utils.finetune.getAll.invalidate({ appId: app.id })
      await utils.finetune.getAllForDataset.invalidate({ datasetGuid: selectedDataset.guid })

      if (user) {
        const id = user.id ? user.id : ''
        const email = user.email ? user.email : ''
        const uname = user.name ? user.name : ''
        identify_and_group(id, email, uname, workspace?.slug)
      }
      track(EVENT_NAMES.finetune_created, {
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'Dataset ID': selectedDataset.id,
        'Finetune Name': values.name,
        'Base Model': values.baseModel,
        'App ID': app.id,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      resetForm()
      onSuccess()
      onIsOpenChange(false)
    },
    [
      datasets,
      createFinetune,
      app.id,
      utils.finetune.getAll,
      utils.finetune.getAllForDataset,
      user,
      workspace.id,
      workspace.name,
      workspace?.slug,
      onSuccess,
      onIsOpenChange,
    ]
  )

  const formikConfig: FormikConfig<CreateFinetuneFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: toFormikValidationSchema(createFinetuneFormSchema),
      onSubmit,
    }),
    [initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const { dirty, isSubmitting } = formik

  const saveDisabled = !dirty || isSubmitting

  return {
    formik,
    finetunes,
    datasets,
    saveDisabled,
  }
}
