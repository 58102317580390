import type { App, Workspace } from '@prisma/client'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Dialog, toast } from '@/client/components'
import { useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'

function DeleteAppForm({
  setOpen,
  app,
  workspace,
}: {
  workspace: Workspace
  app: App
  setOpen: (val: boolean) => void
}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const deleteApp = api.app.delete.useMutation()
  const utils = api.useContext()
  const { user } = useUser()

  async function onSuccess() {
    await utils.app.getAll.invalidate()
    toast.success({
      title: 'App deleted',
      description: 'Successfully deleted the app',
    })
    setOpen(false)
    await router.push(`/${workspace.slug}`)
  }

  async function onDelete() {
    setLoading(true)
    await deleteApp.mutateAsync(
      { appId: app.id },
      {
        onSuccess: () => {
          void onSuccess()
          if (user) {
            const id = user.id ? user.id : ''
            const email = user.email ? user.email : ''
            const uname = user.name ? user.name : ''
            identify_and_group(id, email, uname, workspace?.slug)
          }
          track(EVENT_NAMES.app_deleted, {
            'App Name': app.name,
            'App ID': app.id,
            'Workspace ID': workspace.id,
            'Worspace Name': workspace.name,
            'User ID': user?.id,
            'User Email': user?.email,
            'User Name': user?.name,
          })
        },
        onError: (error) => {
          toast.error({
            title: 'Error',
            description: error.message,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      }
    )
  }

  return (
    <>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-full sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-grey-900">Delete {app?.name}</h3>
            <div className="mt-2">
              <p className="text-sm text-grey-700">Are you sure you want to delete {app?.name}?</p>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-grey-200 dark:border-zinc-800 dark:border-zinc-800 bg-grey-50 dark:bg-black px-6 py-5 sm:flex">
        <Button variant="outline" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          variant="destructive"
          onClick={() => void onDelete()}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Deleting...' : 'Delete App'}
        </Button>
      </div>
    </>
  )
}

export const DeleteAppModal = ({
  open,
  setOpen,
  app,
  workspace,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  app: App | null
  workspace: Workspace
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(e) => {
        setOpen(e)
      }}
    >
      <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
        <Dialog.Header>
          {app && <DeleteAppForm setOpen={setOpen} app={app} workspace={workspace} />}
        </Dialog.Header>
      </Dialog.Content>
    </Dialog.Root>
  )
}
