/* eslint-disable @next/next/no-img-element */
import { json } from '@codemirror/lang-json'
import CodeMirror from '@uiw/react-codemirror'
import { Edit05, Eye, EyeOff, Tool01, XClose } from '@untitled-ui/icons-react'
import React from 'react'
import { Button, MarkdownRenderer, Select, TextareaWithStudioContext } from '@/client/components'
import TextToSpeechPlayer from '@/client/components/TextToSpeechPlayer'
import type {
  MessageType,
  ToolCallType,
} from '@/client/containers/views/Studio/components/Tabs/Chat/types'
import type { IsVisionSupportedOptions } from '@/client/containers/views/Studio/components/Tabs/Common/hooks/useIsVisionSupported'
import { useIsVisionSupported } from '@/client/containers/views/Studio/components/Tabs/Common/hooks/useIsVisionSupported'
import { StudioImageUploader } from '@/client/containers/views/Studio/hooks/useStudioImageUploader'
import { cn } from '@/client/utils'
import { useCurrentWorkspace } from '@/common/hooks'
import { useDebounce } from '@/common/hooks/debounce'
import { StreamRenderer } from '@/common/stream/stream/StreamRenderer'
import { useStreamStore } from '@/common/stream/stream/streamStore'
import { isJSONParsable } from '@/utils/json'
import { uuid } from '@/utils/uuid'

interface BaseMessageProps {
  options: MessageType['role'][]
  disableAutoFocus?: boolean
  visionSupportConfig?: IsVisionSupportedOptions
  isLast?: boolean
  toolCallIDs?: string[]
}

interface EditableMessageProps extends BaseMessageProps {
  message: MessageType
  onChange: (newValue: MessageType) => void
  onRemove: () => void
  onAdd?: (newValue: MessageType) => void
  isReadonly: false
}

export interface ReadonlyMessageProps extends BaseMessageProps {
  message: MessageType
  isReadonly: true
}

interface StreamableMessageProps extends BaseMessageProps {
  message: Required<MessageType>
  isReadonly: true
}

type MessageProps = EditableMessageProps | ReadonlyMessageProps | StreamableMessageProps

const PrimitiveChatMessage: React.FC<MessageProps> = (props) => {
  const { workspace } = useCurrentWorkspace()
  const [message, setMessage] = React.useState<MessageType>(props.message)
  const data = useDebounce(message, 100)

  const id = React.useMemo(uuid, [])

  const [isMarkdownView, setMarkdownView] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)

  const { isStreamAvailable } = useStreamStore()

  const isVisionSupported = useIsVisionSupported(
    props.visionSupportConfig
      ? {
          modelName: props.visionSupportConfig?.modelName,
          workspaceModelProviderId: props.visionSupportConfig?.workspaceModelProviderId,
        }
      : undefined
  )

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(
    function autoFocusToTextarea() {
      if (props.disableAutoFocus || props.isReadonly) {
        return
      }
      textAreaRef.current?.focus()
    },
    [props.disableAutoFocus, props.isReadonly]
  )

  const isStreaming = Boolean(
    typeof props.message.dataGuid === 'string' && isStreamAvailable(props.message.dataGuid)
  )

  const onUploadedImages = React.useCallback(
    (urls: string[]) => {
      if (props.isReadonly) return
      if (!urls.length) return

      setMessage((prev) => ({
        ...prev,
        files: [...(props.message.files ?? []), ...urls],
      }))
    },
    [props]
  )

  const onRemoveImage = React.useCallback(
    (url: string) => {
      if (props.isReadonly) return

      setMessage((prev) => ({
        ...prev,
        files: prev.files?.filter((f) => f !== url),
      }))
    },
    [props]
  )

  const onAddToolCall = React.useCallback(
    (toolCall: ToolCallType) => {
      if (props.isReadonly) return
      props.onAdd?.({
        role: 'tool',
        content: '',
        tool_call_id: toolCall.id,
      })
    },
    [props]
  )

  const mutateRemote = React.useCallback(() => {
    if (props.isReadonly) return
    if (isStreaming) return

    props.onChange(data)
  }, [data, props, isStreaming])

  React.useEffect(
    function mutateLocalAfterRemoteDoneStreaming() {
      if (isStreaming) return
      if (isFocused) return
      setMessage(props.message)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStreaming, props.message]
  )

  React.useEffect(
    function mutateLocalAfterReadonlyRemoteMutation() {
      if (props.isReadonly) {
        setMessage(props.message)
      }
    },
    [props.message, props.isReadonly]
  )

  React.useEffect(
    function mutateRemoteAfterLocalMutation() {
      mutateRemote()
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const onDelete = React.useCallback(() => {
    if (props.isReadonly) return
    if (isFocused) {
      setIsFocused(false)
    }
    props.onRemove()
  }, [props, isFocused])

  const isJSONWithoutMarkdownWrapper = React.useMemo(() => {
    if (isStreaming) return false

    const isMD = message.content?.startsWith('```json') && message.content?.endsWith('```')

    if (isMD) return false

    return isJSONParsable(message.content ?? '')
  }, [message.content, isStreaming])

  return (
    <StudioImageUploader.Provider
      images={message.files ?? []}
      key={`main-${id}`}
      id={`main-${id}`}
      config={{
        workspaceGuid: workspace?.project_guid,
        enabled: Boolean(!props.isReadonly && isVisionSupported && message.role === 'user'),
      }}
      callbacks={{
        onUploaded: onUploadedImages,
        onRemove: onRemoveImage,
      }}
    >
      {({ state, eventHandler, config }) => (
        <div>
          <div
            className={cn('flex flex-col group', {
              'flex border-1 border-grey-200 dark:border-zinc-800 dark:border-zinc-800 border-b':
                props.isLast && message.role !== 'assistant',
            })}
            {...eventHandler<HTMLDivElement>()}
          >
            <div
              className={cn(
                'flex justify-between relative items-center w-full top-0 pl-5 pr-4 py-3.5 pb-0'
              )}
            >
              {!props.isReadonly && message.role !== 'assistant' ? (
                <div className="absolute w-full h-full top-0 left-0 bg-white dark:bg-black -z-10" />
              ) : null}
              <Select.Root
                disabled={props.isReadonly}
                value={message.role}
                onValueChange={(newValue) => {
                  if (props.isReadonly) return
                  setMessage((prev) => ({
                    ...prev,
                    role: newValue as MessageType['role'],
                  }))
                }}
              >
                <Select.Trigger
                  disabled={props.isReadonly}
                  className={
                    'border-0 flex-none px-3 -ml-3 capitalize w-36 font-medium disabled:bg-transparent disabled:text-grey-500 bg-transparent text-grey-800 ring-0 hover:ring-1 ring-inset disabled:opacity-100 disabled:hover:ring-0 ring-transparent focus:ring-grey-300 focus:ring-1 focus:bg-white hover:bg-white transition drop-shadow-none disabled:shadow-none shadow-none duration-200 hover:shadow-sm hover:text-black hover:ring-grey-300 dark:text-grey-50 dark:ring-zinc-700 dark:hover:ring-grey-600'
                  }
                >
                  <Select.Value />
                </Select.Trigger>

                <Select.Content>
                  {props.options.map((option, index) => {
                    return (
                      <Select.Item key={index} value={option} className={'capitalize'}>
                        {option}
                      </Select.Item>
                    )
                  })}
                </Select.Content>
              </Select.Root>
              {message.role === 'tool' && props.toolCallIDs && props.toolCallIDs.length > 0 ? (
                <Select.Root
                  value={message.tool_call_id}
                  onValueChange={(newValue) => {
                    if (props.isReadonly) return
                    setMessage((prev) => ({
                      ...prev,
                      tool_call_id: newValue,
                    }))
                  }}
                >
                  <Select.Trigger
                    className={
                      'border-1 flex-none w-fit px-3 text-xs disabled:bg-transparent disabled:text-grey-500 bg-transparent text-grey-800 ring-0 hover:ring-1 ring-inset disabled:opacity-100 disabled:hover:ring-0 ring-transparent focus:ring-grey-300 focus:ring-1 focus:bg-white hover:bg-white transition drop-shadow-none disabled:shadow-none shadow-none duration-200 hover:shadow-sm hover:text-black hover:ring-grey-300 dark:text-grey-50 dark:ring-zinc-700 dark:hover:ring-grey-600'
                    }
                  >
                    <Select.Value placeholder={'Select tool call ID'} />
                  </Select.Trigger>

                  <Select.Content>
                    {props.toolCallIDs.map((option, index) => {
                      return (
                        <Select.Item key={index} value={option}>
                          {option}
                        </Select.Item>
                      )
                    })}
                  </Select.Content>
                </Select.Root>
              ) : null}

              <div className="flex gap-1.5 ml-auto w-fit">
                {message.content && !isStreaming && message.role === 'assistant' && (
                  <TextToSpeechPlayer text={message.content} isLast={props.isLast} />
                )}
                <StudioImageUploader.Button
                  variant="outline-hover"
                  className="opacity-30 group-hover:opacity-100"
                />
                {message.role === 'assistant' ? (
                  <Button
                    size="xs"
                    className={cn(
                      isStreaming
                        ? 'hidden'
                        : 'w-9 group-hover:opacity-100 opacity-50 hover:bg-white'
                    )}
                    variant="outline-hover"
                    icon={isMarkdownView ? EyeOff : Eye}
                    tooltip={{
                      content: isMarkdownView
                        ? 'Disable markdown viewer'
                        : 'Enable markdown viewer',
                      side: 'right',
                    }}
                    onClick={() => setMarkdownView((prev) => !prev)}
                  />
                ) : null}
                <Button
                  size="xs"
                  className={cn(
                    props.isReadonly
                      ? 'hidden'
                      : 'w-9 group-hover:opacity-100 opacity-50 hover:bg-white'
                  )}
                  variant="outline-hover"
                  icon={XClose}
                  onClick={onDelete}
                />
              </div>
            </div>

            <div className="px-2 relative">
              {!props.isReadonly && message.role !== 'assistant' ? (
                <div className="absolute w-full h-full top-0 left-0 bg-white dark:bg-black -z-10" />
              ) : null}
              <div
                className={cn(
                  'px-2 border-[2px] border-dashed transition ease-in-out relative rounded-md',
                  {
                    'mb-4': isStreaming,
                    'border-grey-300': state.isDragActive,
                    'border-transparent': !state.isDragActive,
                  }
                )}
              >
                {isStreaming && message.dataGuid ? (
                  <StreamRenderer
                    dataGuid={message.dataGuid}
                    className="w-full text-sm font-regular text-grey-800"
                  />
                ) : (
                  <div className={cn('flex flex-col gap-2 w-full')}>
                    {isMarkdownView ? (
                      <MarkdownRenderer
                        markdownText={
                          isJSONWithoutMarkdownWrapper
                            ? '```json\n' + (message.content ?? '') + '\n```'
                            : message.content ?? ''
                        }
                      />
                    ) : (
                      <TextareaWithStudioContext
                        ref={textAreaRef}
                        className="[&_textarea]:resize-none w-full font-inter"
                        textAreaClassName={cn('px-0 py-3', {
                          'text-grey-700 bg-transparent': props.isReadonly,
                        })}
                        id="message"
                        label="Message"
                        disabled={props.isReadonly || state.isUploading}
                        hideLabel
                        autoExpand
                        placeholder={
                          props.isReadonly
                            ? ''
                            : message.role === 'system'
                              ? 'Enter an additional system message...'
                              : message.role === 'user'
                                ? 'Enter your user message...'
                                : message.role === 'tool'
                                  ? 'Enter your tool message...'
                                  : 'Enter your assistant message...'
                        }
                        variant={'noBorder'}
                        value={message.content ?? ''}
                        onChange={(e) => {
                          if (props.isReadonly) return
                          if (!isFocused) {
                            setIsFocused(true)
                          }
                          setMessage((prev) => ({
                            ...prev,
                            content: e.target.value,
                          }))
                        }}
                        onMouseEnter={() => setIsFocused(true)}
                        onMouseLeave={() => setIsFocused(false)}
                      />
                    )}

                    {props.isLast &&
                    message.role === 'assistant' &&
                    typeof message.duration === 'number' &&
                    typeof message.token === 'number' ? (
                      <div className="flex items-center gap-1 text-grey-400">
                        {message.duration ? (
                          <p className="text-xs">
                            {(message.duration * 0.001).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            s
                          </p>
                        ) : null}

                        {message.token ? <span className="text-lg">&middot;</span> : null}

                        {message.token ? (
                          <p className="text-xs">{message.token.toLocaleString()} tokens</p>
                        ) : null}
                      </div>
                    ) : null}

                    {message?.tool_calls ? (
                      <div className="flex flex-col gap-2 pb-4">
                        <ToolCallsRendererV2 toolCalls={message.tool_calls} onAdd={onAddToolCall} />
                      </div>
                    ) : null}

                    {message.role !== 'tool' ? (
                      <StudioImageUploader.Gallery className="pb-4" />
                    ) : null}

                    {config.enabled ? (
                      <p
                        className={cn('text-xs transition ease-in-out absolute', {
                          'opacity-50': state.isDragActive,
                          'opacity-0': !state.isDragActive,
                          'bottom-24': message.files && message.files?.length > 0,
                          'bottom-2': !message.files || message.files?.length === 0,
                        })}
                      >
                        Drop images here to upload. Allowed image types:{' '}
                        {config.allowedTypes.join(', ')}.
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </StudioImageUploader.Provider>
  )
}

export const ChatMessage = React.memo(PrimitiveChatMessage)

interface ToolCallProps {
  index?: number
  toolCall: ToolCallType
  onUpdated?: (newValue: ToolCallType, index: number) => void
  onRemove?: (index: number) => void
  streaming?: boolean
}

interface ToolCallPropsV2 extends ToolCallProps {
  onAdd?: (toolCall: ToolCallType) => void
}

export const ToolCallsRendererV2: React.FC<
  Pick<ToolCallPropsV2, 'onAdd' | 'streaming'> & {
    toolCalls: MessageType['tool_calls']
  }
> = ({ toolCalls, streaming, onAdd }) => {
  if (!toolCalls || toolCalls.length === 0) return null
  return (
    <div className="flex flex-col gap-2">
      {toolCalls.map((toolCall, i) => (
        <ToolCallV2 key={i} toolCall={toolCall} streaming={streaming} onAdd={onAdd} />
      ))}
    </div>
  )
}

export const ToolCallV2: React.FC<ToolCallPropsV2> = ({
  index = 0,
  toolCall,
  onUpdated,
  onRemove,
  streaming,
  onAdd,
}) => {
  const [argumentsValue, setArgumentsValue] = React.useState(toolCall.function?.arguments)
  const [toolCallId, setToolCallId] = React.useState(toolCall.id)
  const [toolCallFunctionName, setToolCallFunctionName] = React.useState(toolCall.function?.name)

  // const onChangeParameters = useCallback(
  //   (newVal: string) => {
  //     const updatedToolCall = {
  //       ...toolCall,
  //       function: { ...toolCall.function, arguments: newVal },
  //     }
  //     onUpdated && onUpdated(updatedToolCall, index)
  //   },
  //   [index, toolCall]
  // )

  const computedToolCall: ToolCallType = React.useMemo(() => {
    return {
      type: 'function',
      id: toolCallId,
      function: { name: toolCallFunctionName, arguments: argumentsValue },
    } as ToolCallType
  }, [toolCallId, toolCallFunctionName, argumentsValue])

  React.useEffect(() => {
    onUpdated && onUpdated(computedToolCall, index)
  }, [computedToolCall, index, onUpdated])

  const onChangeArgumentsValue = React.useCallback((newVal: string) => {
    setArgumentsValue(newVal)
  }, [])

  const onChangeId = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToolCallId(e.target.value)
  }, [])

  const onChangeFunctionName = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToolCallFunctionName(e.target.value)
  }, [])

  const parsedArgs = React.useMemo(() => {
    if (onUpdated) {
      return {} // don't parse if we are in edit mode
    }
    let parsedArgs: Record<string, unknown> | string = {}
    try {
      parsedArgs = JSON.parse(toolCall.function?.arguments) as Record<string, unknown>
    } catch (error) {
      parsedArgs = toolCall.function?.arguments
    }
    return parsedArgs
  }, [toolCall.function?.arguments, onUpdated])

  const extensions = React.useMemo(() => {
    return [json()]
  }, [])

  return (
    <div className="grid grid-cols-1 border divide-y rounded-md border-grey-300">
      <span className="p-2 text-xs text-grey-600 dark:text-zinc-600 flex items-center w-full">
        <div className={cn(streaming && 'animate-bounce')}>
          <Tool01 className="inline-block w-4 h-4 mr-2" />
        </div>

        {onUpdated ? (
          <input
            type="text"
            className="font-mono text-xs border border-none rounded-md"
            value={toolCallFunctionName}
            onChange={onChangeFunctionName}
          />
        ) : (
          <>{toolCall.function?.name}</>
        )}

        {onUpdated ? (
          <input
            type="text"
            className="font-mono text-xs border border-none rounded-md"
            value={toolCallId}
            onChange={onChangeId}
          />
        ) : (
          <span className="p-1 ml-4 font-mono border rounded-md border-grey-200 dark:border-zinc-800">
            {toolCall.id}
          </span>
        )}

        {onAdd ? (
          <Button
            size="xs"
            variant="outline"
            startIcon={Edit05}
            onClick={() => onAdd(toolCall)}
            className="ml-auto"
            tooltip={{
              content: 'Edit response',
              side: 'left',
            }}
          />
        ) : null}

        {onRemove && (
          <Button
            size="sm"
            variant="ghost"
            icon={XClose}
            onClick={() => {
              onRemove && onRemove(index)
            }}
          />
        )}
      </span>
      <span className="p-2 font-mono text-xs text-grey-600 dark:text-zinc-600">
        {onUpdated ? (
          <>
            <CodeMirror
              height={'75px'}
              value={toolCall.function?.arguments}
              onChange={onChangeArgumentsValue}
              extensions={extensions}
            />
          </>
        ) : (
          <>{JSON.stringify(parsedArgs, null, 2)}</>
        )}
      </span>
    </div>
  )
}

export const ToolCallsRenderer: React.FC<{
  toolCalls: MessageType['tool_calls']
  streaming?: boolean
}> = ({ toolCalls, streaming }) => {
  if (!toolCalls || toolCalls.length === 0) return null
  return (
    <div className="flex flex-col gap-2">
      {toolCalls.map((toolCall, i) => (
        <ToolCall key={i} toolCall={toolCall} streaming={streaming} />
      ))}
    </div>
  )
}

export const ToolCall: React.FC<ToolCallProps> = ({
  index = 0,
  toolCall,
  onUpdated,
  onRemove,
  streaming,
}) => {
  const [argumentsValue, setArgumentsValue] = React.useState(toolCall.function?.arguments)
  const [toolCallId, setToolCallId] = React.useState(toolCall.id)
  const [toolCallFunctionName, setToolCallFunctionName] = React.useState(toolCall.function?.name)

  // const onChangeParameters = useCallback(
  //   (newVal: string) => {
  //     const updatedToolCall = {
  //       ...toolCall,
  //       function: { ...toolCall.function, arguments: newVal },
  //     }
  //     onUpdated && onUpdated(updatedToolCall, index)
  //   },
  //   [index, toolCall]
  // )

  const computedToolCall: ToolCallType = React.useMemo(() => {
    return {
      type: 'function',
      id: toolCallId,
      function: { name: toolCallFunctionName, arguments: argumentsValue },
    } as ToolCallType
  }, [toolCallId, toolCallFunctionName, argumentsValue])

  React.useEffect(() => {
    onUpdated && onUpdated(computedToolCall, index)
  }, [computedToolCall, index, onUpdated])

  const onChangeArgumentsValue = React.useCallback((newVal: string) => {
    setArgumentsValue(newVal)
  }, [])

  const onChangeId = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToolCallId(e.target.value)
  }, [])

  const onChangeFunctionName = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToolCallFunctionName(e.target.value)
  }, [])

  const parsedArgs = React.useMemo(() => {
    if (onUpdated) {
      return {} // don't parse if we are in edit mode
    }
    let parsedArgs: Record<string, unknown> | string = {}
    try {
      parsedArgs = JSON.parse(toolCall.function?.arguments) as Record<string, unknown>
    } catch (error) {
      parsedArgs = toolCall.function?.arguments
    }
    return parsedArgs
  }, [toolCall.function?.arguments, onUpdated])

  const extensions = React.useMemo(() => {
    return [json()]
  }, [])

  return (
    <div className="grid grid-cols-1 border divide-y rounded-md border-grey-300">
      <span className="p-2 text-xs text-grey-600 dark:text-zinc-600 flex items-center">
        <div className={cn(streaming && 'animate-bounce')}>
          <Tool01 className="inline-block w-4 h-4 mr-2" />
        </div>

        {onUpdated ? (
          <input
            type="text"
            className="font-mono text-xs border border-none rounded-md"
            value={toolCallFunctionName}
            onChange={onChangeFunctionName}
          />
        ) : (
          <>{toolCall.function?.name}</>
        )}

        {onUpdated ? (
          <input
            type="text"
            className="font-mono text-xs border border-none rounded-md"
            value={toolCallId}
            onChange={onChangeId}
          />
        ) : (
          <span className="p-1 ml-4 font-mono border rounded-sm border-grey-200 dark:border-zinc-800">
            {toolCall.id}
          </span>
        )}

        {onRemove && (
          <Button
            size="sm"
            variant="ghost"
            icon={XClose}
            onClick={() => {
              onRemove && onRemove(index)
            }}
          />
        )}
      </span>
      <span className="p-2 font-mono text-xs text-grey-600 dark:text-zinc-600">
        {onUpdated ? (
          <>
            <CodeMirror
              height={'75px'}
              value={toolCall.function?.arguments}
              onChange={onChangeArgumentsValue}
              extensions={extensions}
            />
          </>
        ) : (
          <>{JSON.stringify(parsedArgs, null, 2)}</>
        )}
      </span>
    </div>
  )
}
