import * as AccordionPrimitive from '@radix-ui/react-accordion'
import React from 'react'
import { cn } from '@/client/utils'
import type { ItemProps } from './types'

const AccordionItem = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Item>, ItemProps>(
  ({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
      ref={ref}
      className={cn(
        'border-b border-grey-200 dark:border-zinc-800 text-grey-800',
        'dark:border-zinc-800 dark:text-grey-50',
        className
      )}
      {...props}
    />
  )
)

AccordionItem.displayName = 'AccordionItem'

export { AccordionItem }
