import { ChevronDown, PlusCircle } from '@untitled-ui/icons-react'
import React from 'react'
import { Command, FieldMessage, Icon, Popover, Separator, Skeleton } from '@/client/components'
import { cn } from '@/client/utils'
import type {
  ComboboxItemProps,
  ComboboxSingleOption,
  ComboboxSingleV2Props,
  CommandGroupItemProps,
} from './types'

const ComboboxItemV2 = ({ option, config }: ComboboxItemProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const children = () => (
    <React.Fragment>
      {option.icon ? (
        <Icon
          className={cn(
            {
              'text-grey-400 dark:text-zinc-600': option.disabled,
              'text-grey-900 dark:text-white': !option.disabled,
              'border-[1.5px] border-grey-200 dark:border-zinc-800 rounded-md p-1.5':
                config?.icon?.style === 'outline',
            },
            config?.icon?.className
          )}
          size={config?.icon?.size ?? config?.icon?.style === 'outline' ? 'xxl' : 'md'}
          component={option.icon}
        />
      ) : null}
      <div className="flex flex-col">
        <span className="font-medium">{option.label}</span>
        <span className="text-sm text-grey-400">{option.description}</span>
      </div>
    </React.Fragment>
  )

  return (
    <div
      ref={parentRef}
      className={cn(
        option.disabled
          ? 'cursor-not-allowed text-grey-400 dark:text-zinc-600'
          : 'text-grey-900 dark:text-white',
        'overflow-x-auto no-scrollbar w-full flex items-center gap-3'
      )}
      onMouseEnter={() => {
        if (!parentRef.current) return
        /* Don't marquee if there's no overflow. Avoid unnecessary scroll glitch. */
        if (parentRef.current?.scrollWidth < parentRef.current?.clientWidth) return
        parentRef.current.scrollTo({ left: parentRef.current.scrollWidth, behavior: 'smooth' })
      }}
      onMouseLeave={() => {
        if (!parentRef.current) return
        /* Don't marquee if there's no overflow. Avoid unnecessary scroll glitch. */
        if (parentRef.current?.scrollWidth < parentRef.current?.clientWidth) return
        parentRef.current.scrollTo({ left: 0, behavior: 'smooth' })
      }}
    >
      {children()}
    </div>
  )
}

const CommandGroupItemV2 = ({
  options,
  value,
  heading,
  setOpen,
  onChange,
  onMouseEnter,
  onMouseLeave,
  config,
}: CommandGroupItemProps) => {
  return (
    <Command.Group heading={heading} onMouseLeave={onMouseLeave}>
      {options.map((option) => (
        <Command.Item
          key={option.value}
          className={cn('flex text-sm p-2', option.disabled ? 'cursor-not-allowed' : '')}
          value={option.value}
          onMouseEnter={(_) => {
            onMouseEnter?.(option.value)
          }}
          onSelect={(_) => {
            // Note: cmdk currently lowercases values in forms for some reason, so don't use value from callback parameter // https://github.com/pacocoursey/cmdk/issues/150
            if (onChange) {
              if (option.disabled) return
              onChange(option.value === value ? '' : option.value, heading)
            }

            setOpen(false)
          }}
        >
          <ComboboxItemV2 option={option} config={config} />
        </Command.Item>
      ))}
    </Command.Group>
  )
}

const ComboboxSingleV2 = ({
  className,
  label,
  options,
  groups,
  value = '',
  children,
  placeholder,
  search,
  disabled,
  isLoading,
  emptyText,
  message,
  hasError,
  onChange,
  onMouseEnter,
  onMouseLeave,
  modal = true,
  onSelectCreate,
  title,
  content,
  item,
}: ComboboxSingleV2Props) => {
  const [open, setOpen] = React.useState(false)

  // Sort options alphabetically
  const optionsSortedAlphabetically = React.useMemo(
    () => options?.sort((a, b) => a.label.localeCompare(b.label)),
    [options]
  )

  const allOptions: ComboboxSingleOption[] = React.useMemo(() => {
    if (optionsSortedAlphabetically) {
      return optionsSortedAlphabetically
    } else if (groups) {
      return groups.reduce((prev: ComboboxSingleOption[], group) => [...prev, ...group.options], [])
    }
    return []
  }, [optionsSortedAlphabetically, groups])

  const selectedOption = React.useMemo(() => {
    if (value) {
      return allOptions.find((option) => option.value === value)
    }
  }, [value, allOptions])

  const renderSearch = React.useCallback(
    () =>
      search?.style === 'outline' ? (
        <div className="px-3 pt-3 pb-2 sticky top-0 z-10 bg-white">
          <Command.InputV2
            classNames={{
              parent: 'border-[1.5px]',
            }}
            placeholder={search?.placeholder || 'Search...'}
          />
        </div>
      ) : (
        <>
          <Command.InputV2
            classNames={{
              parent: 'border-none',
            }}
            placeholder={search?.placeholder || 'Search...'}
          />
          <Separator orientation={'horizontal'} />
        </>
      ),
    [search]
  )

  return (
    <div className={cn('relative', className)}>
      {label ? (
        <label
          className={cn(
            'body2 mb-1.5 block font-medium text-grey-800 dark:text-zinc-500',
            { 'text-grey-400 dark:text-zinc-600': disabled },
            {
              'sr-only': label,
            }
          )}
        >
          {label}
        </label>
      ) : null}

      {/*ScrollArea is not working in Popover - modal fixes it https://github.com/shadcn-ui/ui/issues/542#issuecomment-1587142689 */}
      <Popover.Root open={open} onOpenChange={setOpen} modal={modal}>
        <Popover.Trigger asChild disabled={disabled}>
          {children ?? (
            <button
              className={cn(
                'flex font-inter w-full gap-3 whitespace-nowrap items-center justify-between rounded-md border bg-white dark:bg-black px-3 py-2 text-sm outline-none',
                open
                  ? 'border-grey-400 dark:border-white'
                  : 'border-grey-300 hover:border-grey-400 focus-within:border-grey-400 dark:border-zinc-800 dark:focus-within:border-white',
                { 'border-error focus-within:border-error': hasError },
                { 'border-grey-100 bg-grey-100 dark:border-zinc-800 dark:bg-grey-700': disabled }
              )}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="combobox"
              aria-expanded={open}
            >
              {selectedOption ? (
                <ComboboxItemV2 option={selectedOption} config={item} />
              ) : (
                <span className="text-grey-500 dark:text-zinc-600">
                  {placeholder || 'Select...'}
                </span>
              )}

              <Icon
                className={cn(
                  'shrink-0 transition-transform duration-200',
                  { 'rotate-180': open },
                  disabled ? 'text-grey-300 dark:text-zinc-600' : 'text-grey-500'
                )}
                size="md"
                component={ChevronDown}
              />
            </button>
          )}
        </Popover.Trigger>

        <Popover.Content
          className={cn('p-0', content?.className)}
          matchTargetWidth={content?.matchTargetWidth ?? false}
          collisionPadding={20}
          side={content?.side}
          align={content?.align}
          sideOffset={content?.sideOffset}
        >
          <Command.Root
            filter={(value, search) => {
              if (value.includes(search.toLowerCase())) return 1 // optimization: if value and label are equal, then we don't have to search for label

              const label = allOptions.find((opt) => opt.value.toLowerCase() === value)?.label
              if (!label) return 0

              return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
            }}
            className="relative"
          >
            {renderSearch()}
            <Command.List className="h-full relative">
              {isLoading ? (
                <div className="my-2 flex">
                  <div className="flex-1 space-y-2 p-2">
                    <Skeleton className="h-8" />
                    <Skeleton className="h-8" />
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <Command.Empty className="text-grey-400">
                    {emptyText || 'Not found.'}
                  </Command.Empty>

                  <div className="h-full overflow-y-scroll">
                    {optionsSortedAlphabetically ? (
                      <CommandGroupItemV2
                        options={optionsSortedAlphabetically}
                        value={value}
                        setOpen={setOpen}
                        onChange={onChange}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        config={item}
                      />
                    ) : null}

                    {groups
                      ? groups.map(({ heading, options }, index) => {
                          return (
                            <CommandGroupItemV2
                              key={index}
                              options={options}
                              value={value}
                              heading={heading}
                              setOpen={setOpen}
                              onChange={onChange}
                              config={item}
                            />
                          )
                        })
                      : null}
                  </div>

                  {onSelectCreate ? (
                    <div className="sticky z-10 bottom-0 bg-white">
                      <Separator orientation={'horizontal'} />
                      <Command.Group>
                        <Command.Item
                          key="create"
                          value="create"
                          onSelect={() => {
                            onSelectCreate()
                          }}
                          className="relative flex gap-3 items-center cursor-pointer select-none rounded-lg p-2 outline-none aria-selected:bg-grey-100/50 aria-selected:text-grey-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:aria-selected:bg-grey-900 dark:aria-selected:text-grey-50"
                        >
                          <Icon
                            className={cn(item?.icon?.className)}
                            size={item?.icon?.size ?? 'md'}
                            component={PlusCircle}
                          />

                          <span className={'font-medium text-sm '}>New {title}</span>
                        </Command.Item>
                      </Command.Group>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </Command.List>
          </Command.Root>
        </Popover.Content>
      </Popover.Root>

      {message ? <FieldMessage message={message} disabled={disabled} hasError={hasError} /> : null}
    </div>
  )
}

ComboboxSingleV2.displayName = 'ComboboxSingleV2'

export { ComboboxSingleV2 }
