import { useFormik, type FormikConfig } from 'formik'
import { useRouter } from 'next/router'
import React from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { toast } from '@/client/components'
import { usePersistStudioState } from '@/client/containers/views/Studio/components/Formik/hooks/usePersistStudioState'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import type { StudioFormInputs } from '@/client/containers/views/Studio/components/Formik/types'
import { useStudio } from '@/client/containers/views/Studio/context'
import { useCurrentApp, useFetchApps, useUser } from '@/common/hooks'
import { api, EVENT_NAMES, identify_and_group, track } from '@/utils'
import { newActionFormSchemaBuilder, saveActionFormSchema } from './schemas'
import type {
  CreateNewActionFormInputs,
  CreateNewAppFormInputs,
  NewActionFormInputs,
  UseCreateActionFormProps,
} from './types'

export const useNewActionForm = ({
  onIsOpenNewActionDialogChange,
  redirectOnSuccess,
  deployAction,
}: UseCreateActionFormProps) => {
  const router = useRouter()
  const {
    dispatch,
    state: { workspace },
  } = useStudio()
  const { values: rootFormValues, resetForm } = useStudioFormikContext()
  const createApp = api.app.create.useMutation()
  const createAction = api.action.create.useMutation()
  const utils = api.useContext()
  const { user } = useUser()
  const { replaceWithState, selectedTab } = usePersistStudioState()
  const { app } = useCurrentApp()
  const { apps } = useFetchApps(workspace.id)
  const existingAppsCount = apps?.length
  const createSession = api.actionSession.create.useMutation({
    onSuccess: (data) => {
      dispatch({
        type: 'change-session',
        payload: data.id,
      })
    },
    onError: (error) => {
      toast.error({
        title: 'Error creating session',
        description: error.message,
      })
    },
  })

  const initialValues = React.useMemo(() => {
    const initialValues: NewActionFormInputs = {
      workspaceId: workspace.id,
      newApp: false,
      // TODO: add handling when tried to save inside Chat tab
      promptTemplate: rootFormValues.completion.messages
        ? JSON.stringify(rootFormValues.completion.messages)
        : rootFormValues.completion.promptTemplate ?? '',
      templateMessages: rootFormValues.chat.templateMessages ?? [],
      systemMessage:
        rootFormValues.completion.messages?.find((x) => x.role === 'system')?.content ??
        rootFormValues.completion.systemMessage ??
        '',
      workspaceModelProviderId: parseInt(rootFormValues.workspaceModelProviderId, 10),
      modelName: rootFormValues.modelName,
      toolIds: rootFormValues.toolIds ?? [],
      indexIds: rootFormValues.indexIds ?? [],
      outputFormat: rootFormValues.outputFormat,
      outputInstructions: rootFormValues.outputInstructions,
      appId: app ? app.id : undefined,
      modelConfig: rootFormValues.modelConfig,
    }

    return initialValues
  }, [app, rootFormValues, workspace.id])

  const onSuccess = () => {
    toast.success({
      title: `Action created`,
      description: 'Action created and first version saved',
    })
  }

  const createNewApp = React.useCallback(
    async ({ promptTemplate, templateMessages, ...values }: CreateNewAppFormInputs) => {
      const appType = selectedTab === 'completion' ? 'prompt' : 'chat'
      const createdApp = await createApp.mutateAsync({
        ...values,
        appType,
        description: '',
        prompt: selectedTab === 'completion' ? promptTemplate : JSON.stringify(templateMessages),
      })
      await utils.app.getAll.invalidate({ workspaceId: workspace.id })
      await utils.action.getAllForApp.invalidate({ appId: createdApp.id })
      await utils.app.getAllWithActions.invalidate({ workspaceId: workspace.id })

      const createdActions = await utils.action.getAllForApp.fetch({ appId: createdApp.id })

      for (const action of createdActions) {
        await utils.actionVersion.getAllForAction.invalidate({ actionId: action.id })
      }

      if (user) {
        identify_and_group(user.id ?? '', user.email ?? '', user.name ?? '', workspace.slug)
      }

      track(EVENT_NAMES.app_created, {
        'App Name': values.name,
        'App Type': appType,
        'Workspace ID': workspace.id,
        'Worspace Name': workspace.name,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      return createdActions.at(0)
    },
    [
      createApp,
      selectedTab,
      user,
      utils.action.getAllForApp,
      utils.actionVersion.getAllForAction,
      utils.app.getAll,
      utils.app.getAllWithActions,
      workspace.id,
      workspace.name,
      workspace.slug,
    ]
  )

  const createNewAction = React.useCallback(
    async ({
      appId,
      name,
      promptTemplate,
      templateMessages,
      ...values
    }: CreateNewActionFormInputs) => {
      if (!appId) {
        throw new Error('You need to select an app')
      }

      if (!name) {
        throw new Error('Name is required')
      }

      const createdAction = await createAction.mutateAsync({
        ...values,
        appId,
        name,
        description: '',
        actionType: selectedTab === 'completion' ? 'prompt' : 'chat',
        prompt: selectedTab === 'completion' ? promptTemplate : JSON.stringify(templateMessages),
        blocks: [],
        deployAction: deployAction ? true : false,
      })
      await utils.action.getAllForApp.invalidate({ appId: appId })
      await utils.app.getBySlug.invalidate({ slug: app?.slug ?? '' })
      await utils.action.getAllForApp.invalidate({ appId: appId })
      await utils.app.getAllWithActions.invalidate({ workspaceId: workspace.id })

      if (user) {
        identify_and_group(user.id ?? '', user.email ?? '', user.name ?? '', workspace.slug)
      }

      track(EVENT_NAMES.action_created, {
        'Workspace ID': values.workspaceId,
        'Workspace Name': workspace.name,
        'App ID': appId,
        // 'App Name': app.name,
        'Action Name': name,
        // 'Action Description': values.description,
        //'Action Type': actionType ??,
        Tools: values.toolIds.join(', '),
        Indices: values.indexIds.join(', '),
        Model: values.modelName,
        'User ID': user?.id,
        'User Email': user?.email,
        'User Name': user?.name,
      })

      return createdAction
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createAction, user, utils.action.getAllForApp, workspace]
  )

  const onSubmit = React.useCallback(
    async (values: NewActionFormInputs) => {
      try {
        const { appName, ...formValues } = values

        let appId: number | undefined
        let appSlug: string | undefined
        let actionId: number | undefined
        let actionSlug: string | undefined

        if (!formValues.appId) {
          const actionName = appName || formValues.name
          if (!actionName) {
            return
          }
          const createdApp = await createNewApp({ ...formValues, name: actionName })

          appId = createdApp?.app.id
          appSlug = createdApp?.app.slug
          actionId = createdApp?.id
        } else {
          if (formValues.appId) {
            if (!formValues.name) {
              return
            }
            const createdAction = await createNewAction({ ...formValues, name: formValues.name })

            if (!createdAction) {
              return
            }
            formValues.appId = createdAction.appId

            appId = formValues.appId
            appSlug = createdAction.app.slug
            actionId = createdAction.id
            actionSlug = createdAction.slug
          }
        }

        if (actionId) {
          void createSession.mutateAsync({ agentId: actionId })
        }

        onSuccess()
        onIsOpenNewActionDialogChange(false)
        resetForm({
          values: rootFormValues,
        })

        // Note: ugly hack - setTimeout ensures that react makes another render do that formik.dirty value is updated. This prevents showing alert about unsaved changes
        setTimeout(() => {
          if (!actionId || !appId) {
            toast.error({
              title: 'App not found',
              description: 'Saving Action failed',
            })
            return
          }

          const persistedState: Partial<StudioFormInputs> = {}
          if (selectedTab === 'completion') {
            persistedState.completion = { prompts: rootFormValues.completion.prompts }
          } else {
            persistedState.chat = {
              variableValues: rootFormValues.chat.variableValues,
              files: rootFormValues.chat.files,
            }
          }

          // default to redirecting to studio unless specified otherwise
          if (!!redirectOnSuccess) {
            void replaceWithState({
              pathname: `/${workspace.slug}/apps/${appSlug!}/actions/${actionSlug!}/studio`,
              actionId,
              formikState: persistedState,
            })
          }
        }, 5)
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createNewAction, createNewApp, onIsOpenNewActionDialogChange, router, workspace]
  )

  const formikConfig: FormikConfig<NewActionFormInputs> = React.useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(newActionFormSchemaBuilder(existingAppsCount)),
      onSubmit,
    }),
    [existingAppsCount, initialValues, onSubmit]
  )

  const formik = useFormik(formikConfig)

  const saveDisabled = !saveActionFormSchema.safeParse(formik.values).success

  return {
    formik,
    saveDisabled,
  }
}
